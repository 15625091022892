// hooks/useChatState.js
import { useState, useCallback, useEffect } from "react";
import {
  saveResponse,
  createConversation,
  endConversation,
} from "../utils/chat";
import { uploadFiles } from "../utils";
import evaluateCondition from "../utils/followUpQuestionHandler";

export const useChatState = (userType) => {
  const [messages, setMessages] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [userAnswer, setUserAnswer] = useState("");
  const [questionQueue, setQuestionQueue] = useState([]);
  const [answeredQuestions, setAnsweredQuestions] = useState({});
  const [skippedQuestions, setSkippedQuestions] = useState([]);
  const [conversation, setConversation] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [timeTaken, setTimeTaken] = useState(new Date());

  // Initialize conversation
  useEffect(() => {
    const initializeConversation = async () => {
      try {
        const user_type =
          userType === "j" || userType === "a" ? "jobseeker" : "company_user";
        const response = await createConversation(user_type);
        setConversation(response.data._id);
      } catch (error) {
        console.error("Error creating conversation:", error);
      }
    };

    initializeConversation();
  }, [userType]);

  const handleFileUpload = async () => {
    if (uploadedFiles.length === 0) return [];

    try {
      return await uploadFiles(uploadedFiles, "chat");
    } catch (error) {
      console.error("Error uploading files:", error);
      throw error;
    }
  };

  const handleFollowUpQuestions = useCallback((currentQ, answer, queue) => {
    // Guard against undefined/null values
    if (!currentQ || !queue || !Array.isArray(queue)) {
      return [];
    }

    // Create a copy of the queue
    let newQueue = [...queue];

    // Check if there are follow-up questions
    if (
      currentQ.follow_up_questions &&
      currentQ.follow_up_questions.length > 0
    ) {
      // Find the current question's position
      const currentIndex = newQueue.findIndex((q) => q._id === currentQ._id);

      // Find matching follow-ups
      const matchingFollowUps = currentQ.follow_up_questions.filter((fu) =>
        evaluateCondition(fu.condition, fu.condition_value, answer),
      );

      // If we have matching follow-ups, insert them after current question
      if (matchingFollowUps.length > 0) {
        newQueue.splice(
          currentIndex + 1,
          0,
          ...matchingFollowUps.map((fu) => fu.question),
        );
      }
    }
    // Remove the current question and return the updated queue
    return newQueue.filter((q) => q.id !== currentQ.id);
  }, []);

  const progressToNextQuestion = useCallback((updatedQueue, conversation) => {
    if (!updatedQueue?.length) {
      endQuestionnaire(conversation);
      return;
    }

    const nextQuestion = updatedQueue[0];
    if (nextQuestion) {
      setCurrentQuestion(nextQuestion);
      setMessages((prev) => [
        ...prev,
        {
          text: nextQuestion.question_text,
          sender: "bot",
          isQuestion: true,
          required: nextQuestion.required,
        },
      ]);
    } else {
      endQuestionnaire(conversation);
    }
  }, []);

  const saveMessageAndProgress = async (fileUrls) => {
    const newMessage = {
      text: userAnswer.trim(),
      sender: "user",
      files: fileUrls.map((file) => ({
        name: file.name,
        file: file.url,
        fileType: file.mimetype,
      })),
      user: {},
    };

    const searchParams = new URLSearchParams(window.location.search);
    const queryJobseeker = searchParams.get("j");
    if (queryJobseeker) {
      newMessage.user.userId = queryJobseeker;
    }

    setMessages((prev) => [...prev, newMessage]);
    setUserAnswer("");
    setUploadedFiles([]);

    await saveResponse(
      conversation,
      currentQuestion.category === "assessment"
        ? currentQuestion.question
        : currentQuestion,
      newMessage,
      currentQuestion.answer_type.toLowerCase(),
    );

    // Handle follow-ups and progress to next question
    const updatedQueue = handleFollowUpQuestions(
      currentQuestion,
      userAnswer,
      questionQueue,
    );
    setQuestionQueue(updatedQueue);
    progressToNextQuestion(updatedQueue, conversation);
  };

  const handleSendAnswer = async () => {
    if ((!userAnswer.trim() && uploadedFiles.length === 0) || isUploading)
      return;

    setIsUploading(true);
    try {
      const fileUrls = await handleFileUpload();
      await saveMessageAndProgress(fileUrls);
    } catch (error) {
      console.error("Error handling answer:", error);
    } finally {
      setIsUploading(false);
    }
  };

  const endQuestionnaire = useCallback(
    (conversation) => {
      const endMessage = {
        text:
          userType === "a"
            ? "Thank you for completing the questionnaire. Your responses will be reviewed shortly."
            : "Thank you for completing the registration. We will send you an email shortly with further details.",
        sender: "bot",
      };

      setMessages((prev) => [...prev, endMessage]);

      const total_time = new Date() - timeTaken;
      setTimeTaken(total_time);
      const endMode = userType === "j" ? "jobseeker_registation" : "assessment";

      if (conversation) {
        endConversation(conversation, endMode, total_time);
      }

      setCurrentQuestion(null);
      setQuestionQueue([]);
    },
    [userType, timeTaken],
  );

  return {
    messages,
    setMessages,
    currentQuestion,
    setCurrentQuestion,
    userAnswer,
    setUserAnswer,
    questionQueue,
    setQuestionQueue,
    answeredQuestions,
    setAnsweredQuestions,
    skippedQuestions,
    setSkippedQuestions,
    conversation,
    setConversation,
    uploadedFiles,
    setUploadedFiles,
    isUploading,
    setIsUploading,
    timeTaken,
    setTimeTaken,
    handleSendAnswer,
  };
};
