import Modal from "react-modal";
import { useState, useEffect } from "react";
import { X } from "lucide-react";
import { API_BASE_URL } from "../utils/api";
import { subscriptionList, createSubscriptionSession } from "../utils/stripe";

// Assuming you have some subscription plans data
const StripeSubscriptionModal = ({ isOpen, onRequestClose, onSelectPlan }) => {
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSubscriptionPlans = async () => {
      setIsLoading(true);
      try {
        // In a real application, you would call your backend API here
        // which would then securely communicate with the Stripe API
        let data = await subscriptionList();
        setSubscriptionPlans(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (isOpen) {
      fetchSubscriptionPlans();
    }
  }, [isOpen]);

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
  };

  const handleProceed = () => {
    if (selectedPlan) {
      onSelectPlan(selectedPlan);
      onRequestClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Stripe Subscription Modal"
      className="max-w-md mx-auto mt-20 p-6 bg-white rounded-lg shadow-xl"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
      <div className="relative">
        <h2 className="text-2xl font-bold mb-4">Choose a Subscription Plan</h2>
        <button
          onClick={onRequestClose}
          className="absolute top-0 right-0 p-2"
          aria-label="Close modal"
        >
          <X size={24} />
        </button>
        {isLoading ? (
          <p>Loading subscription plans...</p>
        ) : error ? (
          <p className="text-red-500">Error: {error}</p>
        ) : (
          <div className="space-y-4">
            {subscriptionPlans.map((plan) => (
              <div
                key={plan.id}
                className={`p-4 border rounded-lg cursor-pointer transition-colors ${
                  selectedPlan?.id === plan.id
                    ? "bg-blue-100 border-blue-500"
                    : "hover:bg-gray-100"
                }`}
                onClick={() => handleSelectPlan(plan)}
              >
                <h3 className="font-semibold">{plan.name}</h3>
                <strong className="text-gray-600">{plan.price}</strong>
                <ul className="text-gray-600 list-disc p-4">
                  {plan.marketing_features.map((feature) => (
                    <li>{feature.name}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}
        <button
          onClick={handleProceed}
          disabled={!selectedPlan || isLoading || error}
          className="mt-6 w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {selectedPlan ? `Proceed with ${selectedPlan.name}` : "Select a Plan"}
        </button>
      </div>
    </Modal>
  );
};

const handleSelectPlan = async (plan) => {
  const price = plan.id;
  let response = await createSubscriptionSession(price);
  window.open(response.redirect, "_blank", "noopener,noreferrer");
};
export { StripeSubscriptionModal as default, handleSelectPlan };
