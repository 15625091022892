// CustomSelect.jsx
import React from "react";
import Select from "react-select";

const CustomSelect = ({
  options,
  value,
  onChange,
  isInvalid,
  placeholder = "Select...",
  className = "",
  isMulti = true,
  isDisabled = false,
  height = "2.8rem", // Default height
}) => {
  // Transform options based on their type
  const transformedOptions = options.map((option) => {
    if (typeof option === "string") {
      return {
        value: option,
        label: option,
      };
    }
    return {
      value: option.id,
      label: option.name,
    };
  });

  // Transform selected value(s) based on type
  const getSelectedValues = () => {
    if (!value) return null;
    if (isMulti) {
      const values = Array.isArray(value) ? value : [value];
      return values
        .map((val) => {
          if (typeof val === "string") {
            return { value: val, label: val };
          }
          const option = options.find((opt) => opt.id === val);
          return option ? { value: option.id, label: option.name } : null;
        })
        .filter(Boolean);
    } else {
      if (typeof value === "string") {
        return { value: value, label: value };
      }
      const option = options.find((opt) => opt.id === value.id);
      return option ? { value: option.id, label: option.name } : null;
    }
  };

  // Custom styles
  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minHeight: height,
      height: height,
      borderColor: isInvalid ? "#ef4444" : "#d1d5db",
      "&:hover": {
        borderColor: "#9ca3af",
      },
      boxShadow: state.isFocused ? "0 0 0 1px #6366f1" : "none",
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isSelected
        ? "#6366f1" // indigo-500 for selected
        : isFocused
          ? "#e0e7ff" // indigo-100 for hover
          : null,
      color: isSelected ? "white" : "#374151",
      ":active": {
        backgroundColor: "#6366f1", // indigo-500
      },
      ":hover": {
        backgroundColor: "#6366f1", // indigo-500
        color: "white",
      },
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "#e0e7ff", // indigo-100
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#201e61", // indigo-600
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      ":hover": {
        backgroundColor: "#ef4444",
        color: "white",
      },
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 999,
    }),
  };

  const handleChange = (selectedOptions) => {
    if (isMulti) {
      const selectedValues = selectedOptions
        ? selectedOptions.map((option) => option.value)
        : [];
      onChange(selectedValues);
    } else {
      onChange(selectedOptions ? selectedOptions.value : null);
    }
  };

  return (
    <div className={className}>
      <Select
        isMulti={isMulti}
        className={isInvalid ? "border-red-500" : ""}
        classNamePrefix="select"
        value={getSelectedValues()}
        onChange={handleChange}
        options={transformedOptions}
        styles={customStyles}
        placeholder={placeholder}
        isDisabled={isDisabled}
        isClearable
      />
    </div>
  );
};

export default CustomSelect;
