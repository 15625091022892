import React, { useState } from "react";
import { api } from "./utils/api";

const ForgotPasswordPage = ({ changeView }) => {
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setError("Please enter your email address.");
      return;
    }
    let user = await api.get(`/api/users/reset-password?email=${email}`);
    user = user.data;
    // Here you would typically call an API to handle the password reset
    if (user.found) {
      setIsSubmitted(true);
      setError("");
    }
    else{
      setError("Email Not Found!");
    }
  };
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src="./whoppit-logo.png"
            alt="Whoppit"
          />
        </div>
        <h1 className="text-2xl font-bold text-center text-gray-900">
          Forgot Password
        </h1>
        {!isSubmitted ? (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email Address
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className="w-full px-3 py-2 mt-1 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            {error && (
              <div className="p-3 text-sm text-red-700 bg-red-100 border border-red-400 rounded-md">
                {error}
              </div>
            )}
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Reset Password
            </button>
          </form>
        ) : (
          <div className="p-3 text-sm text-green-700 bg-green-100 border border-green-400 rounded-md">
            Account found with {email}, you will receive password reset instructions.
          </div>
        )}
        <div className="text-center">
          <button
            onClick={() => changeView("login")}
            className="w-full text-center text-sm text-indigo-600 hover:text-indigo-500"
          >
            Back to Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
