// AuditDetail.js
"use client";

import React, { useState } from 'react';
import {
  Bell,
  UserCheck,
  FileText,
  FileCheck,
  MessageSquare,
  Paperclip,
  XCircle,
  ArrowLeft,
  Clock,
  Mail,
  PhoneCall,
  User
} from 'lucide-react';
import { Button } from './components/ui/Button';
import { Card, CardContent, CardHeader, CardTitle } from './components/ui/Card';
import { Badge } from './components/ui/Badge';
import { Input } from './components/ui/Input';
import { Label } from './components/ui/Label';
import { Textarea } from './components/ui/Textarea';
import Avatar, { AvatarImage, AvatarFallback } from './components/ui/Avatar';
import ScrollArea from './components/ui/Scroll-area';

const AuditDetail = ({ worker, onClose, isInline = false }) => {
  const [newCommunication, setNewCommunication] = useState({
    type: '',
    content: '',
    status: '',
  });
  const [isFormExpanded, setIsFormExpanded] = useState(false);  

  const getStatusColor = (status) => {
    switch (status) {
      case 'Hiring In Progress':
        return 'bg-blue-100 text-blue-800';
      case 'Onboarding':
        return 'bg-yellow-100 text-yellow-800';
      case 'Active Employee':
        return 'bg-green-100 text-green-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getCommunicationTypeIcon = (type) => {
    switch (type) {
      case 'System Message':
        return <Bell className="h-4 w-4 text-gray-600" />;
      case 'Recruiter Action':
        return <UserCheck className="h-4 w-4 text-blue-600" />;
      case 'Worker Submission':
        return <FileText className="h-4 w-4 text-green-600" />;
      case 'Notification':
        return <MessageSquare className="h-4 w-4 text-purple-600" />;
      default:
        return <MessageSquare className="h-4 w-4 text-gray-600" />;
    }
  };

  const containerClasses = isInline 
    ? "py-4 px-6" 
    : "space-y-6 bg-white p-6 rounded-lg shadow";

  return (
    <div className={containerClasses}>
      {/* Header - Only show if not inline */}
      {!isInline && (
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center space-x-4">
            <Button 
              variant="ghost" 
              size="sm" 
              onClick={onClose}
              className="hover:bg-gray-100"
            >
              <ArrowLeft className="h-5 w-5" />
            </Button>
            <div>
              <h2 className="text-xl font-bold text-gray-800">
                Audit History - {worker.firstName} {worker.surname}
              </h2>
              <p className="text-sm text-gray-500">
                Last updated {new Date(worker.lastCommunication).toLocaleDateString()}
              </p>
            </div>
          </div>
          <Badge className={getStatusColor(worker.status)}>
            {worker.status}
          </Badge>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Left Sidebar - Worker Information */}
        <div className="space-y-6">
          {/* Profile Card */}
          <Card>
            <CardHeader className="border-b p-4">
              <CardTitle className="text-lg">Worker Information</CardTitle>
            </CardHeader>
            <CardContent className="px-4 pb-4">
              <div className="flex items-center space-x-4 mb-6">
                <Avatar className="h-16 w-16">
                  <AvatarImage src={worker.avatar} alt={`${worker.firstName} ${worker.surname}`} />
                  <AvatarFallback className="bg-blue-100 text-blue-600">
                    {worker.firstName[0]}{worker.surname[0]}
                  </AvatarFallback>
                </Avatar>
                <div>
                  <h3 className="font-semibold text-gray-900">
                    {worker.firstName} {worker.surname}
                  </h3>
                  <p className="text-sm text-gray-500">{worker.role}</p>
                </div>
              </div>

              <div className="space-y-4">
                <div className="flex items-center space-x-3 text-sm">
                  <div className="p-2 bg-gray-50 rounded">
                    <Mail className="h-4 w-4 text-gray-600" />
                  </div>
                  <div>
                    <p className="text-gray-500">Email</p>
                    <p className="font-medium">{worker.email}</p>
                  </div>
                </div>
                
                <div className="flex items-center space-x-3 text-sm">
                  <div className="p-2 bg-gray-50 rounded">
                    <PhoneCall className="h-4 w-4 text-gray-600" />
                  </div>
                  <div>
                    <p className="text-gray-500">Phone</p>
                    <p className="font-medium">{worker.phone}</p>
                  </div>
                </div>

                <div className="flex items-center space-x-3 text-sm">
                  <div className="p-2 bg-gray-50 rounded">
                    <User className="h-4 w-4 text-gray-600" />
                  </div>
                  <div>
                    <p className="text-gray-500">Hiring Manager</p>
                    <p className="font-medium">{worker.hiringManager}</p>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>

          {/* Pending Actions Card */}
          <Card>
            <CardHeader className="border-b p-4">
              <CardTitle className="text-lg">Pending Actions</CardTitle>
            </CardHeader>
            <CardContent className="px-4 pb-4">
              <div className="space-y-3">
                {worker.pendingActions.map((action, index) => (
                  <div key={index} className="flex items-center space-x-3 p-3 bg-yellow-50 rounded-lg">
                    <Clock className="h-5 w-5 text-yellow-600" />
                    <span className="text-sm font-medium text-yellow-800">{action}</span>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </div>

        {/* Main Content - Communications Timeline */}
        <div className="md:col-span-2 space-y-6">
          {/* Communications Timeline */}
          <Card>
            <CardHeader className="border-b p-4">
              <CardTitle className="text-lg">Communication Timeline</CardTitle>
            </CardHeader>
            <CardContent className="p-0">
              <ScrollArea className={isInline ? "h-[300px]" : "h-[400px]"}>
                <div className="p-4 space-y-4">
                  {worker.communications.map((comm) => (
                    <div
                      key={comm.id}
                      className={`flex ${
                        comm.source === 'Worker' ? 'justify-end' : 'justify-start'
                      }`}
                    >
                      <div
                        className={`max-w-[80%] p-4 rounded-lg ${
                          comm.source === 'System'
                            ? 'bg-gray-50 border border-gray-100'
                            : comm.source === 'Recruiter'
                            ? 'bg-blue-50 border border-blue-100'
                            : 'bg-green-50 border border-green-100'
                        }`}
                      >
                        <div className="flex items-center justify-between mb-2">
                          <div className="flex items-center space-x-2">
                            {getCommunicationTypeIcon(comm.type)}
                            <span className="text-sm font-medium text-gray-700">
                              {comm.type}
                            </span>
                          </div>
                          <span className="text-xs text-gray-500">
                            {new Date(comm.date).toLocaleString()}
                          </span>
                        </div>
                        <p className="text-sm text-gray-700 mb-2">{comm.content}</p>
                        {comm.status && (
                          <Badge variant="outline" className="mb-2">
                            {comm.status}
                          </Badge>
                        )}
                        {comm.attachment && (
                          <div className="flex items-center mt-2 text-sm text-blue-600 hover:text-blue-700">
                            <Paperclip className="h-4 w-4 mr-1" />
                            <a href="#" className="hover:underline">
                              {comm.attachment}
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </ScrollArea>
            </CardContent>
          </Card>

          {/* New Communication Form */}
          <Card className="border border-gray-200 shadow-sm">
            <CardHeader className="border-b bg-gray-50 p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <MessageSquare className="h-5 w-5 text-blue-600 mr-2" />
                  <CardTitle className="text-lg font-semibold">Log New Communication</CardTitle>
                </div>
                <Button 
                  variant="outline" 
                  size="sm" 
                  onClick={() => setIsFormExpanded(!isFormExpanded)}
                  className="ml-4"
                >
                  {isFormExpanded ? (
                    <>
                      <XCircle className="h-4 w-4 mr-2" />
                      Cancel
                    </>
                  ) : (
                    <>
                      <MessageSquare className="h-4 w-4 mr-2" />
                      Add New Communication
                    </>
                  )}
                </Button>
              </div>
            </CardHeader>
            {isFormExpanded && (
              <CardContent className="p-6">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    console.log('New communication:', newCommunication);
                    setNewCommunication({ type: '', content: '', status: '' });
                    setIsFormExpanded(false); // Close form after submission
                  }}
                  className="space-y-6"
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="space-y-2">
                      <div className="flex items-center">
                        <Bell className="h-4 w-4 text-blue-600 mr-2" />
                        <Label htmlFor="comm-type">Communication Type</Label>
                      </div>
                      <select
                        id="comm-type"
                        value={newCommunication.type}
                        onChange={(e) =>
                          setNewCommunication({
                            ...newCommunication,
                            type: e.target.value,
                          })
                        }
                        className="w-full rounded-md border border-gray-200 px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                      >
                        <option value="Recruiter Action" selected>Recruiter Action</option>
                      </select>
                    </div>

                    <div className="space-y-2">
                      <div className="flex items-center">
                        <FileCheck className="h-4 w-4 text-blue-600 mr-2" />
                        <Label htmlFor="comm-status">Status Update</Label>
                      </div>
                      <Input
                        id="comm-status"
                        placeholder="e.g., Pending Response, Completed"
                        value={newCommunication.status}
                        onChange={(e) =>
                          setNewCommunication({
                            ...newCommunication,
                            status: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="space-y-2">
                    <div className="flex items-center">
                      <MessageSquare className="h-4 w-4 text-blue-600 mr-2" />
                      <Label htmlFor="comm-content">Communication Details</Label>
                    </div>
                    <Textarea
                      id="comm-content"
                      placeholder="Enter details of the communication..."
                      value={newCommunication.content}
                      onChange={(e) =>
                        setNewCommunication({
                          ...newCommunication,
                          content: e.target.value,
                        })
                      }
                      className="min-h-[100px]"
                    />
                  </div>

                  <div className="space-y-2">
                    <div className="flex items-center">
                      <Paperclip className="h-4 w-4 text-blue-600 mr-2" />
                      <Label htmlFor="comm-attachment">Attach Document</Label>
                    </div>
                    <Input
                      id="comm-attachment"
                      type="file"
                      className="cursor-pointer"
                    />
                  </div>

                  <div className="flex justify-end">
                    <Button type="submit" className="bg-blue-600 hover:bg-blue-700">
                      <MessageSquare className="h-4 w-4 mr-2" />
                      Log Communication
                    </Button>
                  </div>
                </form>
              </CardContent>
            )}
          </Card>

        </div>
      </div>
    </div>
  );
};

export default AuditDetail;