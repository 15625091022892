import React, { useState, useEffect } from "react";
import Select from "react-select";
import { format } from "date-fns";
import { api } from "./utils/api";
import { isValidURL } from "./utils";
import { InlineWidget } from "react-calendly";

const EventCreationForm = ({
  jobName,
  jobId,
  candidates,
  onClose,
  currentCompany,
}) => {
  const [formData, setFormData] = useState({
    jobName: jobName || "",
    jobId: jobId || "",
    eventTitle: "",
    startDate: "",
    startTime: "",
    duration: 15, // Default duration to 15 minutes
    selectedCandidates: candidates.map((candidate) => ({
      value: candidate.id,
      label: candidate.name,
    })),
    addMeetLink: true,
    description: "",
  });

  const [enableCalendly, setEnableCanlendly] = useState(false);
  const [enableGoogleCalendlar, setEnableGoogleCalendlar] = useState(true);
  const [calendlyPrefill, setCalendlyPrefill] = useState({});

  useEffect(() => {
    if (jobName) setFormData((prev) => ({ ...prev, jobName }));
    if (jobId) setFormData((prev) => ({ ...prev, jobId }));

    if (currentCompany?.settings?.calendly) {
      setEnableCanlendly(true);
      setEnableGoogleCalendlar(false);
    }
  }, [jobName, jobId, currentCompany]);

  useEffect(() => {
    console.log(candidates);
    const jobseeker = candidates[0].jobseeker
      ? candidates[0].jobseeker
      : candidates.jobseeker;
    const prefill = {
      email: jobseeker?.email,
      name: jobseeker?.name,
      smsReminderNumber: jobseeker?.phone,
    };
    setCalendlyPrefill(prefill);
  }, [candidates]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCandidateChange = (selectedOptions) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedCandidates: selectedOptions,
    }));
  };

  const submitForm = async (formData) => {
    addGoogleEvent(formData);
    addCalendlyEvent(formData);
  };

  const addGoogleEvent = async (formData) => {
    const token = localStorage.getItem("token");
    await api.post(`/api/events/google/create`, formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  const addCalendlyEvent = async (formData) => {
    const token = localStorage.getItem("token");
    await api.post(`/api/events/calendly/create`, formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const startDateTime = new Date(
      `${formData.startDate}T${formData.startTime}`,
    );
    const endDateTime = new Date(
      startDateTime.getTime() + formData.duration * 60000,
    ); // Add duration in minutes

    const eventData = {
      jobName: formData.jobName,
      jobId: formData.jobId,
      eventTitle: formData.eventTitle,
      startDateTime: format(startDateTime, "yyyy-MM-dd'T'HH:mm:ssxxx"),
      endDateTime: format(endDateTime, "yyyy-MM-dd'T'HH:mm:ssxxx"),
      candidates: formData.selectedCandidates.map((c) => ({
        id: c.value,
        name: c.label,
      })),
      description: formData.description,
      addMeetLink: formData.addMeetLink,
    };

    const token = localStorage.getItem("token");
    const response = await api.get(`/api/events/google/auth/check`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      if (isValidURL(response.data.authUrl)) {
        window.open(response.data.authUrl, "_blank", "popup");
      } else {
        await submitForm(eventData);
        onClose(); // Close the modal after submission
      }
    }

    // Here you would typically send this data to your backend
  };

  const candidateOptions = candidates.map((candidate) => ({
    value: candidate.id,
    label: candidate.name,
  }));

  const durationOptions = [
    { value: 15, label: "15 minutes" },
    { value: 30, label: "30 minutes" },
    { value: 45, label: "45 minutes" },
    { value: 60, label: "60 minutes" },
  ];

  const inputClass =
    "mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 px-4 py-2";

  return enableGoogleCalendlar === true ? (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label
          htmlFor="jobName"
          className="block text-sm font-medium text-gray-700"
        >
          Job Name
        </label>
        <input
          type="text"
          id="jobName"
          name="jobName"
          value={formData.jobName}
          onChange={handleInputChange}
          readOnly
          className={`${inputClass} bg-gray-100`}
        />
      </div>

      <div>
        <input
          type="hidden"
          id="jobId"
          name="jobId"
          value={formData.jobId}
          onChange={handleInputChange}
          readOnly
          className={`${inputClass} bg-gray-100`}
        />
      </div>

      <div>
        <label
          htmlFor="eventTitle"
          className="block text-sm font-medium text-gray-700"
        >
          Event Title
        </label>
        <input
          type="text"
          id="eventTitle"
          name="eventTitle"
          value={formData.eventTitle}
          onChange={handleInputChange}
          required
          className={inputClass}
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label
            htmlFor="startDate"
            className="block text-sm font-medium text-gray-700"
          >
            Start Date
          </label>
          <input
            type="date"
            id="startDate"
            name="startDate"
            value={formData.startDate}
            onChange={handleInputChange}
            required
            className={inputClass}
          />
        </div>
        <div>
          <label
            htmlFor="startTime"
            className="block text-sm font-medium text-gray-700"
          >
            Start Time
          </label>
          <input
            type="time"
            id="startTime"
            name="startTime"
            value={formData.startTime}
            onChange={handleInputChange}
            required
            className={inputClass}
          />
        </div>
      </div>

      <div>
        <label
          htmlFor="duration"
          className="block text-sm font-medium text-gray-700"
        >
          Duration
        </label>
        <Select
          name="duration"
          options={durationOptions}
          value={durationOptions.find(
            (option) => option.value === formData.duration,
          )}
          onChange={(selectedOption) =>
            setFormData((prevData) => ({
              ...prevData,
              duration: selectedOption.value,
            }))
          }
          className="mt-1"
          classNamePrefix="react-select"
        />
      </div>

      <div>
        <label
          htmlFor="candidates"
          className="block text-sm font-medium text-gray-700"
        >
          Candidates
        </label>
        <Select
          isMulti={true}
          name="candidates"
          options={candidateOptions}
          value={formData.selectedCandidates}
          onChange={handleCandidateChange}
          className="mt-1"
          classNamePrefix="react-select"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">
          <input
            type="checkbox"
            id="addMeetLink"
            name="addMeetLink"
            value={formData.addMeetLink}
            checked={formData.addMeetLink}
            onChange={(e) =>
              setFormData({ ...formData, addMeetLink: e.target.checked })
            }
          />
          &nbsp;Add Google Meets Link
        </label>
      </div>

      <div>
        <label
          htmlFor="description"
          className="block text-sm font-medium text-gray-700"
        >
          Description
        </label>
        <textarea
          id="description"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          rows={3}
          className={inputClass}
        ></textarea>
      </div>

      <div className="flex justify-end space-x-3">
        <button
          type="button"
          onClick={onClose}
          className="py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Create Event
        </button>
      </div>
    </form>
  ) : (
    <InlineWidget
      url={currentCompany?.settings?.calendlyUser?.user?.scheduling_url}
      prefill={calendlyPrefill}
    />
  );
};

export default EventCreationForm;
