import React, { useState, useRef, useEffect, useCallback } from "react";
import { Send } from "lucide-react";
import { api } from "./utils/api";
import { getNameInitials } from "./utils";
const MessageInterface = ({ jobseeker, companyUser }) => {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const messagesEndRef = useRef(null);
  const sendButtonRef = useRef(null);
  const textAreaRef = useRef(null);
  const [userMessage, setUserMessage] = useState("");
  const [messageValue, setMessageValue] = useState("");

  const loadOldMessage = useCallback(async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const jobseekerId = jobseeker[0].id;
      const companyUserId = companyUser.id;
      const response = await api.get(
        `/api/messages/jobseeker/${jobseekerId}/${companyUserId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setMessages(response.data);
    } catch (error) {
      console.error("Failed to fetch messages:", error);
    } finally {
      setIsLoading(false);
    }
  }, [jobseeker, companyUser]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    loadOldMessage();
  }, [jobseeker, companyUser, loadOldMessage]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Added missing functions
  if (isLoading) {
    return (
      <div className="flex-grow flex justify-center items-center">
        Loading questions...
      </div>
    );
  }

  const handleKeyPress = (e) => {
    if (
      e.key === "Enter" &&
      !e.shiftKey &&
      document.activeElement === sendButtonRef.current
    ) {
      e.preventDefault();
      handleMessageSent();
    }
  };

  const handleMessageSent = (e) => {
    const company_user = companyUser.id;
    const company_user_name = companyUser.name;
    const message = {
      type: "sms",
      recipient: jobseeker,
      sender_name: company_user_name,
      sender: company_user,
      content: userMessage,
      sender_type: "company_user",
    };
    const token = localStorage.getItem("token");
    api.post("/api/messages", message, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setMessageValue("");
    loadOldMessage();
  };

  const handleTextAreaChange = (e) => {
    setUserMessage(e.target.value);
    setMessageValue(e.target.value);
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
  };

  return (
    <div className="flex-grow flex justify-center overflow-hidden lg:p-8">
      <div className="w-full md:max-w-3xl flex flex-col bg-white md:mt-2 md:mb-4 md:rounded-lg">
        <div
          className="flex-grow overflow-y-auto p-4"
          style={{ maxHeight: "calc(100vh - 160px)" }}
        >
          {messages.length > 0 ? (
            messages.map((message, index) => (
              <div
                key={index}
                className={`mb-4 ${message.sender_type === "company_user" ? "text-right" : "text-left"}`}
              >
                {
                  <>
                    <div
                      className={`inline-block p-3 rounded-lg ${message.sender_type === "company_user"
                          ? "bg-white text-gray-700 border border-gray-300"
                          : "bg-blue-500 text-white"
                        } max-w-[85%]`}
                    >
                      {message.content}
                      <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 text-sm font-bold ml-2">
                        {getNameInitials(message.sender_name)}
                      </div>
                    </div>
                  </>
                }
              </div>
            ))
          ) : (
            <div className="inline-block p-3 rounded-lg bg-white text-gray-700 border border-gray-300">
              No previous messages were found !
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
        <div className="border-t border-gray-200 p-4">
          <div className="flex items-start items-center">
            <div className="flex-grow mr-2 w-full">
              <div className="relative w-full">
                <textarea
                  ref={textAreaRef}
                  className="w-full p-3 pr-12 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 min-h-[56px] resize-none overflow-hidden"
                  onChange={handleTextAreaChange}
                  value={messageValue}
                  placeholder="Type your message here..."
                />
              </div>
            </div>
            <button
              ref={sendButtonRef}
              onClick={handleMessageSent}
              onKeyDown={handleKeyPress}
              className={`bg-blue-500 text-white h-14 w-14 flex items-center justify-center rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500`}
            >
              <Send size={24} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageInterface;
