// ChatInterface.jsx
import React, { useRef, useEffect, useState } from "react";
import { Send } from "lucide-react";
import { Message } from "./components/Message";
import { FileUpload } from "./components/FileUpload";
import { useChatState } from "./hooks/useChatState";
import { QuestionInput } from "./components/QuestionInput";
import { useQuestionLoader } from "./hooks/useQuestionLoader";

const ChatInterface = ({ userType }) => {
  const {
    messages,
    currentQuestion,
    userAnswer,
    uploadedFiles,
    isUploading,
    handleSendAnswer,
    setUserAnswer,
    setUploadedFiles,
    setMessages,
    setCurrentQuestion,
    setQuestionQueue,
  } = useChatState(userType);

  const {
    isLoading,
    isTokenValid,
    loadedMessages,
    loadedQuestions,
    initialQuestion,
  } = useQuestionLoader(userType);

  const messagesEndRef = useRef(null);
  const textAreaRef = useRef(null);
  const sendButtonRef = useRef(null);
  const [chatAttachment] = useState(false);

  // Initialize questions and messages when they're loaded
  useEffect(() => {
    if (loadedMessages && loadedQuestions) {
      setMessages(loadedMessages);
      setQuestionQueue(loadedQuestions);
      setCurrentQuestion(initialQuestion);
    }
  }, [
    loadedMessages,
    loadedQuestions,
    initialQuestion,
    setMessages,
    setQuestionQueue,
    setCurrentQuestion,
  ]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleUploadAttachments = (event) => {
    /*
    const files = Array.from(event.target.files);
    console.log(files);
    setUploadedFiles((prev) => [...prev, ...files]);
    event.target.value = "";
    */
    const files = Array.from(event.target.files).map((file) =>
      Object.assign(file, {
        customType: currentQuestion.category ?? "document",
      }),
    );
    setUploadedFiles((prev) => [...prev, ...files]);
    event.target.value = "";
  };

  const handleSkip = () => {
    // Implement skip functionality if needed
  };

  if (isLoading) {
    return (
      <div className="flex-grow flex justify-center items-center">
        Loading questions...
      </div>
    );
  }

  if (!isTokenValid) {
    return (
      <div className="flex-grow flex justify-center items-center">
        Link is expired or used
      </div>
    );
  }

  return (
    <div className="flex-grow flex justify-center overflow-hidden lg:p-8">
      <div className="w-full md:max-w-3xl flex flex-col bg-white shadow-lg md:mt-2 md:mb-4 md:rounded-lg">
        <header className="sm:mx-auto sm:w-full sm:max-w-md pb-10">
          <img
            className="mx-auto h-12 w-auto"
            src="./whoppit-logo.png"
            alt="Whoppit"
          />
        </header>

        <div
          className="flex-grow overflow-y-auto p-4"
          style={{ maxHeight: "calc(100vh - 160px)" }}
        >
          {messages.map((message, index) => (
            <div
              key={index}
              className={`mb-4 ${message.sender === "user" ? "text-right" : "text-left"}`}
            >
              <Message
                message={message}
                onSkip={message.required === false ? handleSkip : undefined}
              />
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>

        {currentQuestion && (
          <footer className="border-t border-gray-200 p-4">
            <div className="mb-2">
              {uploadedFiles.length > 0 && (
                <FileUpload
                  files={uploadedFiles}
                  onRemove={(file) =>
                    setUploadedFiles((prev) => prev.filter((f) => f !== file))
                  }
                />
              )}
            </div>

            <div className="flex items-start items-center">
              <div className="flex-grow mr-2 w-full">
                <QuestionInput
                  question={currentQuestion}
                  value={userAnswer}
                  onChange={setUserAnswer}
                  onFileUpload={handleUploadAttachments}
                  textAreaRef={textAreaRef}
                  chatAttachment={chatAttachment}
                />
              </div>

              <button
                ref={sendButtonRef}
                onClick={handleSendAnswer}
                disabled={
                  (!userAnswer.trim() && uploadedFiles.length === 0) ||
                  isUploading
                }
                className={`bg-blue-500 text-white h-14 w-14 flex items-center justify-center rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 ${(!userAnswer.trim() && uploadedFiles.length === 0) ||
                    isUploading
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                  }`}
              >
                {isUploading ? (
                  <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-white" />
                ) : (
                  <Send size={24} />
                )}
              </button>
            </div>
          </footer>
        )}
      </div>
    </div>
  );
};

export default ChatInterface;
