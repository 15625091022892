// WorkerDetail.js
import React from 'react';
import {
  Mail,
  Phone,
  Calendar,
  Briefcase,
  ClipboardList,
  GraduationCap,
  Shield,
  AlertCircle,
  CreditCard,
  FileText,
} from 'lucide-react';
import Button from './components/ui/Button';
import Badge from './components/ui/Badge';

const renderShortcutButton = (icon, label) => (
  <Button variant="outline" size="icon" title={label}>
    {icon}
  </Button>
);

const WorkerDetail = ({ worker }) => (
  <div className="p-4 bg-gray-50 rounded-lg">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
      <div>
        <h4 className="font-semibold mb-2">Contact Information</h4>
        <div className="space-y-2">
          <div className="flex items-center">
            <Mail className="h-4 w-4 mr-2 text-gray-500" />
            <span>{worker.email}</span>
          </div>
          <div className="flex items-center">
            <Phone className="h-4 w-4 mr-2 text-gray-500" />
            <span>{worker.phone}</span>
          </div>
        </div>
      </div>
      <div>
        <h4 className="font-semibold mb-2">Application Details</h4>
        <div className="space-y-2">
          <div className="flex items-center">
            <Calendar className="h-4 w-4 mr-2 text-gray-500" />
            <span>
              Applied: {new Date(worker.appliedDate).toLocaleDateString()}
            </span>
          </div>
          <div className="flex items-center">
            <Briefcase className="h-4 w-4 mr-2 text-gray-500" />
            <span>Job: {worker.jobTitle}</span>
          </div>
        </div>
      </div>
    </div>
    <div className="mb-4">
      <h4 className="font-semibold mb-2">Quick Actions</h4>
      <div className="flex flex-wrap gap-2">
        {renderShortcutButton(
          <ClipboardList className="h-4 w-4" />,
          'Checklist'
        )}
        {renderShortcutButton(
          <Briefcase className="h-4 w-4" />,
          'Onboarding'
        )}
        {renderShortcutButton(
          <GraduationCap className="h-4 w-4" />,
          'Training'
        )}
        {renderShortcutButton(
          <Shield className="h-4 w-4" />,
          'Right-to-Work'
        )}
        {renderShortcutButton(
          <AlertCircle className="h-4 w-4" />,
          'Audit'
        )}
        {renderShortcutButton(
          <CreditCard className="h-4 w-4" />,
          'Payroll'
        )}
        {renderShortcutButton(
          <FileText className="h-4 w-4" />,
          'Documents'
        )}
      </div>
    </div>
    <div>
      <h4 className="font-semibold mb-2">Latest Actions</h4>
      <ul className="space-y-2">
        {worker.latestActions.map((action, index) => (
          <li key={index} className="flex items-center">
            <Calendar className="h-4 w-4 mr-2 text-gray-500" />
            <span className="text-sm">
              {new Date(action.date).toLocaleString()}: {action.action}
            </span>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default WorkerDetail;