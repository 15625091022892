// WorkerChecklistDetail.js

import React, { useState } from 'react';
import {
  AlertTriangle,
  CheckCircle2,
  Clock,
  FileText,
  Briefcase,
  Calendar,
  User,
  XCircle,
  CheckCircle,
  X,
} from 'lucide-react';
import { Button } from './components/ui/Button';
import { Card, CardContent, CardHeader, CardTitle } from './components/ui/Card';
import { Badge } from './components/ui/Badge';
import { Label } from './components/ui/Label';
import { Input } from './components/ui/Input';

const WorkerChecklistDetail = ({ worker, onClose }) => {
  const [selectedQualification, setSelectedQualification] = useState(null);
  const [showChaseUpModal, setShowChaseUpModal] = useState(false);
  const [chaseUpReason, setChaseUpReason] = useState('');

  const getStatusColor = (status) => {
    switch (status) {
      case true:
        return 'bg-green-100 text-green-800';
      case false:
        return 'bg-yellow-100 text-yellow-800';
      case 'Rejected':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusIcon = (status) => {
    if (status === true) {
      return <CheckCircle className="h-5 w-5 text-green-600" />;
    } else if (status === false) {
      return <Clock className="h-5 w-5 text-yellow-600" />;
    } else if (status === 'Rejected') {
      return <AlertTriangle className="h-5 w-5 text-red-600" />;
    }
    return <XCircle className="h-5 w-5 text-gray-400" />;
  };

  const handleQualificationClick = (qualification) => {
    if (qualification.obtained === false || qualification.obtained === 'Rejected') {
      setSelectedQualification(qualification);
      setShowChaseUpModal(true);
    }
  };

  const handleChaseUp = () => {
    console.log(
      `Chasing up ${worker.firstName} ${worker.surname} for qualification: ${selectedQualification.name} - Reason: ${chaseUpReason}`
    );
    setChaseUpReason('');
    setShowChaseUpModal(false);
    setSelectedQualification(null);
  };

  return (
    <div className="p-6 bg-white">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Worker Information */}
        <Card className="md:col-span-1 border border-gray-200 shadow-sm">
          <CardHeader className="border-b bg-gray-50 p-4">
            <div className="flex items-center">
              <User className="h-5 w-5 text-blue-600 mr-2" />
              <CardTitle className="text-lg font-semibold">
                Worker Information
              </CardTitle>
            </div>
          </CardHeader>
          <CardContent className="p-4">
            <dl className="grid grid-cols-1 gap-4">
              <div className="flex items-start">
                <User className="h-5 w-5 text-blue-600 mt-1 mr-3" />
                <div>
                  <dt className="text-sm text-gray-500">Full Name</dt>
                  <dd className="mt-1 font-medium text-gray-900 text-lg">
                    {worker.firstName} {worker.surname}
                  </dd>
                </div>
              </div>
              <div className="flex items-start">
                <Briefcase className="h-5 w-5 text-blue-600 mt-1 mr-3" />
                <div>
                  <dt className="text-sm text-gray-500">Job Title</dt>
                  <dd className="mt-1 font-medium text-gray-900">
                    {worker.jobTitle}
                  </dd>
                </div>
              </div>
              <div className="flex items-start">
                <Calendar className="h-5 w-5 text-blue-600 mt-1 mr-3" />
                <div>
                  <dt className="text-sm text-gray-500">Start Date</dt>
                  <dd className="mt-1 font-medium text-gray-900">
                    {worker.startDate}
                  </dd>
                </div>
              </div>
            </dl>
          </CardContent>
        </Card>

        {/* Qualifications */}
        <Card className="md:col-span-2 border border-gray-200 shadow-sm">
          <CardHeader className="border-b bg-gray-50 p-4">
            <div className="flex items-center">
              <FileText className="h-5 w-5 text-blue-600 mr-2" />
              <CardTitle className="text-lg font-semibold">
                Qualifications
              </CardTitle>
            </div>
          </CardHeader>
          <CardContent className="p-4 space-y-4">
            {worker.qualifications.map((qual) => (
              <div
                key={qual.id}
                className={`p-4 rounded-lg cursor-pointer ${
                  qual.obtained === true
                    ? 'bg-green-50 border border-green-200'
                    : qual.obtained === 'Rejected'
                    ? 'bg-red-50 border border-red-200'
                    : 'bg-yellow-50 border border-yellow-200'
                }`}
                onClick={() => handleQualificationClick(qual)}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-3">
                    <div className="p-2 rounded-lg bg-gray-50">
                      <FileText className="h-5 w-5 text-blue-600" />
                    </div>
                    <div>
                      <p className="font-medium text-gray-900">{qual.name}</p>
                    </div>
                  </div>
                  <Badge
                    className={`${getStatusColor(qual.obtained)} px-3 py-1`}
                  >
                    {qual.obtained === true
                      ? 'Approved'
                      : qual.obtained === 'Rejected'
                      ? 'Rejected'
                      : 'Pending'}
                  </Badge>
                </div>
              </div>
            ))}
          </CardContent>
        </Card>
      </div>
      {/* Chase Up Modal */}
      {showChaseUpModal && selectedQualification && (
        <div
          className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50"
          onClick={() => {
            setShowChaseUpModal(false);
            setSelectedQualification(null);
          }}
        >
          <div
            className="bg-white rounded-lg shadow-lg max-w-md w-full"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="px-6 py-4 border-b flex justify-between items-center bg-gray-50">
              <h2 className="text-lg font-semibold text-gray-800">
                Chase Up: {selectedQualification.name}
              </h2>
              <button
                onClick={() => {
                  setShowChaseUpModal(false);
                  setSelectedQualification(null);
                }}
                className="text-gray-500 hover:text-gray-700 p-2 hover:bg-gray-100 rounded-full transition-colors"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            <div className="p-6 space-y-4">
              <div>
                <p className="text-sm text-gray-600">
                  Sending chase up to {worker.firstName} {worker.surname}
                </p>
              </div>
              <div>
                <Label
                  htmlFor="chaseUpReason"
                  className="block text-sm font-medium text-gray-700"
                >
                  Reason for Chase Up
                </Label>
                <textarea
                  id="chaseUpReason"
                  value={chaseUpReason}
                  onChange={(e) => setChaseUpReason(e.target.value)}
                  className="mt-1 w-full rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  rows={4}
                  placeholder="Enter the reason for chasing up..."
                />
              </div>
              <div className="flex justify-end space-x-3">
                <Button
                  variant="outline"
                  onClick={() => {
                    setShowChaseUpModal(false);
                    setSelectedQualification(null);
                  }}
                >
                  Cancel
                </Button>
                <Button onClick={handleChaseUp}>Send Chase Up</Button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-end space-x-3 mt-4">
        <Button variant="outline" onClick={onClose}>
          <XCircle className="h-4 w-4 mr-2" />
          Close
        </Button>
      </div>
    </div>
  );
};

export default WorkerChecklistDetail;