import React from "react";

const CustomNumberInput = ({ value, onChange, min = 0, max = 100, step = 0.1 }) => {
  const handleIncrease = () => {
    const newValue = parseFloat((value + step).toFixed(10)); // Prevent floating-point errors
    if (newValue <= max) onChange(newValue);
  };

  const handleDecrease = () => {
    const newValue = parseFloat((value - step).toFixed(10));
    if (newValue >= min) onChange(newValue);
  };

  const handleInputChange = (e) => {
    const newValue = parseFloat(e.target.value);
    if (!isNaN(newValue) && newValue >= min && newValue <= max) {
      onChange(newValue);
    }
  };

  return (
    <div className="flex items-center">
      {/* Decrease Button */}
      <button
        onClick={handleDecrease}
        className="px-2 bg-gray-200 border border-r-0 rounded-l hover:bg-gray-300 disabled:bg-gray-100 disabled:cursor-not-allowed"
        disabled={value <= min}
      >
        -
      </button>

      {/* Input Field */}
      <input
        type="number"
        value={value}
        onChange={handleInputChange}
        step={step}
        min={min}
        max={max}
        className="w-16 text-center border"
      />

      {/* Increase Button */}
      <button
        onClick={handleIncrease}
        className="px-2 bg-gray-200 border border-l-0 rounded-r hover:bg-gray-300 disabled:bg-gray-100 disabled:cursor-not-allowed"
        disabled={value >= max}
      >
        +
      </button>
    </div>
  );
};

export default CustomNumberInput;
