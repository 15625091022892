import React, { useState } from 'react';
import {
  FileText,
  Download,
  Upload,
  ThumbsUp,
  Edit2,
  PenTool,
  AlertOctagon,
  X,
  PlusCircle,
  Pencil,
  Shield,
  Receipt,
  History,
  Calculator,
  Building2
} from 'lucide-react';

const PayrollDetailsModal = ({ 
  worker, 
  onClose, 
  onUploadDocument, 
  onApprovePayroll, 
  onEditDetails, 
  onAddNote, 
  onRecordIssue, 
  onViewPayslip,
  onAddPayment = () => {
    console.log('Add payment handler not implemented');
  },
  onUpdateTaxCode = () => {
    console.log('Update tax code handler not implemented');
  },
  onManageBenefits = () => {
    console.log('Manage benefits handler not implemented');
  },
  onUpdatePension = () => {
    console.log('Update pension handler not implemented');
  }
}) => {
  const [showUploadForm, setShowUploadForm] = useState(false);
  const [activeTab, setActiveTab] = useState('overview'); // 'overview' | 'history' | 'documents'

  const getStatusColor = (status) => {
    switch (status) {
      case 'Paid':
        return 'bg-green-100 text-green-800';
      case 'Pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'Failed':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  if (!worker) return null;

  const TabButton = ({ name, icon: Icon, label }) => (
    <button
      onClick={() => setActiveTab(name)}
      className={`flex items-center px-4 py-2 rounded-md text-sm font-medium transition-all
        ${activeTab === name 
          ? 'bg-white text-gray-900 shadow' 
          : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900'}`}
    >
      <Icon className="h-4 w-4 mr-2" />
      {label}
    </button>
  );

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50" onClick={onClose}>
      <div 
        className="bg-white rounded-lg shadow-lg max-w-5xl w-full max-h-[90vh] overflow-hidden border" 
        onClick={(e) => e.stopPropagation()}
      >
        {/* Header */}
        <div className="px-6 py-4 border-b flex justify-between items-center bg-gray-50">
          <div className="flex items-center space-x-4">
            <div className="bg-blue-100 p-2 rounded-full">
              <Building2 className="h-6 w-6 text-blue-600" />
            </div>
            <div>
              <h2 className="text-xl font-semibold text-gray-800">
                {worker.firstName} {worker.surname}
              </h2>
              <p className="text-sm text-gray-500">{worker.payrollId} • {worker.department}</p>
            </div>
          </div>
          <button 
            onClick={onClose} 
            className="text-gray-500 hover:text-gray-700 p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        {/* Tab Navigation */}
        <div className="px-6 py-3 bg-gray-100 border-b">
          <div className="flex space-x-2">
            <TabButton name="overview" icon={Calculator} label="Overview" />
            <TabButton name="history" icon={History} label="Payment History" />
            <TabButton name="documents" icon={FileText} label="Documents" />
          </div>
        </div>
        <div className="overflow-y-auto p-6 space-y-6">
          {activeTab === 'overview' && (
            <div className="space-y-6">
              {/* Quick Actions */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <button
                  onClick={() => onAddPayment(worker.id)}
                  className="flex items-center p-4 bg-white rounded-lg border hover:shadow-md transition-all group"
                >
                  <div className="flex-shrink-0 h-10 w-10 flex items-center justify-center bg-blue-100 text-blue-600 rounded-full group-hover:bg-blue-200">
                    <PlusCircle className="h-5 w-5" />
                  </div>
                  <div className="ml-4 text-left">
                    <h3 className="text-sm font-medium text-gray-900">Add Payment</h3>
                    <p className="text-xs text-gray-500 mt-1">Process additional payments</p>
                  </div>
                </button>
                
                <button
                  onClick={() => onUpdateTaxCode(worker.id)}
                  className="flex items-center p-4 bg-white rounded-lg border hover:shadow-md transition-all group"
                >
                  <div className="flex-shrink-0 h-10 w-10 flex items-center justify-center bg-green-100 text-green-600 rounded-full group-hover:bg-green-200">
                    <Receipt className="h-5 w-5" />
                  </div>
                  <div className="ml-4 text-left">
                    <h3 className="text-sm font-medium text-gray-900">Tax Settings</h3>
                    <p className="text-xs text-gray-500 mt-1">Update tax codes & information</p>
                  </div>
                </button>

                <button
                  onClick={() => onAddNote(worker.id)}
                  className="flex items-center p-4 bg-white rounded-lg border hover:shadow-md transition-all group"
                >
                  <div className="flex-shrink-0 h-10 w-10 flex items-center justify-center bg-purple-100 text-purple-600 rounded-full group-hover:bg-purple-200">
                    <PenTool className="h-5 w-5" />
                  </div>
                  <div className="ml-4 text-left">
                    <h3 className="text-sm font-medium text-gray-900">Add Note</h3>
                    <p className="text-xs text-gray-500 mt-1">Record important information</p>
                  </div>
                </button>
              </div>

              {/* Current Pay Period */}
              <div className="bg-white rounded-lg border">
                <div className="px-6 py-4 border-b">
                  <div className="flex justify-between items-center">
                    <h3 className="text-sm font-medium text-gray-900">Current Pay Period</h3>
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(worker.paymentStatus)}`}>
                      {worker.paymentStatus}
                    </span>
                  </div>
                </div>
                <div className="px-6 py-4">
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
                    <div>
                      <div className="text-sm text-gray-500">Gross Pay</div>
                      <div className="mt-1 text-2xl font-semibold text-gray-800">
                        £{worker.currentGrossPay.toFixed(2)}
                      </div>
                      <div className="mt-1 text-xs text-gray-400">Before deductions</div>
                    </div>
                    
                    <div>
                      <div className="text-sm text-gray-500">Deductions</div>
                      <div className="mt-1 text-2xl font-semibold text-red-600">
                        -£{worker.currentDeductions.toFixed(2)}
                      </div>
                      <div className="mt-1 text-xs text-gray-400">Tax, NI & other</div>
                    </div>
                    
                    <div>
                      <div className="text-sm text-gray-500">Net Pay</div>
                      <div className="mt-1 text-2xl font-semibold text-green-600">
                        £{worker.currentNetPay.toFixed(2)}
                      </div>
                      <div className="mt-1 text-xs text-gray-400">Take home pay</div>
                    </div>
                    
                    <div>
                      <div className="text-sm text-gray-500">Payment Date</div>
                      <div className="mt-1 text-2xl font-semibold text-gray-800">
                        {new Date(worker.lastPaymentDate).toLocaleDateString('en-GB', {
                          day: 'numeric',
                          month: 'short',
                          year: 'numeric',
                        })}
                      </div>
                      <div className="mt-1 text-xs text-gray-400">Next scheduled payment</div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Deductions Breakdown */}
              <div className="bg-white rounded-lg border">
                <div className="px-6 py-4 border-b">
                  <h3 className="text-sm font-medium text-gray-900">Deductions Breakdown</h3>
                </div>
                <div className="p-6">
                  <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
                    {Object.entries(worker.payrollHistory[0].deductions).map(([key, value]) => (
                      <div key={key} className="bg-gray-50 p-4 rounded-lg border">
                        <div className="text-sm text-gray-500">
                          {key.replace(/([A-Z])/g, ' $1').trim()}
                        </div>
                        <div className="mt-1 text-lg font-semibold text-gray-800">
                          £{value.toFixed(2)}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* Banking Details */}
              <div className="bg-white rounded-lg border">
                <div className="px-6 py-4 border-b">
                  <h3 className="text-sm font-medium text-gray-900">Banking Details</h3>
                </div>
                <div className="p-6">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div className="bg-gray-50 p-4 rounded-lg border">
                      <div className="text-sm text-gray-500">Account Name</div>
                      <div className="mt-1 font-medium text-gray-800">
                        {worker.bankDetails?.accountName}
                      </div>
                    </div>
                    <div className="bg-gray-50 p-4 rounded-lg border">
                      <div className="text-sm text-gray-500">Account Number</div>
                      <div className="mt-1 font-medium text-gray-800">
                        **** **** **** {worker.bankDetails?.accountNumber.slice(-4)}
                      </div>
                    </div>
                    <div className="bg-gray-50 p-4 rounded-lg border">
                      <div className="text-sm text-gray-500">Sort Code</div>
                      <div className="mt-1 font-medium text-gray-800">
                        {worker.bankDetails?.sortCode}
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 flex items-center justify-end">
                    <button
                      onClick={() => onEditDetails(worker.id)}
                      className="inline-flex items-center px-3 py-2 text-sm font-medium text-gray-700 bg-white rounded-md border hover:bg-gray-50 transition-colors"
                    >
                      <Edit2 className="h-4 w-4 mr-2" />
                      Update Bank Details
                    </button>
                  </div>
                </div>
              </div>

              {/* Additional Information */}
              <div className="bg-white rounded-lg border">
                <div className="px-6 py-4 border-b">
                  <h3 className="text-sm font-medium text-gray-900">Additional Information</h3>
                </div>
                <div className="p-6">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="space-y-4">
                      <div className="bg-gray-50 p-4 rounded-lg border">
                        <div className="text-sm text-gray-500">Employment Type</div>
                        <div className="mt-1 font-medium text-gray-800">Full Time</div>
                      </div>
                      <div className="bg-gray-50 p-4 rounded-lg border">
                        <div className="text-sm text-gray-500">Tax Code</div>
                        <div className="mt-1 font-medium text-gray-800">{worker.taxCode}</div>
                      </div>
                    </div>
                    <div className="space-y-4">
                      <div className="bg-gray-50 p-4 rounded-lg border">
                        <div className="text-sm text-gray-500">NIC Category</div>
                        <div className="mt-1 font-medium text-gray-800">{worker.nicCategory}</div>
                      </div>
                      <div className="bg-gray-50 p-4 rounded-lg border">
                        <div className="text-sm text-gray-500">Payment Schedule</div>
                        <div className="mt-1 font-medium text-gray-800">{worker.payPeriod}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeTab === 'history' && (
            <div className="space-y-6">
              {/* Summary Cards */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="bg-white p-4 rounded-lg border">
                  <div className="text-sm text-gray-500">YTD Earnings</div>
                  <div className="mt-1 text-2xl font-semibold text-gray-800">
                    £{(worker.currentGrossPay * 12).toFixed(2)}
                  </div>
                  <div className="mt-2 text-xs text-gray-400">Financial Year 2024/25</div>
                </div>
                
                <div className="bg-white p-4 rounded-lg border">
                  <div className="text-sm text-gray-500">Total Payments</div>
                  <div className="mt-1 text-2xl font-semibold text-gray-800">
                    {worker.payrollHistory.length}
                  </div>
                  <div className="mt-2 text-xs text-gray-400">Historical payments</div>
                </div>

                <div className="bg-white p-4 rounded-lg border">
                  <div className="text-sm text-gray-500">Average Monthly</div>
                  <div className="mt-1 text-2xl font-semibold text-gray-800">
                    £{(worker.payrollHistory.reduce((acc, curr) => acc + curr.netPay, 0) / worker.payrollHistory.length).toFixed(2)}
                  </div>
                  <div className="mt-2 text-xs text-gray-400">Net pay average</div>
                </div>
              </div>

              {/* Payment History Table */}
              <div className="bg-white rounded-lg border">
                <div className="px-6 py-4 border-b bg-gray-50">
                  <div className="flex justify-between items-center">
                    <h3 className="text-sm font-medium text-gray-900">Payment History</h3>
                    <div className="flex items-center space-x-2">
                      <button className="inline-flex items-center px-3 py-1.5 text-sm font-medium text-gray-700 bg-white rounded-md border hover:bg-gray-50 transition-colors">
                        <Download className="h-4 w-4 mr-2" />
                        Export
                      </button>
                    </div>
                  </div>
                </div>
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Period
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Gross Pay
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Deductions
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Net Pay
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Date
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {worker.payrollHistory.map((payment) => (
                        <tr key={payment.id} className="hover:bg-gray-50 transition-colors">
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            {payment.payPeriod}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            £{payment.grossPay.toFixed(2)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            £{Object.values(payment.deductions).reduce((a, b) => a + b, 0).toFixed(2)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            £{payment.netPay.toFixed(2)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            {payment.paymentDate}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            <div className="flex space-x-2">
                              <button
                                onClick={() => onViewPayslip(worker.id, payment.payPeriod)}
                                className="inline-flex items-center px-2.5 py-1.5 text-xs font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded transition-colors"
                              >
                                <FileText className="h-3 w-3 mr-1" />
                                View
                              </button>
                              <button className="inline-flex items-center px-2.5 py-1.5 text-xs font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded transition-colors">
                                <Download className="h-3 w-3 mr-1" />
                                Download
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

          {activeTab === 'documents' && (
            <div className="space-y-6">
              {/* Upload Section */}
              <div className="bg-white rounded-lg border">
                <div className="px-6 py-4 border-b">
                  <div className="flex justify-between items-center">
                    <h3 className="text-sm font-medium text-gray-900">Documents</h3>
                    <button
                      onClick={() => setShowUploadForm(!showUploadForm)}
                      className="inline-flex items-center px-3 py-1.5 text-sm font-medium text-gray-700 bg-white rounded-md border hover:bg-gray-50 transition-colors"
                    >
                      <Upload className="h-4 w-4 mr-2" />
                      Upload New
                    </button>
                  </div>
                </div>
                
                {showUploadForm && (
                  <div className="p-6 border-b bg-gray-50">
                    <div className="max-w-xl mx-auto">
                      <div className="flex justify-center px-6 py-10 border-2 border-gray-300 border-dashed rounded-lg hover:border-gray-400 transition-colors">
                        <div className="text-center">
                          <Upload className="mx-auto h-12 w-12 text-gray-400" />
                          <div className="mt-4 flex text-sm text-gray-600">
                            <label
                              htmlFor="file-upload"
                              className="relative cursor-pointer rounded-md bg-white font-medium text-blue-600 focus-within:outline-none hover:text-blue-500"
                            >
                              <span>Upload a file</span>
                              <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                            </label>
                            <p className="pl-1">or drag and drop</p>
                          </div>
                          <p className="text-xs text-gray-500">PDF, DOC up to 10MB</p>
                        </div>
                      </div>
                      <div className="mt-4 flex justify-end">
                        <button
                          onClick={() => {
                            onUploadDocument(worker.id);
                            setShowUploadForm(false);
                          }}
                          className="inline-flex justify-center items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md transition-colors"
                        >
                          <Upload className="h-4 w-4 mr-2" />
                          Upload Document
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <div className="p-6">
                  {worker.documents.length > 0 ? (
                    <div className="grid gap-4">
                      {worker.documents.map((doc, index) => (
                        <div 
                          key={index} 
                          className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border hover:bg-gray-100 transition-colors"
                        >
                          <div className="flex items-center">
                            <div className="h-10 w-10 flex items-center justify-center bg-gray-100 rounded-full">
                              <FileText className="h-5 w-5 text-gray-600" />
                            </div>
                            <div className="ml-4">
                              <p className="text-sm font-medium text-gray-900">{doc.name}</p>
                              <p className="text-xs text-gray-500">Added on {new Date().toLocaleDateString()}</p>
                            </div>
                          </div>
                          <div className="flex items-center space-x-2">
                            <button className="inline-flex items-center px-3 py-1.5 text-xs font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded transition-colors">
                              <Download className="h-3 w-3 mr-1" />
                              Download
                            </button>
                            <button className="inline-flex items-center px-2 py-1.5 text-xs font-medium text-red-600 hover:bg-red-50 rounded transition-colors">
                              <X className="h-3 w-3" />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="text-center py-12">
                      <FileText className="mx-auto h-12 w-12 text-gray-400" />
                      <h3 className="mt-2 text-sm font-medium text-gray-900">No documents</h3>
                      <p className="mt-1 text-sm text-gray-500">Get started by uploading a document.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PayrollDetailsModal;
