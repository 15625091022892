import React from 'react';
import { ChevronRight, User, Brain, Star, ThumbsUp, ThumbsDown, Gift, AlertTriangle, Zap, Book } from 'lucide-react';

const PersonalityType = ({ personalityType }) => {
  const {
    type,
    name,
    nameDescription,
    epithet,
    description,
    jungianFunctionalPreference,
    generalTraits,
    relationshipStrengths,
    relationshipWeaknesses,
    successDefinition,
    strengths,
    gifts,
    potentialProblemAreas,
    explanationOfProblems,
    solutions,
    livingHappilyTips,
    tenRulesToLive,
  } = personalityType;

  const SectionTitle = ({ icon: Icon, title }) => (
    <h3 className="text-xl font-bold mb-4 text-indigo-800 flex items-center">
      <Icon className="mr-2" />
      {title}
    </h3>
  );

  const List = ({ items }) => (
    <ul className="list-disc pl-5 space-y-2">
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );

  return (
    <div className="p-6 space-y-8 bg-gradient-to-br from-blue-50 to-indigo-50 rounded-lg">
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold text-indigo-800">{type} - {name}</h2>
        <p className="text-xl text-gray-600">{nameDescription}</p>
        <p className="text-2xl font-semibold mt-2 text-indigo-600">{epithet}</p>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-md">
        <SectionTitle icon={User} title="Personality Description" />
        <p className="whitespace-pre-line">{description}</p>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-md">
        <SectionTitle icon={Brain} title="Jungian Functional Preference" />
        <ul className="space-y-2">
          {Object.entries(jungianFunctionalPreference).map(([key, value]) => (
            <li key={key} className="flex items-center">
              <ChevronRight className="text-indigo-500 mr-2" />
              <span className="font-semibold">{key.charAt(0).toUpperCase() + key.slice(1)}:</span> {value}
            </li>
          ))}
        </ul>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-md">
        <SectionTitle icon={Star} title="General Traits" />
        <List items={generalTraits} />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <SectionTitle icon={ThumbsUp} title="Relationship Strengths" />
          <List items={relationshipStrengths} />
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <SectionTitle icon={ThumbsDown} title="Relationship Weaknesses" />
          <List items={relationshipWeaknesses} />
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-md">
        <SectionTitle icon={Star} title="Definition of Success" />
        <p className="whitespace-pre-line">{successDefinition}</p>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-md">
        <SectionTitle icon={Zap} title="Strengths" />
        <List items={strengths} />
      </div>

      <div className="bg-white p-6 rounded-lg shadow-md">
        <SectionTitle icon={Gift} title="Gifts" />
        <List items={gifts} />
      </div>

      <div className="bg-white p-6 rounded-lg shadow-md">
        <SectionTitle icon={AlertTriangle} title="Potential Problem Areas" />
        <p className="whitespace-pre-line">{potentialProblemAreas}</p>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-md">
        <SectionTitle icon={Book} title="Explanation of Problems" />
        <p className="whitespace-pre-line">{explanationOfProblems}</p>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-md">
        <SectionTitle icon={Zap} title="Solutions" />
        <p className="whitespace-pre-line">{solutions}</p>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-md">
        <SectionTitle icon={Star} title="Living Happily Tips" />
        <p className="whitespace-pre-line">{livingHappilyTips}</p>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-md">
        <SectionTitle icon={Star} title="Ten Rules to Live By" />
        <ol className="list-decimal pl-5 space-y-2">
          {tenRulesToLive.map((rule, index) => (
            <li key={index}>{rule}</li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default PersonalityType;