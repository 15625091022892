"use client";

import React, { useState } from 'react';
import {
  Shield, 
  Search, 
  ChevronDown, 
  ChevronUp, 
  FileText,
  MessageSquare,
  XCircle
} from 'lucide-react';
import { Button } from './components/ui/Button';
import { Badge } from './components/ui/Badge';
import { Input } from './components/ui/Input';
import AuditDetail from './AuditDetail';

// Keep dummyAudits data the same...
const dummyAudits = [
  {
    id: '1',
    firstName: 'John',
    surname: 'Doe',
    role: 'Senior Electrician',
    status: 'Hiring In Progress',
    lastCommunication: '2023-10-25',
    communicationCount: 12,
    lastAction: 'Document Submission',
    pendingActions: ['Document Submission', 'Background Check'],
    email: 'john.doe@example.com',
    phone: '+1234567890',
    hiringManager: 'Jane Smith',
    avatar: 'https://randomuser.me/api/portraits/men/3.jpg',
    communications: [
      {
        id: 'c1',
        date: '2023-10-25T14:30',
        type: 'Recruiter Action',
        source: 'Recruiter',
        content: 'Scheduled final interview for November 2nd at 2 PM.',
        status: 'Pending Confirmation',
      },
      {
        id: 'c2',
        date: '2023-10-23T09:00',
        type: 'Worker Submission',
        source: 'Worker',
        content: 'Submitted updated resume and portfolio.',
        attachment: 'john_doe_portfolio.pdf',
      },
    ],
  },
  {
    id: '2',
    firstName: 'Maria',
    surname: 'Garcia',
    role: 'Apprentice Electrician',
    status: 'Active Employee',
    lastCommunication: '2023-10-24',
    communicationCount: 8,
    lastAction: 'Training Completion',
    pendingActions: ['Annual Review'],
    email: 'maria.garcia@example.com',
    phone: '+1234567891',
    hiringManager: 'John Brown',
    avatar: 'https://randomuser.me/api/portraits/women/3.jpg',
    communications: [
      {
        id: 'c3',
        date: '2023-10-24T10:00:00',
        type: 'System Message',
        source: 'System',
        content: 'Completed mandatory safety training.',
        status: 'Completed',
      },
    ],
  },
];

const AuditList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('All');
  const [sortConfig, setSortConfig] = useState(null);
  const [expandedAuditId, setExpandedAuditId] = useState(null);

  // Your existing helper functions (getStatusColor, handleSort, etc.)...
  const getStatusColor = (status) => {
    switch (status) {
      case 'Hiring In Progress':
        return 'bg-blue-100 text-blue-800';
      case 'Active Employee':
        return 'bg-green-100 text-green-800';
      case 'Onboarding':
        return 'bg-yellow-100 text-yellow-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const handleSort = (key) => {
    if (sortConfig && sortConfig.key === key) {
      setSortConfig(
        sortConfig.direction === 'ascending'
          ? { key, direction: 'descending' }
          : null
      );
    } else {
      setSortConfig({ key, direction: 'ascending' });
    }
  };

  const renderSortIcon = (key) => {
    if (!sortConfig || sortConfig.key !== key) {
      return <ChevronDown className="ml-1 h-4 w-4 inline-block text-gray-400" />;
    }
    return sortConfig.direction === 'ascending' ? (
      <ChevronUp className="ml-1 h-4 w-4 inline-block text-gray-400" />
    ) : (
      <ChevronDown className="ml-1 h-4 w-4 inline-block text-gray-400" />
    );
  };

  const filteredAudits = dummyAudits
    .filter((audit) => {
      const nameMatch = `${audit.firstName} ${audit.surname}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const statusMatch =
        filterStatus === 'All' || audit.status === filterStatus;
      return nameMatch && statusMatch;
    })
    .sort((a, b) => {
      if (!sortConfig) return 0;
      const { key, direction } = sortConfig;
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });

  const toggleAuditDetail = (auditId) => {
    setExpandedAuditId(expandedAuditId === auditId ? null : auditId);
  };

  return (
    <div className="space-y-6">
      <div className="bg-white p-6 rounded-lg shadow">
        <div className="flex items-center mb-4">
          <MessageSquare className="mr-2 h-6 w-6 text-blue-600" />
          <h2 className="text-xl font-bold text-gray-800">Audit Management</h2>
        </div>

        {/* Filters */}
        <div className="flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 md:space-x-4 mb-4">
          <div className="flex-1 relative">
            <input
              type="text"
              placeholder="Search workers..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full border border-gray-300 rounded-md p-2 pl-10 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
          <select
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
            className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="All">All Statuses</option>
            <option value="Hiring In Progress">Hiring In Progress</option>
            <option value="Active Employee">Active Employee</option>
            <option value="Onboarding">Onboarding</option>
          </select>
        </div>

        {/* Audits Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr className="bg-gray-50 text-gray-600 uppercase text-sm leading-normal">
                <th
                  className="py-3 px-4 text-left cursor-pointer"
                  onClick={() => handleSort('firstName')}
                >
                  Name {renderSortIcon('firstName')}
                </th>
                <th className="py-3 px-4 text-left">Role</th>
                <th className="py-3 px-4 text-left">Status</th>
                <th
                  className="py-3 px-4 text-left cursor-pointer"
                  onClick={() => handleSort('lastCommunication')}
                >
                  Last Updated {renderSortIcon('lastCommunication')}
                </th>
                <th className="py-3 px-4 text-left">Pending Actions</th>
                <th className="py-3 px-4 text-left">Actions</th>
              </tr>
            </thead>
            <tbody className="text-gray-700 text-sm">
              {filteredAudits.map((audit, index) => (
                <React.Fragment key={audit.id}>
                  <tr
                    className={`border-b border-gray-200 hover:bg-gray-50 ${
                      index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                    } ${expandedAuditId === audit.id ? 'border-b-0' : ''}`}
                  >
                    <td className="py-3 px-4 text-left whitespace-nowrap">
                      {audit.firstName} {audit.surname}
                    </td>
                    <td className="py-3 px-4 text-left">{audit.role}</td>
                    <td className="py-3 px-4 text-left">
                      <Badge className={getStatusColor(audit.status)}>
                        {audit.status}
                      </Badge>
                    </td>
                    <td className="py-3 px-4 text-left">
                      {new Date(audit.lastCommunication).toLocaleDateString()}
                    </td>
                    <td className="py-3 px-4 text-left">
                      {audit.pendingActions.length > 0 ? (
                        <Badge variant="outline" className="bg-yellow-50">
                          {audit.pendingActions.length} pending
                        </Badge>
                      ) : (
                        <Badge variant="outline" className="bg-green-50">
                          No actions
                        </Badge>
                      )}
                    </td>
                    <td className="py-3 px-4 text-left">
                      <Button
                        onClick={() => toggleAuditDetail(audit.id)}
                        variant="outline"
                        size="sm"
                        className="inline-flex items-center"
                      >
                        {expandedAuditId === audit.id ? (
                          <>
                            <XCircle className="h-4 w-4 mr-2" />
                            Close Details
                          </>
                        ) : (
                          <>
                            <FileText className="h-4 w-4 mr-2" />
                            View Details
                          </>
                        )}
                      </Button>
                    </td>
                  </tr>
                  {expandedAuditId === audit.id && (
                    <tr className="border-b border-gray-200">
                      <td colSpan="6" className="p-0">
                        <div className="border-t border-gray-200">
                          <AuditDetail 
                            worker={audit} 
                            onClose={() => setExpandedAuditId(null)}
                            isInline={true}  // New prop to handle inline styling
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AuditList;