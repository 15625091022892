import React, { useState } from 'react';

export const DropdownMenu = ({ children }) => <div className="relative">{children}</div>;

export const DropdownMenuTrigger = ({ children, asChild, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return asChild ? (
    React.cloneElement(children, {
      ...props,
      onClick: handleClick,
      'aria-expanded': isOpen,
      'aria-haspopup': 'true',
    })
  ) : (
    <button {...props} onClick={handleClick} aria-expanded={isOpen} aria-haspopup="true">
      {children}
    </button>
  );
};

export const DropdownMenuContent = ({ children, isOpen }) => {
  if (!isOpen) return null;

  return (
    <div className="absolute mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
      {children}
    </div>
  );
};

export const DropdownMenuItem = ({ children, onSelect }) => (
  <div
    onClick={onSelect}
    className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
  >
    {children}
  </div>
);