// Payroll.js

import React, { useState } from 'react';
import {
  User,
  Briefcase,
  ClipboardList,
  Shield,
  FileText,
  AlertOctagon,
  ThumbsUp,
  PenTool,
  Edit2,
  Upload,
  Download,
  ExternalLink,
  Banknote,
  CreditCard,
  MessageSquare,
  GraduationCap,
  Search,
  ChevronDown,
  ChevronUp,
  PlusCircle,
  Receipt,
} from 'lucide-react';
import { useNotification } from "./CustomNotificationSystem";
import PayrollDetailsModal from './PayrollDetailsModal';

// Dummy data for workers
const dummyWorkers = [
  {
    id: '1',
    firstName: 'John',
    surname: 'Doe',
    jobTitle: 'Senior Electrician',
    payPeriod: 'Monthly',
    currentGrossPay: 3500,
    currentDeductions: 1050,
    currentNetPay: 2450,
    paymentStatus: 'Paid',
    lastPaymentDate: '2023-10-25',
    payrollId: 'EMP001',
    department: 'Electrical',
    taxCode: '1250L',
    nicCategory: 'A',
    bankDetails: {
      accountName: 'John Doe',
      accountNumber: '12345678',
      sortCode: '12-34-56',
    },
    isAgencyWorker: false,
    documents: [],
    payrollHistory: [
      {
        id: 'pay1',
        payPeriod: 'October 2023',
        grossPay: 3500,
        deductions: {
          incomeTax: 500,
          nationalInsurance: 300,
          pension: 175,
          studentLoan: 50,
          other: 25,
        },
        netPay: 2450,
        paymentDate: '2023-10-25',
        paymentMethod: 'Bank Transfer',
      },
    ],
  },
  {
    id: '2',
    firstName: 'Jane',
    surname: 'Smith',
    jobTitle: 'Electrician',
    payPeriod: 'Weekly',
    currentGrossPay: 800,
    currentDeductions: 200,
    currentNetPay: 600,
    paymentStatus: 'Pending',
    lastPaymentDate: '2023-10-18',
    payrollId: 'EMP002',
    department: 'Electrical',
    taxCode: '1257L',
    nicCategory: 'A',
    bankDetails: {
      accountName: 'Jane Smith',
      accountNumber: '87654321',
      sortCode: '65-43-21',
    },
    isAgencyWorker: false,
    documents: [],
    payrollHistory: [
      {
        id: 'pay2',
        payPeriod: 'Week 42 2023',
        grossPay: 800,
        deductions: {
          incomeTax: 120,
          nationalInsurance: 50,
          pension: 20,
          studentLoan: 5,
          other: 5,
        },
        netPay: 600,
        paymentDate: '2023-10-18',
        paymentMethod: 'Bank Transfer',
      },
    ],
  },
  {
    id: '3',
    firstName: 'Michael',
    surname: 'Johnson',
    jobTitle: 'Junior Electrician',
    payPeriod: 'Monthly',
    currentGrossPay: 2500,
    currentDeductions: 750,
    currentNetPay: 1750,
    paymentStatus: 'Failed',
    lastPaymentDate: '2023-10-20',
    payrollId: 'EMP003',
    department: 'Electrical',
    taxCode: '1250L',
    nicCategory: 'A',
    bankDetails: {
      accountName: 'Michael Johnson',
      accountNumber: '11223344',
      sortCode: '11-22-33',
    },
    isAgencyWorker: false,
    documents: [],
    payrollHistory: [
      {
        id: 'pay3',
        payPeriod: 'September 2023',
        grossPay: 2500,
        deductions: {
          incomeTax: 350,
          nationalInsurance: 200,
          pension: 150,
          studentLoan: 30,
          other: 20,
        },
        netPay: 1750,
        paymentDate: '2023-09-25',
        paymentMethod: 'Bank Transfer',
      },
    ],
  },
];

const Payroll = () => {
  const [activeTab, setActiveTab] = useState('payroll');
  const [selectedWorker, setSelectedWorker] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('All');
  const [sortConfig, setSortConfig] = useState(null);
  const [jobTitleFilter, setJobTitleFilter] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [workerType, setWorkerType] = useState('all'); // 'all', 'agency', 'non-agency'
  const [showActionMenu, setShowActionMenu] = useState(null);  
  const notify = useNotification();

  const workers = dummyWorkers;

  const getStatusColor = (status) => {
    switch (status) {
      case 'Paid':
        return 'bg-green-100 text-green-800';
      case 'Pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'Failed':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  // Modify the filteredWorkers function
  const filteredWorkers = workers
    .filter((worker) => {
      const nameMatch = (worker.firstName.toLowerCase() + ' ' + worker.surname.toLowerCase())
        .includes(searchTerm.toLowerCase());
      const statusMatch = filterStatus === 'All' || worker.paymentStatus === filterStatus;
      const jobTitleMatch = !jobTitleFilter || 
        worker.jobTitle.toLowerCase().includes(jobTitleFilter.toLowerCase());
      const workerTypeMatch = workerType === 'all' || 
        (workerType === 'agency' ? worker.isAgencyWorker : !worker.isAgencyWorker);
      const dateMatch = (!startDate || !endDate) || 
        (worker.lastPaymentDate >= startDate && worker.lastPaymentDate <= endDate);

      return nameMatch && statusMatch && jobTitleMatch && workerTypeMatch && dateMatch;
    })
    .sort((a, b) => {
      if (!sortConfig) return 0;
      const { key, direction } = sortConfig;
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });

    // Action Menu Component
    const ActionMenu = ({ worker, onClose }) => (
      <div 
        className="absolute right-0 mt-2 w-56 rounded-md shadow-xl bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 z-50"
      >
        <div className="py-1" role="menu" aria-orientation="vertical">
          <button
            onClick={() => {
              setSelectedWorker(worker);
              onClose();
            }}
            className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            <FileText className="h-4 w-4 mr-2" />
            View Details
          </button>
          <button
            onClick={() => {
              handleAddPayment(worker.id);
              onClose();
            }}
            className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            <PlusCircle className="h-4 w-4 mr-2" />
            Add Payment
          </button>
          <button
            onClick={() => {
              addNote(worker.id);
              onClose();
            }}
            className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            <PenTool className="h-4 w-4 mr-2" />
            Add Note
          </button>
          <button
            onClick={() => {
              handleUpdateTaxCode(worker.id);
              onClose();
            }}
            className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            <Receipt className="h-4 w-4 mr-2" />
            Update Tax Code
          </button>
          {worker.isAgencyWorker && (
            <button
              onClick={() => {
                handleContactCUBA(worker);
                onClose();
              }}
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              <ExternalLink className="h-4 w-4 mr-2" />
              Contact CUBA
            </button>
          )}
        </div>
      </div>
    );
	

  const handleSort = (key) => {
    if (sortConfig && sortConfig.key === key) {
      setSortConfig(
        sortConfig.direction === 'ascending' ? { key, direction: 'descending' } : null
      );
    } else {
      setSortConfig({ key, direction: 'ascending' });
    }
  };

  const renderSortIcon = (key) => {
    if (!sortConfig || sortConfig.key !== key) {
      return <ChevronDown className="ml-1 h-4 w-4 inline-block text-gray-400" />;
    }
    return sortConfig.direction === 'ascending' ? (
      <ChevronUp className="ml-1 h-4 w-4 inline-block text-gray-400" />
    ) : (
      <ChevronDown className="ml-1 h-4 w-4 inline-block text-gray-400" />
    );
  };

  const handleContactCUBA = (worker) => {
    const message = prompt(`Enter message for CUBA regarding ${worker.firstName} ${worker.surname}:`);
    if (message) {
      console.log(`Sending message to CUBA for ${worker.firstName} ${worker.surname}: ${message}`);
      // Dummy function to simulate sending a message
      notify(`Message sent to CUBA: ${message}`, 'success');
    }
  };

  // Dummy functions for actions
  const approvePayroll = (workerId) => {
    console.log(`Approving payroll for worker ${workerId}`);
    notify(`Payroll approved for worker ID: ${workerId}`, 'success');
  };

  const editPayrollDetails = (workerId) => {
    console.log(`Editing payroll details for worker ${workerId}`);
    notify(`Payroll details edited for worker ID: ${workerId}`, 'success');
  };

  const addNote = (workerId) => {
    const note = prompt('Enter note:');
    if (note) {
      console.log(`Adding note for worker ${workerId}: ${note}`);
      notify(`Note added for worker ID: ${workerId}`, 'success');
    }
  };

  const recordPaymentIssue = (workerId) => {
    const issue = prompt('Describe the payment issue:');
    if (issue) {
      console.log(`Recording payment issue for worker ${workerId}: ${issue}`);
      notify(`Payment issue recorded for worker ID: ${workerId}`, 'error');
    }
  };

  const uploadDocument = (workerId) => {
    console.log(`Uploading document for worker ${workerId}`);
    notify(`Document uploaded for worker ID: ${workerId}`, 'success');
  };

  const viewPayslip = (workerId, payPeriod) => {
    console.log(`Viewing payslip for worker ${workerId} for period ${payPeriod}`);
    notify(`Payslip for ${payPeriod} is not available.`, 'info');
  };

  const renderPayroll = () => (
    <div className="bg-white p-6 rounded-lg shadow">
      {/* Payroll Header */}
      <div className="flex items-center mb-4">
        <Banknote className="mr-2 h-6 w-6 text-blue-600" />
        <h2 className="text-xl font-bold text-gray-800">Payroll Management</h2>
      </div>
	  {/* Enhanced Filters */}
      <div className="space-y-4 mb-6">
        <div className="flex flex-col md:flex-row md:items-center gap-4">
          {/* Search Names */}
          <div className="flex-1 relative">
            <input
              type="text"
              placeholder="Search worker names..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full border border-gray-300 rounded-md p-2 pl-10 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
          
          {/* Search Job Titles */}
          <div className="flex-1 relative">
            <input
              type="text"
              placeholder="Search job titles..."
              value={jobTitleFilter}
              onChange={(e) => setJobTitleFilter(e.target.value)}
              className="w-full border border-gray-300 rounded-md p-2 pl-10 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <Briefcase className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:items-center gap-4">
          {/* Date Range */}
          <div className="flex items-center space-x-2">
            <div>
              <label className="block text-sm text-gray-600 mb-1">Start Date</label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm text-gray-600 mb-1">End Date</label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>

          {/* Status Filter */}
          <div>
            <label className="block text-sm text-gray-600 mb-1">Status</label>
            <select
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="All">All Statuses</option>
              <option value="Paid">Paid</option>
              <option value="Pending">Pending</option>
              <option value="Failed">Failed</option>
            </select>
          </div>

          {/* Worker Type Filter */}
          <div>
            <label className="block text-sm text-gray-600 mb-1">Worker Type</label>
            <select
              value={workerType}
              onChange={(e) => setWorkerType(e.target.value)}
              className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="all">All Workers</option>
              <option value="agency">Agency Workers</option>
              <option value="non-agency">Non-Agency Workers</option>
            </select>
          </div>

          {/* Clear Filters Button */}
          <div>
            <label className="block text-sm text-gray-600 mb-1">&nbsp;</label>
            <button
              onClick={() => {
                setSearchTerm('');
                setJobTitleFilter('');
                setStartDate('');
                setEndDate('');
                setFilterStatus('All');
                setWorkerType('all');
              }}
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Clear Filters
            </button>
          </div>
        </div>
      </div>
      {/* Workers Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr className="bg-gray-50 text-gray-600 uppercase text-sm leading-normal">
              <th
                className="py-3 px-4 text-left cursor-pointer"
                onClick={() => handleSort('firstName')}
              >
                Worker Name {renderSortIcon('firstName')}
              </th>
              <th
                className="py-3 px-4 text-left cursor-pointer"
                onClick={() => handleSort('jobTitle')}
              >
                Job Title/Role {renderSortIcon('jobTitle')}
              </th>
              <th
                className="py-3 px-4 text-left cursor-pointer"
                onClick={() => handleSort('payPeriod')}
              >
                Pay Period {renderSortIcon('payPeriod')}
              </th>
              <th
                className="py-3 px-4 text-left cursor-pointer"
                onClick={() => handleSort('currentGrossPay')}
              >
                Gross Pay {renderSortIcon('currentGrossPay')}
              </th>
              <th
                className="py-3 px-4 text-left cursor-pointer"
                onClick={() => handleSort('currentDeductions')}
              >
                Deductions {renderSortIcon('currentDeductions')}
              </th>
              <th
                className="py-3 px-4 text-left cursor-pointer"
                onClick={() => handleSort('currentNetPay')}
              >
                Net Pay {renderSortIcon('currentNetPay')}
              </th>
              <th
                className="py-3 px-4 text-left cursor-pointer"
                onClick={() => handleSort('paymentStatus')}
              >
                Payment Status {renderSortIcon('paymentStatus')}
              </th>
              <th
                className="py-3 px-4 text-left cursor-pointer"
                onClick={() => handleSort('lastPaymentDate')}
              >
                Last Payment Date {renderSortIcon('lastPaymentDate')}
              </th>
              <th className="py-3 px-4 text-left">Agency</th>
              <th className="py-3 px-4 text-left">Actions</th>
            </tr>
          </thead>
          <tbody className="text-gray-700 text-sm">
            {filteredWorkers.map((worker, index) => (
                <tr
                  key={worker.id}
                  onClick={() => setSelectedWorker(worker)}
                  className={`border-b border-gray-200 hover:bg-gray-50 cursor-pointer ${
                    index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                  }`}
                >
                <td className="py-3 px-4 text-left whitespace-nowrap">
                  {worker.firstName} {worker.surname}
                </td>
                <td className="py-3 px-4 text-left">{worker.jobTitle}</td>
                <td className="py-3 px-4 text-left">{worker.payPeriod}</td>
                <td className="py-3 px-4 text-left">£{worker.currentGrossPay.toFixed(2)}</td>
                <td className="py-3 px-4 text-left">£{worker.currentDeductions.toFixed(2)}</td>
                <td className="py-3 px-4 text-left">£{worker.currentNetPay.toFixed(2)}</td>
                <td className="py-3 px-4 text-left">
                  <span
                    className={`px-2 py-1 rounded-full text-xs font-medium ${getStatusColor(
                      worker.paymentStatus
                    )}`}
                  >
                    {worker.paymentStatus}
                  </span>
                </td>
                <td className="py-3 px-4 text-left">{worker.lastPaymentDate}</td>
                <td className="py-3 px-4 text-left">
                  {worker.isAgencyWorker ? worker.agency : 'N/A'}
                </td>
                <td className="py-3 px-4 text-left relative">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowActionMenu(showActionMenu === worker.id ? null : worker.id);
                    }}
                    className="inline-flex items-center px-3 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Actions
                    <ChevronDown className={`ml-2 h-4 w-4 transition-transform ${showActionMenu === worker.id ? 'rotate-180' : ''}`} />
                  </button>

                  {showActionMenu === worker.id && (
                    <>
                      <div 
                        className="fixed inset-0 h-full w-full z-40" 
                        onClick={() => setShowActionMenu(null)}
                      />
                      <div className="relative z-50">
                        <ActionMenu 
                          worker={worker} 
                          onClose={() => setShowActionMenu(null)} 
                        />
                      </div>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  const handleAddPayment = (workerId) => {
    console.log(`Adding payment for worker ${workerId}`);
    notify(`Adding payment for worker ID: ${workerId}`, 'info');
  };

  const handleUpdateTaxCode = (workerId) => {
    console.log(`Updating tax code for worker ${workerId}`);
    notify(`Updating tax code for worker ID: ${workerId}`, 'info');
  };

  const handleManageBenefits = (workerId) => {
    console.log(`Managing benefits for worker ${workerId}`);
    notify(`Managing benefits for worker ID: ${workerId}`, 'info');
  };

  const handleUpdatePension = (workerId) => {
    console.log(`Updating pension for worker ${workerId}`);
    notify(`Updating pension for worker ID: ${workerId}`, 'info');
  };

  const renderWorkerDetails = () => {
    if (!selectedWorker) return null;

    return (
      <PayrollDetailsModal
        worker={selectedWorker}
        onClose={() => setSelectedWorker(null)}
        onUploadDocument={uploadDocument}
        onApprovePayroll={approvePayroll}
        onEditDetails={editPayrollDetails}
        onAddNote={addNote}
        onRecordIssue={recordPaymentIssue}
        onViewPayslip={viewPayslip}
        onAddPayment={handleAddPayment}
        onUpdateTaxCode={handleUpdateTaxCode}
        onManageBenefits={handleManageBenefits}
        onUpdatePension={handleUpdatePension}
      />
    );
  };

  const renderPayrollDashboard = () => (
    <div className="bg-white p-6 rounded-lg shadow mb-6">
      <h2 className="text-xl font-bold mb-4 text-gray-800">Payroll Dashboard</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="bg-blue-50 p-4 rounded-lg border border-blue-100">
          <h3 className="font-semibold mb-2 text-gray-700">Total Gross Payroll</h3>
          <p className="text-2xl font-bold text-gray-800">
            £{workers.reduce((sum, worker) => sum + worker.currentGrossPay, 0).toFixed(2)}
          </p>
        </div>
        <div className="bg-blue-50 p-4 rounded-lg border border-blue-100">
          <h3 className="font-semibold mb-2 text-gray-700">Total Deductions</h3>
          <p className="text-2xl font-bold text-gray-800">
            £{workers.reduce((sum, worker) => sum + worker.currentDeductions, 0).toFixed(2)}
          </p>
        </div>
        <div className="bg-blue-50 p-4 rounded-lg border border-blue-100">
          <h3 className="font-semibold mb-2 text-gray-700">Total Net Payroll</h3>
          <p className="text-2xl font-bold text-gray-800">
            £{workers.reduce((sum, worker) => sum + worker.currentNetPay, 0).toFixed(2)}
          </p>
        </div>
      </div>
      {/* Compliance Alerts */}
      <div className="bg-yellow-50 p-4 rounded-lg mt-4 border border-yellow-100">
        <h3 className="font-semibold mb-2 text-gray-700">Compliance Alerts</h3>
        <ul className="list-disc pl-5 text-gray-700">
          <li className="text-yellow-600">2 workers have missing tax codes</li>
          <li className="text-red-600">1 worker is below minimum wage threshold</li>
        </ul>
      </div>
    </div>
  );

  const renderPlaceholder = (title) => (
    <div className="bg-white p-6 rounded-lg shadow">
      <h3 className="text-lg font-semibold mb-2 text-gray-800">{title}</h3>
      <p className="text-gray-600">This section is under development.</p>
    </div>
  );

  return (
    <div className="space-y-6">
      {renderPayrollDashboard()}
      {renderPayroll()}
      {renderWorkerDetails()}
    </div>
  );
};

export default Payroll;