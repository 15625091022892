// Worker.js

import React, { useState, useEffect } from 'react';
import {
  User,
  Search,
  ChevronDown,
  ChevronUp,
  FileText,
  XCircle,
  Briefcase,
  Calendar,
  Mail,
  Phone,
} from 'lucide-react';
import { Button } from './components/ui/Button';
import { Card, CardContent, CardHeader, CardTitle } from './components/ui/Card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from './components/ui/Table';
import { Badge } from './components/ui/Badge';
import { Input } from './components/ui/Input';
import WorkerDetail from './WorkerDetail';

const fetchWorkers = () => [
  {
    id: '1',
    name: 'John Doe',
    avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
    jobTitle: 'Electrician',
    email: 'john.doe@example.com',
    location: 'London, UK',
    appliedDate: '2023-07-01T10:30:00Z',
    phone: '+44 123 456 7890',
    applicationStatus: 'Interview',
    score: 85,
    latestActions: [
      { date: '2023-07-05T14:30:00Z', action: 'Completed safety training' },
      { date: '2023-07-03T11:00:00Z', action: 'Submitted additional documents' },
      { date: '2023-07-02T09:15:00Z', action: 'Scheduled interview' },
    ],
  },
  {
    id: '2',
    name: 'Jane Smith',
    avatar: 'https://randomuser.me/api/portraits/women/1.jpg',
    jobTitle: 'Plumber',
    email: 'jane.smith@example.com',
    location: 'Manchester, UK',
    appliedDate: '2023-06-28T09:45:00Z',
    phone: '+44 987 654 3210',
    applicationStatus: 'Onboarding',
    score: 92,
    latestActions: [
      { date: '2023-07-04T16:00:00Z', action: 'Signed employment contract' },
      { date: '2023-07-02T13:30:00Z', action: 'Completed background check' },
      { date: '2023-06-30T10:00:00Z', action: 'Passed skills assessment' },
    ],
  },
  // Add more dummy workers as needed
];

const Worker = () => {
  const [workers, setWorkers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedWorkerId, setExpandedWorkerId] = useState(null);
  const [filterStatus, setFilterStatus] = useState('All');
  const [sortConfig, setSortConfig] = useState(null);

  useEffect(() => {
    const data = fetchWorkers();
    setWorkers(data);
  }, []);

  const getStatusColor = (status) => {
    switch (status) {
      case 'Applied':
        return 'bg-blue-100 text-blue-800';
      case 'Interview':
        return 'bg-yellow-100 text-yellow-800';
      case 'Onboarding':
        return 'bg-green-100 text-green-800';
      case 'Hired':
        return 'bg-green-200 text-green-800';
      case 'Rejected':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const handleSort = (key) => {
    if (sortConfig && sortConfig.key === key) {
      setSortConfig(
        sortConfig.direction === 'ascending'
          ? { key, direction: 'descending' }
          : null
      );
    } else {
      setSortConfig({ key, direction: 'ascending' });
    }
  };

  const renderSortIcon = (key) => {
    if (!sortConfig || sortConfig.key !== key) {
      return <ChevronDown className="ml-1 h-4 w-4 text-gray-400" />;
    }
    return sortConfig.direction === 'ascending' ? (
      <ChevronUp className="ml-1 h-4 w-4 text-gray-400" />
    ) : (
      <ChevronDown className="ml-1 h-4 w-4 text-gray-400" />
    );
  };

  const filteredWorkers = workers
    .filter((worker) => {
      const nameMatch = worker.name.toLowerCase().includes(searchTerm.toLowerCase());
      const statusMatch = filterStatus === 'All' || worker.applicationStatus === filterStatus;
      return nameMatch && statusMatch;
    })
    .sort((a, b) => {
      if (!sortConfig) return 0;
      const { key, direction } = sortConfig;
      let aValue = a[key];
      let bValue = b[key];

      if (key === 'appliedDate') {
        aValue = new Date(aValue);
        bValue = new Date(bValue);
      } else {
        aValue = aValue.toString().toLowerCase();
        bValue = bValue.toString().toLowerCase();
      }

      if (aValue < bValue) return direction === 'ascending' ? -1 : 1;
      if (aValue > bValue) return direction === 'ascending' ? 1 : -1;
      return 0;
    });

  const toggleWorkerDetail = (workerId) => {
    setExpandedWorkerId(expandedWorkerId === workerId ? null : workerId);
  };

  return (
    <div className="space-y-6">
      <Card className="bg-white shadow-lg p-4">
        <CardHeader className="bg-white">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <div className="p-2 rounded-lg">
                <User className="h-6 w-6 text-blue-600" />
              </div>
              <CardTitle className="text-xl font-bold text-gray-800">
                Workers
              </CardTitle>
            </div>
          </div>
        </CardHeader>
        <CardContent className="pt-0">
          {/* Filters */}
          <div className="flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 md:space-x-4 mb-6">
            <div className="flex-1 relative">
              <Input
                type="text"
                placeholder="Search workers..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10"
              />
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
            <select
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              className="w-[180px] border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="All">All Statuses</option>
              <option value="Applied">Applied</option>
              <option value="Interview">Interview</option>
              <option value="Onboarding">Onboarding</option>
              <option value="Hired">Hired</option>
              <option value="Rejected">Rejected</option>
            </select>
          </div>

          {/* Table */}
          <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow className="bg-gray-50">
                  <TableHead
                    className="py-3 px-4 text-left cursor-pointer font-bold text-gray-600 text-sm"
                    onClick={() => handleSort('name')}
                  >
                    <div className="flex items-center">
                      <User className="h-4 w-4 text-blue-600 mr-2" />
                      Name {renderSortIcon('name')}
                    </div>
                  </TableHead>
                  <TableHead
                    className="cursor-pointer font-bold text-gray-600 text-sm"
                    onClick={() => handleSort('jobTitle')}
                  >
                    <div className="flex items-center">
                      <Briefcase className="h-4 w-4 text-blue-600 mr-2" />
                      Job Title {renderSortIcon('jobTitle')}
                    </div>
                  </TableHead>
                  <TableHead
                    className="cursor-pointer font-bold text-gray-600 text-sm"
                    onClick={() => handleSort('applicationStatus')}
                  >
                    <div className="flex items-center">
                      <FileText className="h-4 w-4 text-blue-600 mr-2" />
                      Status {renderSortIcon('applicationStatus')}
                    </div>
                  </TableHead>
                  <TableHead
                    className="cursor-pointer font-bold text-gray-600 text-sm"
                    onClick={() => handleSort('appliedDate')}
                  >
                    <div className="flex items-center">
                      <Calendar className="h-4 w-4 text-blue-600 mr-2" />
                      Applied Date {renderSortIcon('appliedDate')}
                    </div>
                  </TableHead>
                  <TableHead className="font-bold text-gray-600 text-sm">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredWorkers.map((worker, index) => (
                  <React.Fragment key={worker.id}>
                    <TableRow
                      className={`border-b border-gray-200 hover:bg-gray-50 ${
                        index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                      } ${expandedWorkerId === worker.id ? 'border-b-0' : ''}`}
                    >
                      <TableCell className="font-medium">
                        <div className="flex items-center">
                          <img
                            src={worker.avatar}
                            alt={worker.name}
                            className="w-8 h-8 rounded-full mr-2"
                          />
                          {worker.name}
                        </div>
                      </TableCell>
                      <TableCell>{worker.jobTitle}</TableCell>
                      <TableCell>
                        <Badge className={getStatusColor(worker.applicationStatus)}>
                          {worker.applicationStatus}
                        </Badge>
                      </TableCell>
                      <TableCell>
                        {new Date(worker.appliedDate).toLocaleDateString()}
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() => toggleWorkerDetail(worker.id)}
                          variant="outline"
                          size="sm"
                          className="inline-flex items-center"
                        >
                          {expandedWorkerId === worker.id ? (
                            <>
                              <XCircle className="h-4 w-4 mr-2" />
                              Close Details
                            </>
                          ) : (
                            <>
                              <FileText className="h-4 w-4 mr-2" />
                              View Details
                            </>
                          )}
                        </Button>
                      </TableCell>
                    </TableRow>
                    {expandedWorkerId === worker.id && (
                      <TableRow className="border-b border-gray-200">
                        <TableCell colSpan={5} className="p-0">
                          <div className="">
                            <WorkerDetail
                              worker={worker}
                              onClose={() => setExpandedWorkerId(null)}
                              isInline={true}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Worker;