// CustomCreatableSelect.jsx
import React from "react";
import CreatableSelect from "react-select/creatable";

const CustomCreatableSelect = ({
  options,
  value = [], // Add default empty array
  onChange,
  onCreateOption,
  isInvalid,
  placeholder = "Select or create...",
  className = "",
  isMulti = true,
}) => {
  // Transform the options into the format required by react-select
  const transformedOptions = options.map((option) => ({
    value: option.id,
    label: option.name,
  }));

  // Transform the selected values into the format required by react-select
  // Add null check for value
  const selectedValues = Array.isArray(value)
    ? value
        .map((id) => {
          const option = options.find((opt) => opt.id === id);
          return option
            ? {
                value: option.id,
                label: option.name,
              }
            : null;
        })
        .filter(Boolean)
    : []; // Return empty array if value is not an array
  // Custom styles
  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: isInvalid ? "#ef4444" : "#d1d5db",
      "&:hover": {
        borderColor: "#9ca3af",
      },
      boxShadow: state.isFocused ? "0 0 0 1px #6366f1" : "none",
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isSelected
        ? "#6366f1" // indigo-500 for selected
        : isFocused
          ? "#e0e7ff" // indigo-100 for hover
          : null,
      color: isSelected ? "white" : "#374151",
      ":active": {
        backgroundColor: "#6366f1", // indigo-500
      },
      ":hover": {
        backgroundColor: "#6366f1", // indigo-500
        color: "white",
      },
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "#e0e7ff", // indigo-100
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#201e61", // indigo-600
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      ":hover": {
        backgroundColor: "#ef4444",
        color: "white",
      },
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 999,
    }),
  };
  const handleChange = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    onChange(selectedValues);
  };

  return (
    <CreatableSelect
      isMulti={isMulti}
      className={`${className} ${isInvalid ? "border-red-500" : ""}`}
      classNamePrefix="select"
      value={selectedValues}
      onChange={handleChange}
      options={transformedOptions}
      styles={customStyles}
      placeholder={placeholder}
      formatCreateLabel={(inputValue) => `Create "${inputValue}"`}
      onCreateOption={onCreateOption}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary: "indigo-500",
        },
      })}
    />
  );
};

export default CustomCreatableSelect;
