import React from 'react';

const AboutStep = ({ formData, onChange, checkFieldInvalid }) => {
  return (
    <div className="flex flex-col gap-4 max-w-[456px]">
      {/* Company Name */}
      <div>
        <label className="block text-gray-700 text-sm font-medium mb-2">
          Company Name <span className="text-red-500 font-bold">*</span>
        </label>
        <input
          type="text"
          className= {`w-full px-3 py-2 border border-gray-300 rounded-md ${checkFieldInvalid("company") ? "border-red-500" : "border-gray-300"}`}
          value={formData.company}
          onChange={(e) => onChange('company', e.target.value)}
        />
      </div>

      {/* Company Email */}
      <div>
        <label className="block text-gray-700 text-sm font-medium mb-2">
          Company Email <span className="text-red-500 font-bold">*</span>
        </label>
        <input
          type="email"
          className= {`w-full px-3 py-2 border border-gray-300 rounded-md ${checkFieldInvalid("company_email") ? "border-red-500" : "border-gray-300"}`}
          value={formData.company_email}
          onChange={(e) => onChange('company_email', e.target.value)}
        />
      </div>

      {/* Phone */}
      <div>
        <label className="block text-gray-700 text-sm font-medium mb-2">
          Phone Number <span className="text-red-500 font-bold">*</span>
        </label>
        <input
          type="tel"
          className= {`w-full px-3 py-2 border border-gray-300 rounded-md ${checkFieldInvalid("phone") ? "border-red-500" : "border-gray-300"}` }
          value={formData.phone}
          onChange={(e) => onChange('phone', e.target.value)}
        />
      </div>

      {/* Company Website */}
      <div>
        <label className="block text-gray-700 text-sm font-medium mb-2">
          Company Website
        </label>
        <input
          type="url"
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          value={formData.company_website}
          onChange={(e) => onChange('company_website', e.target.value)}
          placeholder="https://"
        />
      </div>

      {/* Company Slogan */}
      <div>
        <label className="block text-gray-700 text-sm font-medium mb-2">
          Company Slogan
        </label>
        <input
          type="text"
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          value={formData.company_slogan}
          onChange={(e) => onChange('company_slogan', e.target.value)}
        />
      </div>

      {/* Company Overview */}
      <div>
        <label className="block text-gray-700 text-sm font-medium mb-2">
          Company Overview
        </label>
        <textarea
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          rows={6}
          value={formData.company_overview_text}
          onChange={(e) => onChange('company_overview_text', e.target.value)}
          placeholder="Tell potential candidates about your company..."
        />
      </div>
    </div>
  );
};

export default AboutStep;
