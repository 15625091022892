// RightsToWorkDetail.js
"use client";

import React from 'react';
import {
  ArrowRight,
  ArrowLeft,  
  XCircle,
  Clock,
  ExternalLink,
  User,
  Fingerprint,
  Calendar,
  Globe,
  FileCheck,
  Briefcase,
  ShieldCheck,
  AlertTriangle,
  AlertCircle,
  CheckCircle2,
  Mail,
  Phone,
  Building,
  FileBadge,
  Flag,
  CreditCard,
} from 'lucide-react';
import { Card } from './components/ui/Card';
import { Badge } from './components/ui/Badge';

const RightsToWorkDetail = ({ worker, onClose, isInline = false }) => {
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'pass':
      case 'completed':
        return 'bg-green-100 text-green-800';
      case 'fail':
      case 'failed':
        return 'bg-red-100 text-red-800';
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-GB', {
      day: '2-digit',
      month: 'numeric',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
  };

  const calculateTimeDifference = (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    const diffTime = Math.abs(d2 - d1);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    
    if (diffDays > 0) {
      return `${diffDays}d ${diffHours}h`;
    }
    return `${diffHours}h`;
  };

  const calculateTotalElapsedTime = (timeline) => {
    const firstDate = new Date(timeline[0].date);
    const lastDate = new Date(timeline[timeline.length - 1].date);
    const diffTime = Math.abs(lastDate - firstDate); // Fixed: firstTime -> firstDate
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    return `${diffDays} days ${diffHours} hours total elapsed time`;
  };

  const renderTimeline = (worker) => (
    <div className="flex justify-center py-4">    
      <div className="bg-white rounded-lg border border-gray-200 shadow-sm w-3/5">
        <div className="px-6 py-4 border-b border-gray-200 bg-gray-50">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Clock className="h-5 w-5 text-blue-600 mr-2" />
              <h3 className="text-lg font-semibold text-lg font-semibold">Right-to-Work Timeline</h3>
            </div>
            <div className="flex items-center text-sm text-gray-500">
              <Clock className="h-4 w-4 mr-2" />
              <span>{calculateTotalElapsedTime(worker.timeline)}</span>
            </div>
          </div>
        </div>
        <div className="p-6">
          <div className="relative">
            {worker.timeline.map((event, index) => {
              const isWorkerEvent = event.source === 'worker';
              const alignClass = isWorkerEvent ? 'text-right' : 'text-left';
              const contentAlign = isWorkerEvent ? 'flex-row-reverse' : 'flex-row';
              const showTimeDiff = index < worker.timeline.length - 1;
              
              // Get border color based on status
              const getBorderColor = (status) => {
                switch (status.toLowerCase()) {
                  case 'completed':
                    return 'border-green-200';
                  case 'pending':
                    return 'border-yellow-200';
                  case 'failed':
                    return 'border-red-200';
                  default:
                    return 'border-gray-200';
                }
              };

              return (
                <div key={index} className="relative py-3">
                  <div className={`mb-0 flex ${contentAlign} items-center`}>
                    {/* Center line */}
                    <div className="absolute left-1/2 transform -translate-x-1/2 w-1 bg-gradient-to-b from-blue-200 to-blue-100 h-full"></div>
                    
                    {/* Horizontal connector line - Now using dashed border */}
                    <div className={`absolute top-1/2 transform -translate-y-1/2 ${
                      isWorkerEvent ? 'right-1/2' : 'left-1/2'
                    } w-[calc(8%+8px)] h-0 border-t-2 border-dashed border-gray-300`}></div>

                    {/* Status dot with ring effect */}
                    <div className="absolute left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2">
                      <div className={`w-7 h-7 rounded-full ${getStatusColor(event.status)} ring-4 ring-white shadow-md flex items-center justify-center`}>
                        <div className={`w-2 h-2 rounded-full ${
                          event.status.toLowerCase() === 'completed' 
                            ? 'bg-green-500' 
                            : event.status.toLowerCase() === 'failed'
                            ? 'bg-red-500'
                            : 'bg-yellow-500'
                        }`}></div>
                      </div>
                    </div>

                    {/* Event content */}
                    <div className={`w-[42%] ${alignClass} relative`}>
                      <div className={`p-4 bg-white rounded-lg border-2 ${getBorderColor(event.status)} shadow-sm hover:shadow-md transition-shadow duration-200`}>
                        {/* Performer info */}
                        <div className={`flex ${isWorkerEvent ? 'justify-end' : 'justify-start'} items-center text-sm mb-2`}>
                          <div className="flex items-center text-gray-500">
                            <User className={`${isWorkerEvent ? 'ml-2' : 'mr-2'} h-4 w-4 ${isWorkerEvent ? 'text-blue-600' : 'text-green-600'}`} />
                            <span>
                              {isWorkerEvent 
                                ? `${worker.firstName} ${worker.surname}`
                                : event.performedBy || 'Sarah Johnson (HR Officer)'
                              }
                            </span>
                          </div>
                        </div>
                        <div className={`flex ${isWorkerEvent ? 'justify-end' : 'justify-start'} items-center text-sm`}>
                          <Calendar className={`${isWorkerEvent ? 'ml-2' : 'mr-2'} h-4 w-4 ${isWorkerEvent ? 'text-blue-600' : 'text-green-600'}`} />
                          <span className="text-gray-500">{formatDate(event.date)}</span>
                        </div>
                        <h4 className={`text-md font-medium mt-2 text-gray-900 ${alignClass}`}>{event.title}</h4>
                        <p className={`text-sm text-gray-600 mt-1 ${alignClass}`}>{event.description}</p>
                        <div className={`mt-3 flex ${isWorkerEvent ? 'justify-end' : 'justify-start'} items-center`}>
                          <Badge className={`${getStatusColor(event.status)} px-2 py-1`}>
                            {event.status.charAt(0).toUpperCase() + event.status.slice(1)}
                          </Badge>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Time difference indicator - Now positioned between events with connecting lines */}
                  {showTimeDiff && (
                    <div className="absolute left-1/2 transform -translate-x-1/2 -mb-6 z-10">
                      <div className="flex items-center">
                        <div className="bg-white px-3 py-1 rounded-full border border-gray-200 shadow-sm">
                          <div className="flex items-center text-xs text-gray-500">
                            <Clock className="h-3 w-3 mr-1" />
                            {calculateTimeDifference(event.date, worker.timeline[index + 1].date)}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>    
  );

  const containerClasses = isInline 
    ? "py-4 px-6" 
    : "fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50";

  const contentClasses = isInline
    ? "bg-white"
    : "bg-white rounded-lg shadow-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto";

  const getStatusIcon = (status) => {
    switch (status.toLowerCase()) {
      case 'pass':
      case 'completed':
        return <CheckCircle2 className="h-5 w-5 text-green-600" />;
      case 'fail':
      case 'failed':
        return <AlertCircle className="h-5 w-5 text-red-600" />;
      case 'pending':
        return <AlertTriangle className="h-5 w-5 text-yellow-600" />;
      default:
        return <AlertTriangle className="h-5 w-5 text-gray-600" />;
    }
  };

  return (
    <div 
      className={containerClasses}
      onClick={!isInline ? onClose : undefined}
    >
      <div
        className={contentClasses}
        onClick={!isInline ? (e) => e.stopPropagation() : undefined}
      >
        <div className="p-6 space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Personal Information */}
            <div className="bg-white rounded-lg border border-gray-200 shadow-sm">
              <div className="px-6 py-4 border-b border-gray-200 bg-gray-50">
                <div className="flex items-center">
                  <User className="h-5 w-5 text-blue-600 mr-2" />
                  <h3 className="text-lg font-semibold text-lg font-semibold">
                    Personal Information
                  </h3>
                </div>
              </div>
              <div className="p-6">
                <dl className="grid grid-cols-1 gap-4">
                  <div className="flex items-start">
                    <FileBadge className="h-5 w-5 text-blue-600 mt-1 mr-3" />
                    <div>
                      <dt className="text-sm text-gray-500">Full Name</dt>
                      <dd className="mt-1 font-medium text-gray-900 text-lg">
                        {worker.firstName} {worker.surname}
                      </dd>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <Calendar className="h-5 w-5 text-blue-600 mt-1 mr-3" />
                    <div>
                      <dt className="text-sm text-gray-500">Date of Birth</dt>
                      <dd className="mt-1 font-medium text-gray-900">
                        {worker.dateOfBirth}
                      </dd>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <Flag className="h-5 w-5 text-blue-600 mt-1 mr-3" />
                    <div>
                      <dt className="text-sm text-gray-500">Nationality</dt>
                      <dd className="mt-1 font-medium text-gray-900">
                        {worker.nationality}
                      </dd>
                    </div>
                  </div>
                </dl>
              </div>
            </div>

            {/* Identity Verification */}
            <div className="bg-white rounded-lg border border-gray-200 shadow-sm">
              <div className="px-6 py-4 border-b border-purple-200 bg-gray-50">
                <div className="flex items-center">
                  <Fingerprint className="h-5 w-5 text-purple-600 mr-2" />
                  <h3 className="text-lg font-semibold text-lg font-semibold">
                    Identity Verification
                  </h3>
                </div>
              </div>
              <div className="p-6">
                <dl className="grid grid-cols-1 gap-4">
                  <div className="flex items-start">
                    <CreditCard className="h-5 w-5 text-purple-600 mt-1 mr-3" />
                    <div>
                      <dt className="text-sm text-gray-500">Document Number</dt>
                      <dd className="mt-1 font-medium text-gray-900">
                        {worker.passportNumber}
                      </dd>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <FileCheck className="h-5 w-5 text-purple-600 mt-1 mr-3" />
                    <div>
                      <dt className="text-sm text-gray-500">Document Type</dt>
                      <dd className="mt-1 font-medium text-gray-900">
                        {worker.documentType}
                      </dd>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <AlertTriangle className="h-5 w-5 text-purple-600 mt-1 mr-3" />
                    <div>
                      <dt className="text-sm text-gray-500">Expiry Date</dt>
                      <dd className="mt-1 font-medium text-gray-900">
                        {worker.documentExpiry}
                      </dd>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <Globe className="h-5 w-5 text-purple-600 mt-1 mr-3" />
                    <div>
                      <dt className="text-sm text-gray-500">Issuing Country</dt>
                      <dd className="mt-1 font-medium text-gray-900">
                        {worker.issuingCountry}
                      </dd>
                    </div>
                  </div>
                </dl>
              </div>
            </div>
            
			{/* Work Authorisation */}
            <div className="bg-white rounded-lg border border-gray-200 shadow-sm">
              <div className="px-6 py-4 border-b border-gray-200 bg-gray-50">
                <div className="flex items-center">
                  <Briefcase className="h-5 w-5 text-green-600 mr-2" />
                  <h3 className="text-lg font-semibold text-lg font-semibold">
                    Work Authorisation
                  </h3>
                </div>
              </div>
              <div className="p-6">
                <dl className="grid grid-cols-1 gap-4">
                  <div className="flex items-start">
                    <ShieldCheck className="h-5 w-5 text-green-600 mt-1 mr-3" />
                    <div>
                      <dt className="text-sm text-gray-500">Right to Work Status</dt>
                      <dd className="mt-1 font-medium text-gray-900">
                        {worker.rightToWorkStatus}
                      </dd>
                    </div>
                  </div>
                  {worker.visaNumber && (
                    <>
                      <div className="flex items-start">
                        <CreditCard className="h-5 w-5 text-green-600 mt-1 mr-3" />
                        <div>
                          <dt className="text-sm text-gray-500">Visa Number</dt>
                          <dd className="mt-1 font-medium text-gray-900">
                            {worker.visaNumber}
                          </dd>
                        </div>
                      </div>
                      <div className="flex items-start">
                        <FileCheck className="h-5 w-5 text-green-600 mt-1 mr-3" />
                        <div>
                          <dt className="text-sm text-gray-500">Visa Type</dt>
                          <dd className="mt-1 font-medium text-gray-900">
                            {worker.visaType}
                          </dd>
                        </div>
                      </div>
                      <div className="flex items-start">
                        <Calendar className="h-5 w-5 text-green-600 mt-1 mr-3" />
                        <div>
                          <dt className="text-sm text-gray-500">Visa Start Date</dt>
                          <dd className="mt-1 font-medium text-gray-900">
                            {worker.visaStartDate}
                          </dd>
                        </div>
                      </div>
                      <div className="flex items-start">
                        <AlertTriangle className="h-5 w-5 text-green-600 mt-1 mr-3" />
                        <div>
                          <dt className="text-sm text-gray-500">Visa Expiry Date</dt>
                          <dd className="mt-1 font-medium text-gray-900">
                            {worker.visaExpiryDate}
                          </dd>
                        </div>
                      </div>
                      <div className="flex items-start">
                        <AlertCircle className="h-5 w-5 text-green-600 mt-1 mr-3" />
                        <div>
                          <dt className="text-sm text-gray-500">Work Restrictions</dt>
                          <dd className="mt-1 font-medium text-gray-900">
                            {worker.workRestrictions || 'None'}
                          </dd>
                        </div>
                      </div>
                    </>
                  )}
                </dl>
              </div>
            </div>

            {/* Compliance Information */}
            <div className="bg-white rounded-lg border border-orange-200 shadow-sm">
              <div className="px-6 py-4 border-b border-gray-200 bg-gray-50">
                <div className="flex items-center">
                  <ShieldCheck className="h-5 w-5 text-yellow-600 mr-2" />
                  <h3 className="text-lg font-semibold text-lg font-semibold">
                    Compliance Information
                  </h3>
                </div>
              </div>
              <div className="p-6">
                <dl className="grid grid-cols-1 gap-4">
                  <div className="flex items-start">
                    <Calendar className="h-5 w-5 text-yellow-600 mt-1 mr-3" />
                    <div>
                      <dt className="text-sm text-gray-500">Date of Check</dt>
                      <dd className="mt-1 font-medium text-gray-900">
                        {worker.dateOfCheck}
                      </dd>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <FileCheck className="h-5 w-5 text-yellow-600 mt-1 mr-3" />
                    <div>
                      <dt className="text-sm text-gray-500">Check Method</dt>
                      <dd className="mt-1 font-medium text-gray-900">
                        {worker.checkMethod}
                      </dd>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <div className="mt-1 mr-3">
                      {getStatusIcon(worker.checkOutcome)}
                    </div>
                    <div>
                      <dt className="text-sm text-gray-500">Outcome of Check</dt>
                      <dd className="mt-1">
                        <span
                          className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(
                            worker.checkOutcome
                          )}`}
                        >
                          {worker.checkOutcome}
                        </span>
                      </dd>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <User className="h-5 w-5 text-yellow-600 mt-1 mr-3" />
                    <div>
                      <dt className="text-sm text-gray-500">Checked By</dt>
                      <dd className="mt-1 font-medium text-gray-900">
                        {worker.checkedBy}
                      </dd>
                    </div>
                  </div>
                </dl>
              </div>
            </div>
          </div>

          {/* Share Code Information */}
          {worker.shareCode && (
            <div className="bg-white rounded-lg border border-gray-200 shadow-sm">
              <div className="px-6 py-4 border-b border-gray-200 bg-gray-50">
                <div className="flex items-center">
                  <FileCheck className="h-5 w-5 text-indigo-600 mr-2" />
                  <h3 className="text-lg font-semibold text-lg font-semibold">
                    Share Code Information
                  </h3>
                </div>
              </div>
              <div className="p-6">
                <dl className="grid grid-cols-1 md:grid-cols-3 gap-6">
                  <div className="flex items-start">
                    <CreditCard className="h-5 w-5 text-indigo-600 mt-1 mr-3" />
                    <div>
                      <dt className="text-sm text-gray-500">Share Code</dt>
                      <dd className="mt-1 font-medium text-gray-900">
                        {worker.shareCode}
                      </dd>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <AlertTriangle className="h-5 w-5 text-indigo-600 mt-1 mr-3" />
                    <div>
                      <dt className="text-sm text-gray-500">Share Code Expiry</dt>
                      <dd className="mt-1 font-medium text-gray-900">
                        {worker.shareCodeExpiry}
                      </dd>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <ExternalLink className="h-5 w-5 text-indigo-600 mt-1 mr-3" />
                    <div>
                      <dt className="text-sm text-gray-500">Verification Link</dt>
                      <dd className="mt-1">
                        <a
                          href="https://www.gov.uk/view-right-to-work"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-600 hover:underline flex items-center"
                        >
                          Government's Online Checking Service
                          <ExternalLink className="ml-1 h-4 w-4 inline-block" />
                        </a>
                      </dd>
                    </div>
                  </div>
                </dl>
              </div>
            </div>
          )}

          {/* Timeline with enhanced styling */}          
          {renderTimeline(worker)}
          
        </div>
      </div>
    </div>
  );
};

export default RightsToWorkDetail;