import React from "react";
import CustomSelect from "../components/CustomSelect";

const TermsStep = ({ formData, onChange, checkFieldInvalid }) => {
  return (
    <div className="flex flex-col gap-4 max-w-[456px]">
      {/* Job Type and Graduate Job */}
      <div className="relative">
        <label className="block text-gray-700 text-sm font-medium mb-2">
          Job Type <span className="text-red-500 font-bold">*</span>
        </label>
        <div className="absolute top-0 right-0">
          <label className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={formData.is_graduate_job}
              onChange={(e) => onChange("is_graduate_job", e.target.checked)}
              className={`rounded`}
            />
            <span className="text-sm">Graduate Job</span>
          </label>
        </div>
        <CustomSelect
          options={[
            "Full Time",
            "Part Time",
            "Contract",
            "Temporary",
            "Internship",
          ]}
          value={formData.job_type ? formData.job_type.toLowerCase() : ""}
          onChange={(value) => onChange("job_type", value)}
          isMulti={false}
          placeholder="Select Job Type"
          isInvalid={checkFieldInvalid("job_type")}
          className="w-full"
        />
      </div>

      {/* Start Date Section */}
      <div>
        <label className="block text-gray-700 text-sm font-medium mb-2">
          Start Date <span className="text-red-500 font-bold">*</span>
        </label>
        <div className="flex gap-4 mb-2">
          <label className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={formData.start_asap}
              onChange={(e) => onChange("start_asap", e.target.checked)}
              className="rounded"
            />
            <span className="text-sm">ASAP</span>
          </label>
          <label className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={formData.no_start_date}
              onChange={(e) => onChange("no_start_date", e.target.checked)}
              className="rounded"
            />
            <span className="text-sm">No Start Date</span>
          </label>
        </div>
        <input
          type="date"
          className="w-[234px] px-3 py-2 border border-gray-300 rounded-md"
          value={formData.start_date}
          onChange={(e) => onChange("start_date", e.target.value)}
          disabled={formData.start_asap || formData.no_start_date}
        />
      </div>
      {/* Salary Section */}
      <div>
        <div className="flex gap-2 mb-2">
          <label className="block text-gray-700 text-sm font-medium">
            Salary <span className="text-red-500 font-bold">*</span>
          </label>
          <label className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={formData.is_salary_based_exp}
              onChange={(e) =>
                onChange("is_salary_based_exp", e.target.checked)
              }
              className="rounded"
            />
            <span className="text-sm">Based on experience</span>
          </label>
        </div>

        <div className="w-[234px] mb-4">
          <CustomSelect
            options={["Exact", "Range"]}
            value={formData.show_pay_by}
            onChange={(value) => onChange("show_pay_by", value)}
            isMulti={false}
            placeholder="Select Pay Type"
            className="w-full"
          />
        </div>

        <div className="flex gap-2">
          <div className="w-1/2">
            {formData.show_pay_by === "Range" ? (
              <div className="space-y-2">
                <div className="relative">
                  <span className="absolute left-3 top-2">£</span>
                  <input
                    type="number"
                    placeholder="Min"
                    className={`w-full pl-8 pr-3 py-2 border border-gray-300 rounded-md ${checkFieldInvalid("salary_min") ? "border-red-500" : "border-gray-300"}`}
                    value={formData.salary_min}
                    onChange={(e) => onChange("salary_min", e.target.value)}
                  />
                </div>
                <div className="relative">
                  <span className="absolute left-3 top-2">£</span>
                  <input
                    type="number"
                    placeholder="Max"
                    className={`w-full pl-8 pr-3 py-2 border border-gray-300 rounded-md ${checkFieldInvalid("salary_max") ? "border-red-500" : "border-gray-300"}`}
                    value={formData.salary_max}
                    onChange={(e) => onChange("salary_max", e.target.value)}
                  />
                </div>
              </div>
            ) : (
              <div className="relative">
                <span className="absolute left-3 top-2">£</span>
                <input
                  type="number"
                  className={`w-full pl-8 pr-3 py-2 border border-gray-300 rounded-md ${checkFieldInvalid("salary") ? "border-red-500" : "border-gray-300"}`}
                  value={formData.salary}
                  onChange={(e) => onChange("salary", e.target.value)}
                />
              </div>
            )}
          </div>
          <div className="w-1/2">
            <CustomSelect
              options={["yearly", "monthly", "weekly", "daily", "hourly"]}
              value={formData.salarytype}
              onChange={(value) => onChange("salarytype", value)}
              isMulti={false}
              placeholder="Salary Type"
              className="w-full"
            />
          </div>
        </div>
      </div>

      <div>
        <div className="mb-2">
          <label className="inline-block text-gray-700 text-sm font-medium">
            Working Hours
            <span className="text-red-500 font-bold">*</span>
          </label>
        </div>

        <div className="flex gap-4">
          <div className="w-1/3 space-y-2">
            <span className="text-sm">Hours</span>
            <div style={{ "margin-top": "0px" }} className="mt-0">
              <CustomSelect
                options={Array.from({ length: 24 }, (_, i) =>
                  (i + 1).toString(),
                )}
                value={formData.hours}
                onChange={(value) => onChange("hours", value)}
                isMulti={false}
                placeholder="Select Hrs"
                isInvalid={checkFieldInvalid("hours")}
                className="w-full"
              />
            </div>
          </div>

          <div className="w-1/3">
            <span className="text-sm">From Hour</span>
            <input
              type="time"
              id="hours_from"
              value={formData.hours_from}
              name="hours_from"
              placeholder="From Hours"
              className={`w-full px-3 py-2 border border-gray-300 rounded-md ${checkFieldInvalid("hours_from") ? "border-red-500" : "border-gray-300"}`}
              aria-invalid="false"
              onChange={(e) => onChange("hours_from", e.target.value)}
            />
          </div>

          <div className="w-1/3">
            <span className="text-sm">To Hour</span>
            <input
              type="time"
              id="hours_to"
              value={formData.hours_till}
              name="hours_to"
              placeholder="To Hours"
              className={`w-full px-3 py-2 border border-gray-300 rounded-md ${checkFieldInvalid("hours_till") ? "border-red-500" : "border-gray-300"}`}
              aria-invalid="false"
              onChange={(e) => onChange("hours_till", e.target.value)}
            />
          </div>
        </div>
      </div>

      {/* Breaks Section */}
      <div>
        <div className="mb-2">
          <label className="inline-block text-gray-700 text-sm font-medium">
            Breaks
          </label>
        </div>
        <div className="flex items-center gap-4">
          <div className="w-[160px]">
            <CustomSelect
              className="w-full"
              isMulti={false}
              value={
                formData.break_duration_min
                  ? {
                      id: formData.break_duration_min.toString(),
                      name: `${formData.break_duration_min} minute${formData.break_duration_min === "1" ? "" : "s"}`,
                    }
                  : null
              }
              onChange={(value) => onChange("break_duration_min", value)}
              options={[
                ...[...Array(60)].map((_, i) => ({
                  id: (i + 1).toString(),
                  name: `${i + 1} minute${i + 1 === 1 ? "" : "s"}`,
                })),
              ]}
              placeholder="Duration"
            />
          </div>
          <label className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={formData.is_break_paid}
              onChange={(e) => onChange("is_break_paid", e.target.checked)}
              className="rounded"
            />
            <span className="text-sm">Paid Break</span>
          </label>
        </div>
      </div>

      {/* Weekend Work Section */}
      <div>
        <div className="mb-4">
          <label className="text-gray-700 text-sm font-medium">
            Weekend Work Available
          </label>
        </div>

        {/* Saturday */}
        <div className="flex items-center gap-4 mb-4">
          <div className="w-[150px]">
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={formData.weekend_work_sat}
                onChange={(e) => onChange("weekend_work_sat", e.target.checked)}
                className="rounded"
              />
              <span className="text-sm">Saturday</span>
            </label>
          </div>
          <CustomSelect
            options={["Regular", "Occasional", "Overtime"]}
            value={formData.weekend_work_sat_options}
            onChange={(value) => onChange("weekend_work_sat_options", value)}
            isMulti={false}
            placeholder="Select Option"
            className="w-48" // w-48 is approximately 192px, closest to w-[185px]
            isDisabled={!formData.weekend_work_sat}
          />
        </div>

        {/* Sunday */}
        <div className="flex items-center gap-4 mb-4">
          <div className="w-[150px]">
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={formData.weekend_work_sun}
                onChange={(e) => onChange("weekend_work_sun", e.target.checked)}
                className="rounded"
              />
              <span className="text-sm">Sunday</span>
            </label>
          </div>
          <CustomSelect
            options={["Regular", "Occasional", "Overtime"]}
            value={formData.weekend_work_sun_options}
            onChange={(value) => onChange("weekend_work_sun_options", value)}
            isMulti={false}
            placeholder="Select Option"
            className="w-48" // w-48 is approximately 192px, closest to w-[185px]
            isDisabled={!formData.weekend_work_sun}
          />
        </div>

        {/* Bank Holidays */}
        <div className="flex items-center gap-4">
          <div className="w-[150px]">
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={formData.weekend_work_bank_holiday}
                onChange={(e) =>
                  onChange("weekend_work_bank_holiday", e.target.checked)
                }
                className="rounded"
              />
              <span className="text-sm">Bank Holidays</span>
            </label>
          </div>
          <CustomSelect
            options={["Regular", "Occasional", "Overtime"]}
            value={formData.weekend_work_bank_holiday_options}
            onChange={(value) =>
              onChange("weekend_work_bank_holiday_options", value)
            }
            isMulti={false}
            placeholder="Select Option"
            className="w-48" // w-48 is approximately 192px, closest to w-[185px]
            isDisabled={!formData.weekend_work_bank_holiday}
          />
        </div>
      </div>
    </div>
  );
};

export default TermsStep;
