import React, { useState } from "react";

const NoteAddForm = ({ jobseekerId, companyUserId, onSubmit }) => {
  const [note, setNote] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const company_user_id = companyUserId.id;
    const newNote = {
      jobseekerId,
      companyUserId: company_user_id ,
      note,
      createdOn: new Date(),
    };

    onSubmit(newNote);
    setNote("");
  };

  return (
    <div className="w-full max-w-md p-4 border rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">Add Note</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <textarea
            id="note"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            required
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
            rows="4"
            placeholder="Enter your note here..."
          />
        </div>
        <button
          type="submit"
          className="border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 w-full bg-blue-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:shadow-outline"
        >
          Add Note
        </button>
      </form>
    </div>
  );
};

export default NoteAddForm;
