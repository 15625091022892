// Onboarding.js
"use client";

import React, { useState } from 'react';
import {
  Briefcase,
  Search,
  FileText,
  XCircle,
  Phone,
  Building,
  HardHat,
  Wrench,
  Book,
  AlertTriangle,
  Shield as ShieldIcon,
  UserCheck,
  Coffee,
  CheckCircle,
  Clock,
} from 'lucide-react';
import { Button } from './components/ui/Button';
import { Progress } from './components/ui/Progress';
import { Input } from './components/ui/Input';
import { Badge } from './components/ui/Badge';
import OnboardingDetail from './OnboardingDetail';

export const onboardingTasksList = [
  { id: 'welcome_call', name: 'Welcome Call', icon: Phone },
  { id: 'pre_work_call', name: 'Pre-work Call', icon: Briefcase },
  { id: 'document_collection', name: 'Document Collection', icon: FileText },
  { id: 'safety_induction', name: 'Company Safety Induction', icon: HardHat },
  { id: 'tool_familiarization', name: 'Tool Familiarization', icon: Wrench },
  { id: 'electrical_code_review', name: 'Electrical Code Review', icon: Book },
  { id: 'first_aid_training', name: 'First Aid Training', icon: AlertTriangle },
  { id: 'company_policies', name: 'Company Policies', icon: ShieldIcon },
  { id: 'mentor_assignment', name: 'Mentor Assignment', icon: UserCheck },
  { id: 'first_day_checkin', name: 'First Day Check-in', icon: Coffee },
  { id: 'site_safety_induction', name: 'Site Safety Induction', icon: HardHat },
  { id: 'electrical_regulation_training', name: 'Electrical Regulations Training (BS 7671)', icon: Book },
  { id: 'ppe_training', name: 'Personal Protective Equipment (PPE) Training', icon: ShieldIcon },
  { id: 'risk_assessment_briefing', name: 'Risk Assessment Briefing', icon: AlertTriangle },
  { id: 'company_policy_review', name: 'Company Policies and Procedures', icon: FileText },
  { id: 'emergency_procedure_training', name: 'Emergency Procedures Training', icon: Phone },
  { id: 'vehicle_inspection', name: 'Company Vehicle Inspection and Handover', icon: Briefcase },
  { id: 'client_introduction', name: 'Client Introduction', icon: Building },
  { id: 'mentorship_assignment', name: 'Mentorship Assignment', icon: UserCheck },  
];

const dummyWorkers = [
  {
    id: '1',
    firstName: 'John',
    surname: 'Doe',
    jobTitle: 'Senior Electrician',
    startDate: '2023-01-15',
    avatar: 'https://randomuser.me/api/portraits/men/3.jpg',
    onboardingTasks: onboardingTasksList.map((task) => ({
      ...task,
      status: 'completed',
      completedDate: '2023-01-20',
      completedBy: 'Jane Smith',
      description: task.id === 'welcome_call' ? 'Discussed onboarding process and expectations.' : undefined,
      notes: task.id === 'document_collection' ? 'All documents submitted, except for the driving license.' : undefined,
      attachments: task.id === 'document_collection' ? ['document1.pdf', 'license_application.pdf'] : [],
    })),
  },
  {
    id: '2',
    firstName: 'Maria',
    surname: 'Garcia',
    jobTitle: 'Apprentice Electrician',
    startDate: '2023-03-01',
    avatar: 'https://randomuser.me/api/portraits/women/3.jpg',
    onboardingTasks: onboardingTasksList.map((task) => ({
      ...task,
      status:
        task.id === 'electrical_code_review'
          ? 'problem'
          : task.id === 'first_aid_training'
          ? 'not_completed'
          : 'completed',
      completedDate:
        task.id === 'electrical_code_review' || task.id === 'first_aid_training'
          ? undefined
          : '2023-03-05',
      completedBy:
        task.id === 'electrical_code_review' || task.id === 'first_aid_training'
          ? undefined
          : 'John Doe',
      description: task.id === 'electrical_code_review' ? 'Maria is struggling with advanced concepts.' : undefined,
      notes: task.id === 'first_aid_training' ? 'Needs to complete the online module first.' : undefined,
      attachments: task.id === 'electrical_code_review' ? ['training_material.pdf', 'code_notes.docx'] : [],
    })),
  },
];

const Onboarding = ({ workers = dummyWorkers }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [expandedWorkerId, setExpandedWorkerId] = useState(null);

  const getStatusColor = (status) => {
    switch (status) {
      case 'completed':
        return 'bg-green-100 text-green-800';
      case 'not_completed':
        return 'bg-yellow-100 text-yellow-800';
      case 'problem':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'completed':
        return <CheckCircle className="h-5 w-5 text-green-600" />;
        case 'not_completed':
        return <Clock className="h-5 w-5 text-yellow-600" />;
        case 'problem':
        return <AlertTriangle className="h-5 w-5 text-red-600" />;
        default:
        return null;
    }
  };

  const filteredWorkers = workers.filter(
    (worker) =>
    `${worker.firstName} ${worker.surname}`
    .toLowerCase()
    .includes(searchTerm.toLowerCase()) &&
    (filterStatus === 'all' ||
     worker.onboardingTasks.some((task) => task.status === filterStatus))
  );

  const toggleWorkerDetail = (workerId) => {
    setExpandedWorkerId(expandedWorkerId === workerId ? null : workerId);
  };

  const handleColumnEnter = (index) => {
    highlightColumn(index);
    document.querySelector(`[data-header="${index}"]`)?.classList.add('header-highlight');
  };

  const handleColumnLeave = (index) => {
    removeColumnHighlight(index);
    document.querySelector(`[data-header="${index}"]`)?.classList.remove('header-highlight');
  };  

return (
  <div className="space-y-6">
    <div className="bg-white p-6 rounded-lg shadow-lg">
      <div className="flex items-center mb-4">
        <div className="p-2 bg-blue-50 rounded-lg">
          <Briefcase className="h-6 w-6 text-blue-600" />
        </div>
        <h2 className="text-xl font-bold text-gray-800 ml-2">
          Onboarding Management
        </h2>
      </div>

      {/* Filters */}
      <div className="flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 md:space-x-4 mb-6">
        <div className="flex-1 relative">
          <Input
            type="text"
            placeholder="Search workers..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="pl-10"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
        <select
          value={filterStatus}
          onChange={(e) => setFilterStatus(e.target.value)}
          className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full md:w-64"
        >
          <option value="all">All Statuses</option>
          <option value="completed">Completed</option>
          <option value="not_completed">Not Completed</option>
          <option value="problem">Problem</option>
        </select>
      </div>

      {/* Workers Table */}
	  <div className="">
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr className="bg-gray-50">
                <th className="sticky left-0 z-10 py-3 px-4 text-left text-sm font-bold text-gray-500 uppercase tracking-wider bg-gray-50 min-w-[200px]">
                  Name
                </th>
                {onboardingTasksList.map((task, index) => (
                  <th
                    key={task.id}
                    className="w-[40px] h-[140px] relative group pl-7"
                    onMouseEnter={() => handleColumnEnter(index)}
                    onMouseLeave={() => handleColumnLeave(index)}
					data-header={index}                    
                  >
                    <div
                      className="task-name absolute bottom-0 left-0 origin-bottom-left transform -rotate-45 whitespace-nowrap text-xs text-gray-500 ml-5 mb-2 font-bold"
                      title={task.name}
                    >
                      {task.name}
                    </div>
                  </th>
                ))}
                <th className="py-3 px-4 text-center text-sm font-bold text-gray-500 uppercase tracking-wider w-[100px]">
                  Progress
                </th>
                <th className="sticky right-0 z-10 py-3 px-4 text-left text-sm font-bold text-gray-500 uppercase tracking-wider bg-gray-50 w-[120px]">
                  Actions
                </th>
              </tr>
            </thead>
          <tbody className="text-gray-700 text-sm">
            {filteredWorkers.map((worker, index) => (
              <React.Fragment key={worker.id}>
                <tr
                  className={`border-b border-gray-200 hover:bg-gray-50 ${
                    index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                  } ${expandedWorkerId === worker.id ? 'border-b-0' : ''}`}
                >
                  <td
                    className="sticky left-0 z-10 py-3 px-4 text-left whitespace-nowrap font-medium bg-white cursor-pointer"
                    onClick={() => toggleWorkerDetail(worker.id)}
                  >
                    {worker.firstName} {worker.surname}
                    <div className="text-sm text-gray-500">{worker.jobTitle}</div>
                  </td>
                  {onboardingTasksList.map((task, colIndex) => {
                    const workerTask = worker.onboardingTasks.find(
                      (t) => t.id === task.id
                    );
                    return (
                      <td
                        key={task.id}
                        className={`w-[40px] py-3 px-0 text-center align-middle column-${colIndex}`}
                        onMouseEnter={() => handleColumnEnter(colIndex)}
                        onMouseLeave={() => handleColumnLeave(colIndex)}                        
                      >
                        <div className="flex justify-center items-center status-icon">
                          {getStatusIcon(workerTask?.status || 'not_completed')}
                        </div>
                      </td>
                    );
                  })}
                  <td className="py-3 px-4 text-center">
                    <Progress
                      value={
                        (worker.onboardingTasks.filter(
                          (t) => t.status === 'completed'
                        ).length /
                          onboardingTasksList.length) *
                        100
                      }
                      className="w-24 mx-auto"
                    />
                  </td>
                  <td className="sticky right-0 z-10 py-3 px-4 text-left whitespace-nowrap bg-white">
                    <Button
                      onClick={() => toggleWorkerDetail(worker.id)}
                      variant="outline"
                      size="sm"
                      className="inline-flex items-center"
                    >
                      {expandedWorkerId === worker.id ? (
                        <>
                          <XCircle className="h-4 w-4 mr-2" />
                          Close Details
                        </>
                      ) : (
                        <>
                          <FileText className="h-4 w-4 mr-2" />
                          View Details
                        </>
                      )}
                    </Button>
                  </td>
                </tr>
                {expandedWorkerId === worker.id && (
                  <tr className="border-b border-gray-200">
                    <td colSpan={onboardingTasksList.length + 3} className="p-0">
                      <div className="border-t border-gray-200">
                        <OnboardingDetail
                          worker={worker}
                          onClose={() => setExpandedWorkerId(null)}
                          isInline={true}
                        />
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
          <style jsx>{`
            /* Header text animation */
            .header-highlight .task-name,
            .group:hover .task-name {
              font-size: calc(0.75rem * 1.5);
              transition: all 0.2s ease;
              cursor: pointer;
            }
            .task-name {
              transition: all 0.2s ease;
            }

            /* Status icon animation */
            .highlight .status-icon {
              transform: scale(1.25);
              transition: all 0.2s ease;
            }
            .status-icon {
              transition: all 0.2s ease;
            }

            .group:hover .group-hover\\:visible {
              visibility: visible;
            }
            .invisible {
              visibility: hidden;
            }
            th {
              height: 220px;
              vertical-align: bottom;
            }
            .highlight {
              background-color: rgba(100, 149, 237, 0.2);
            }
          `}</style>
      </div>
    </div>
  </div>
  );
};

// JavaScript functions to add column highlighting
const highlightColumn = (colIndex) => {
  document.querySelectorAll(`.column-${colIndex}`).forEach((cell) => {
    cell.classList.add('highlight');
  });
};

const removeColumnHighlight = (colIndex) => {
  document.querySelectorAll(`.column-${colIndex}`).forEach((cell) => {
    cell.classList.remove('highlight');
  });
};

// Ensure this export statement is at the top level of the file
export default Onboarding;