// components/ui/Input.js
import React from 'react';

export const Input = ({ className = "", ...props }) => {
  return (
    <input
      className={`
        w-1/2 rounded-md border border-gray-300 
        px-3 py-2 text-sm
        focus:outline-none focus:ring-2 focus:ring-blue-500
        disabled:cursor-not-allowed disabled:opacity-50
        ${className}
      `}
      {...props}
    />
  );
};

export default Input;