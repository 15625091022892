import React from "react";
import { X } from "lucide-react";

export const FileUpload = ({ files, onRemove }) => {
  const renderFilePreview = (file) => {
    const isImage = file.type.startsWith("image/");

    return (
      <div key={file.name} className="relative inline-block m-2">
        {isImage ? (
          <img
            src={URL.createObjectURL(file)}
            alt={file.name}
            className="w-20 h-20 object-cover rounded-md"
          />
        ) : (
          <div className="w-20 h-20 bg-gray-200 flex items-center justify-center rounded-md">
            <span className="text-xs text-center">{file.name}</span>
          </div>
        )}
        <button
          onClick={() => onRemove(file)}
          className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1"
        >
          <X size={12} />
        </button>
      </div>
    );
  };

  return (
    <div className="flex flex-wrap mb-2">{files.map(renderFilePreview)}</div>
  );
};
