// services/SpeechToTextService.js

import axios from 'axios';

const SpeechToTextService = {
  // Function to start audio recording and transcribe speech
  startRecognition: () => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log('Starting audio recognition...');

        // Start recording
        const mediaRecorder = await SpeechToTextService.startRecording();
        console.log('Recording started.');

        // Create a timeout or wait for a user action to stop recording
        setTimeout(async () => {
          console.log('Stopping recording after 5 seconds...');
          
          // Stop recording and get the audio blob
          const audioBlob = await SpeechToTextService.stopRecording(mediaRecorder);
          console.log('Recording stopped. Audio data captured:', audioBlob);

          // Transcribe the audio
          const transcription = await SpeechToTextService.transcribeAudio(audioBlob);
          console.log('Transcription result:', transcription);

          // Resolve with the transcription text
          resolve(transcription);
        }, 5000); // Adjust the recording duration as needed

      } catch (error) {
        console.error('Error during speech recognition:', error);
        reject(error);
      }
    });
  },

  // Function to start audio recording
  startRecording: () => {
    return new Promise((resolve, reject) => {
      console.log('Attempting to start audio recording...');
      
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        console.error('MediaDevices API not supported in this browser.');
        reject(new Error('MediaDevices API not supported.'));
        return;
      }

      navigator.mediaDevices.getUserMedia({ audio: true })
        .then((stream) => {
          console.log('Microphone access granted.');

          const mediaRecorder = new MediaRecorder(stream);
          mediaRecorder.start();
          console.log('MediaRecorder started:', mediaRecorder);
          
          resolve(mediaRecorder);
        })
        .catch((error) => {
          console.error('Error accessing microphone:', error);
          reject(error);
        });
    });
  },

  // Function to stop recording and get audio data
  stopRecording: (mediaRecorder) => {
    return new Promise((resolve) => {
      console.log('Stopping MediaRecorder...');

      const audioChunks = [];
      mediaRecorder.addEventListener('dataavailable', (event) => {
        console.log('Data available from MediaRecorder:', event.data);
        audioChunks.push(event.data);
      });

      mediaRecorder.addEventListener('stop', () => {
        console.log('MediaRecorder stopped, creating audio Blob...');
        
        const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
        console.log('Audio Blob created:', audioBlob);
        
        resolve(audioBlob);
      });

      mediaRecorder.stop();
    });
  },

  // Function to transcribe audio using Groq API
  transcribeAudio: async (audioBlob) => {
    const GROQ_API_URL = 'https://api.groq.com/openai/v1/audio/transcriptions';
    const GROQ_API_KEY = process.env.REACT_APP_GROQ_API_KEY;

    const formData = new FormData();
    formData.append('file', audioBlob, 'recording.webm');
    formData.append('model', 'whisper-large-v3-turbo');
    formData.append('response_format', 'json');
    formData.append('language', 'en');

    try {
      // Debugging Step: Log FormData entries
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }

      // Log the headers and URL for debugging
      console.log('Sending request to:', GROQ_API_URL);
      console.log('Authorization header:', `Bearer ${GROQ_API_KEY}`);

      const response = await axios.post(GROQ_API_URL, formData, {
        headers: {
          'Authorization': `Bearer ${GROQ_API_KEY}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        const transcription = response.data.text.trim();
        console.log('Transcription successful:', transcription);
        return transcription;
      } else {
        console.error(`Transcription Error: ${response.status}`);
        console.error(`Response content: ${JSON.stringify(response.data)}`);
        return '';
      }
    } catch (error) {
      // Enhanced error logging
      if (error.response) {
        console.error('Error response status:', error.response.status);
        console.error('Error response data:', error.response.data);
      } else {
        console.error('Error making the request:', error.message);
      }
      return '';
    }
  },
};

export default SpeechToTextService;
