// Avatar.js
import React from 'react';

// Main Avatar component
const Avatar = ({ children, className = '', ...props }) => {
  return (
    <div className={`inline-block rounded-full overflow-hidden ${className}`} {...props}>
      {children}
    </div>
  );
};

// Avatar Image component
export const AvatarImage = ({ src, alt, className = '', ...props }) => (
  <img src={src} alt={alt} className={`w-full h-full object-cover ${className}`} {...props} />
);

// Avatar Fallback component (used when the image fails to load or is not provided)
export const AvatarFallback = ({ children, className = '', ...props }) => (
  <div className={`flex items-center justify-center w-full h-full bg-gray-300 ${className}`} {...props}>
    {children}
  </div>
);

// Exporting Avatar as default and other components as named exports
export default Avatar;