import axios from "axios";
import { API_BASE_URL } from "./api";

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
    Accept: "application/json",
  },
});

export const subscriptionList = async () => {
  const token = localStorage.getItem("token");
  const response = await api.get(`/admin/stripe/subscription/list`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const createIntent = async (price) => {
  const token = localStorage.getItem("token");
  const response = await api.post(
    `/api/stripe/create/intent`,
    { price: price },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  );
  return response.data;
};

export const createSubscriptionSession = async (price) => {
  const token = localStorage.getItem("token");
  const response = await api.post(
    `/api/stripe/create/subscription/session`,
    { price: price },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  );
  return response.data;
};
