// OnboardingDetail.js
"use client";

import React, { useState } from 'react';
import {
  AlertTriangle,
  CheckCircle2,
  Clock,
  FileText,
  Briefcase,
  Calendar,
  User,
  XCircle,
  CheckCircle,
} from 'lucide-react';
import { Button } from './components/ui/Button';
import { Card, CardContent, CardHeader, CardTitle } from './components/ui/Card';
import { Badge } from './components/ui/Badge';
import { Label } from './components/ui/Label';
import { Textarea } from './components/ui/Textarea';
import { Input } from './components/ui/Input';
import { onboardingTasksList } from './Onboarding';

const OnboardingDetail = ({ worker, onClose, isInline = false }) => {
  const [selectedTask, setSelectedTask] = useState(null);
  const [taskStatus, setTaskStatus] = useState('');
  const [taskNote, setTaskNote] = useState('');
  const [taskDescription, setTaskDescription] = useState('');
  const [taskAttachments, setTaskAttachments] = useState('');

  const getStatusColor = (status) => {
    switch (status) {
      case 'completed':
        return 'bg-green-100 text-green-800';
      case 'not_completed':
        return 'bg-yellow-100 text-yellow-800';
      case 'problem':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  // Sort and separate tasks
  const { incompleteTasks, completedTasks } = worker.onboardingTasks.reduce(
    (acc, task) => {
      if (task.status === 'completed') {
        acc.completedTasks.push(task);
      } else {
        acc.incompleteTasks.push(task);
      }
      return acc;
    },
    { incompleteTasks: [], completedTasks: [] }
  );

  // Sort by date (ascending)
  const sortByDate = (tasks) => {
    return tasks.sort((a, b) => {
      const dateA = a.completedDate ? new Date(a.completedDate) : new Date(0);
      const dateB = b.completedDate ? new Date(b.completedDate) : new Date(0);
      return dateA - dateB;
    });
  };

  const sortedIncompleteTasks = sortByDate(incompleteTasks);
  const sortedCompletedTasks = sortByDate(completedTasks);  
  
  const handleTaskClick = (task) => {
    setSelectedTask(task);
    setTaskStatus(task.status);
    setTaskNote(task.note || '');
    setTaskDescription(task.description || '');
    setTaskAttachments(task.attachments ? task.attachments.join(', ') : '');
  };

  const handleSaveTask = () => {
    console.log(`Updating task ${selectedTask.id}:`);
    console.log(`Status: ${taskStatus}`);
    console.log(`Note: ${taskNote}`);
    console.log(`Description: ${taskDescription}`);
    console.log(`Attachments: ${taskAttachments.split(',').map(a => a.trim())}`);
    // Here you would save the updated task details to your backend
    setSelectedTask(null);
  };

  const containerClasses = isInline 
    ? "py-4 px-6" 
    : "fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50";

  const contentClasses = isInline
    ? "bg-white"
    : "bg-white rounded-lg shadow-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto";

  const TaskList = ({ tasks, title, containerClass, icon, taskItemClass }) => (
    <div className={`space-y-4 ${containerClass}`}>
      <div className="flex items-center space-x-3">
        {icon}
        <div className="flex items-center space-x-2">
          <h3 className="text-lg font-semibold text-gray-800">{title}</h3>
          <Badge variant="outline" className="ml-2 bg-white">
            {tasks.length} {tasks.length === 1 ? 'task' : 'tasks'}
          </Badge>
        </div>
      </div>
      <div className="space-y-3">
        {tasks.map((task) => (
          <div 
            key={task.id}
            className={`p-4 rounded-lg cursor-pointer ${taskItemClass}`}
            onClick={() => handleTaskClick(task)}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <div className="p-2 rounded-lg bg-gray-50">
                  {React.createElement(onboardingTasksList.find(t => t.id === task.id)?.icon || FileText, {
                    className: "h-5 w-5 text-blue-600"
                  })}
                </div>
                <div>
                  <p className="font-medium text-gray-900">
                    {onboardingTasksList.find(t => t.id === task.id)?.name}
                  </p>
                  {task.completedDate && (
                    <p className="text-sm text-gray-500">
                      Completed: {task.completedDate}
                    </p>
                  )}
                </div>
              </div>
              <Badge className={`${task.status === 'completed' ? 'bg-green-100 text-green-800' : 
                task.status === 'problem' ? 'bg-red-100 text-red-800' : 
                'bg-yellow-100 text-yellow-800'} px-3 py-1`}>
                {task.status.replace('_', ' ')}
              </Badge>
            </div>

            {(task.description || task.notes || task.attachments?.length > 0) && (
              <div className="mt-3 ml-12 space-y-2">
                {task.description && (
                  <p className="text-sm text-gray-700">
                    {task.description}
                  </p>
                )}
                {task.notes && (
                  <p className="text-sm text-gray-600 italic">
                    {task.notes}
                  </p>
                )}
                {task.attachments && task.attachments.length > 0 && (
                  <div className="text-sm text-gray-700">
                    <ul className="list-disc pl-5 space-y-1">
                      {task.attachments.map((attachment, index) => (
                        <li key={index}>
                          <a href="#" className="text-blue-600 hover:text-blue-800 hover:underline">
                            {attachment}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
  
  return (
    <div 
      className={containerClasses}
      onClick={!isInline ? onClose : undefined}
    >
      <div
        className={contentClasses}
        onClick={!isInline ? (e) => e.stopPropagation() : undefined}
      >
        <div className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Worker Information */}
            <Card className="md:col-span-1 border border-gray-200 shadow-sm">
              <CardHeader className="border-b bg-gray-50 p-4">
                <div className="flex items-center">
                  <User className="h-5 w-5 text-blue-600 mr-2" />
                  <CardTitle className="text-lg font-semibold">Worker Information</CardTitle>
                </div>
              </CardHeader>
              <CardContent className="p-4">
                <dl className="grid grid-cols-1 gap-4">
                  <div className="flex items-start">
                    <User className="h-5 w-5 text-blue-600 mt-1 mr-3" />
                    <div>
                      <dt className="text-sm text-gray-500">Full Name</dt>
                      <dd className="mt-1 font-medium text-gray-900 text-lg">
                        {worker.firstName} {worker.surname}
                      </dd>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <Briefcase className="h-5 w-5 text-blue-600 mt-1 mr-3" />
                    <div>
                      <dt className="text-sm text-gray-500">Job Title</dt>
                      <dd className="mt-1 font-medium text-gray-900">
                        {worker.jobTitle}
                      </dd>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <Calendar className="h-5 w-5 text-blue-600 mt-1 mr-3" />
                    <div>
                      <dt className="text-sm text-gray-500">Start Date</dt>
                      <dd className="mt-1 font-medium text-gray-900">
                        {worker.startDate}
                      </dd>
                    </div>
                  </div>
                </dl>
              </CardContent>
            </Card>

            {/* Onboarding Progress Card - Updated colors and styling */}
            <Card className="md:col-span-2 border border-gray-200 shadow-sm">
              <CardHeader className="border-b bg-gray-50 p-4">
                <div className="flex items-center">
                  <CheckCircle className="h-5 w-5 text-green-600 mr-2" />
                  <CardTitle className="text-lg font-semibold">Onboarding Progress</CardTitle>
                </div>
              </CardHeader>
              <CardContent className="p-4 space-y-6">
                {sortedIncompleteTasks.length > 0 && (
                  <div className="relative">
                    <div className="absolute inset-0 bg-gradient-to-r from-yellow-50 to-red-50 rounded-lg opacity-50" />
                    <TaskList 
                      tasks={sortedIncompleteTasks} 
                      title="Tasks In Progress" 
                      containerClass="relative border border-yellow-200 rounded-lg p-4"
                      icon={
                        <div className="p-1.5 rounded-full bg-yellow-100">
                          <AlertTriangle className="h-5 w-5 text-yellow-600" />
                        </div>
                      }
                      taskItemClass="bg-white hover:bg-yellow-50 border border-yellow-100 shadow-sm transition-all duration-200"
                    />
                  </div>
                )}
                {sortedCompletedTasks.length > 0 && (
                  <div className="relative">
                    <div className="absolute inset-0 bg-gradient-to-r from-green-50 to-teal-50 rounded-lg opacity-50" />
                    <TaskList 
                      tasks={sortedCompletedTasks} 
                      title="Completed Tasks" 
                      containerClass="relative border-2 border-green-200 rounded-lg p-4"
                      icon={
                        <div className="p-1.5 rounded-full bg-green-100">
                          <CheckCircle className="h-5 w-5 text-green-600" />
                        </div>
                      }
                      taskItemClass="bg-white hover:bg-green-50 border border-green-100 shadow-sm transition-all duration-200"
                    />
                  </div>
                )}
              </CardContent>
            </Card>
          </div>

          {/* Task Update Modal */}
          {selectedTask && (
            <div className="fixed -inset-10 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white rounded-lg shadow-lg max-w-md w-full" onClick={e => e.stopPropagation()}>
                <div className="px-6 py-4 border-b bg-gray-50 flex justify-between items-center rounded-lg">
                  <div className="flex items-center">
                    <FileText className="h-5 w-5 text-blue-600 mr-2" />
                    <h2 className="text-lg font-semibold text-gray-800">
                      Update Task Details
                    </h2>
                  </div>
                  <button
                    onClick={() => setSelectedTask(null)}
                    className="text-gray-500 hover:text-gray-700 p-2"
                  >
                    <XCircle className="h-5 w-5" />
                  </button>
                </div>
                <div className="p-6 space-y-4">
                  <div className="space-y-2">
                    <Label htmlFor="taskStatus" className="flex items-center">
                      <Clock className="h-4 w-4 text-blue-600 mr-2" />
                      Status
                    </Label>
                    <select
                      id="taskStatus"
                      value={taskStatus}
                      onChange={(e) => setTaskStatus(e.target.value)}
                      className="w-full rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      <option value="completed">Completed</option>
                      <option value="not_completed">Not Completed</option>
                      <option value="problem">Problem</option>
                    </select>
                  </div>

                  <div className="space-y-2">
                    <Label htmlFor="taskNote" className="flex items-center">
                      <FileText className="h-4 w-4 text-blue-600 mr-2" />
                      Note
                    </Label>
                    <Textarea
                      id="taskNote"
                      value={taskNote}
                      onChange={(e) => setTaskNote(e.target.value)}
                      placeholder="Add a note for this task..."
                      className="min-h-[100px]"
				      rows="8"
                    />
                  </div>

                  <div className="space-y-2">
                    <Label htmlFor="taskDescription" className="flex items-center">
                      <FileText className="h-4 w-4 text-blue-600 mr-2" />
                      Description
                    </Label>
                    <Textarea
                      id="taskDescription"
                      value={taskDescription}
                      onChange={(e) => setTaskDescription(e.target.value)}
                      placeholder="Add a description for this task..."
                      className="min-h-[100px]"
				      rows="8"
                    />
                  </div>

				  {/* Needs to change to a file upload form field */}
                  <div className="space-y-2">
                    <Label htmlFor="taskAttachments" className="flex items-center">
                      <FileText className="h-4 w-4 text-blue-600 mr-2" />
                      Attachments
                    </Label>
                    <Input
                      id="taskAttachments"
                      value={taskAttachments}
                      onChange={(e) => setTaskAttachments(e.target.value)}
                      placeholder="Enter attachment file names, separated by commas"
                      className="w-full rounded-md border border-gray-300 p-2"
                    />
                  </div>

                  <div className="flex justify-end space-x-3">
                    <Button
                      variant="outline"
                      onClick={() => setSelectedTask(null)}
                    >
                      <XCircle className="h-4 w-4 mr-2" />
                      Cancel
                    </Button>
                    <Button onClick={handleSaveTask}>
                      <CheckCircle2 className="h-4 w-4 mr-2" />
                      Save Changes
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OnboardingDetail;