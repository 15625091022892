import React, { useState } from "react";
import { Mic, MicOff } from "lucide-react"; // Importing microphone icons
import SpeechToTextService from "../services/SpeechToTextService";

const JobDescriptionStep = ({ formData, onChange, checkFieldInvalid }) => {
  const [isListening, setIsListening] = useState(false);

  const handleToggleListening = async () => {
    if (isListening) {
      // Stop listening
      setIsListening(false);
    } else {
      // Start listening
      setIsListening(true);
      try {
        const transcription = await SpeechToTextService.startRecognition();
        onChange("description", formData.description + " " + transcription);
      } catch (error) {
        console.error("Error during speech recognition:", error);
      } finally {
        setIsListening(false);
      }
    }
  };

  const safeFormData = {
    ...formData,
      post_cvl:
    formData.post_cvl !== undefined ? formData.post_cvl  : true, // Default to true

  };

  return (
    <div className="flex flex-col gap-4 max-w-full">
      <div className="hidden">
        <label className="flex items-center gap-2">
          <input
            type="checkbox"
            className="rounded"
            checked={safeFormData.post_cvl}
            onChange={(e) => onChange("post_cvl", e.target.checked)}
          />
          <span className="text-sm">Post over CV-Library</span>
        </label>
      </div>
      <div>
        <label className="block text-gray-700 text-sm font-medium mb-2">
          Job Description <span className="text-red-500 font-bold">*</span>
        </label>
        <small className="block text-gray-500 mb-2">
          Try to add more than 300 characters to the job description. A good
          description gets more relevant candidates and helps your job posting
          stand out.
        </small>
        <div className="relative">
          <textarea
            className={ `w-full px-3 py-2 border border-gray-300 rounded-md ${checkFieldInvalid("description") ? "border-red-500" : "border-gray-300"}` }
            rows={15}
            value={formData.description}
            onChange={(e) => onChange("description", e.target.value)}
            placeholder="Describe the role, responsibilities, and what you're looking for in an ideal candidate..."
          />
          <button
            type="button"
            onClick={handleToggleListening}
            className={`absolute bottom-3 right-2 p-3 rounded-full transition-all duration-300 ${isListening
                ? "bg-red-500 text-white animate-pulse shadow-lg"
                : "bg-blue-500 text-white"
              }`}
          >
            {isListening ? <MicOff size={28} /> : <Mic size={28} />}
          </button>
        </div>
        <div className="text-right text-sm text-gray-500">
          {formData.description.length} characters
          {formData.description.length < 300 && " (minimum 300 recommended)"}
        </div>
      </div>
    </div>
  );
};

export default JobDescriptionStep;
