// Checkbox.js
import React from 'react';

export const Checkbox = ({ id, checked, onChange, className = '', ...props }) => (
  <input
    type="checkbox"
    id={id}
    checked={checked}
    onChange={onChange}
    className={`form-checkbox h-5 w-5 text-blue-600 ${className}`}
    {...props}
  />
);