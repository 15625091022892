// components/ui/Progress.js
import React from 'react';
import { ProgressColors } from '../../constants';

const getColorForProgress = (value) => {
  // Find the color corresponding to the current progress
  for (let i = ProgressColors.length - 1; i >= 0; i--) {
    if (value >= ProgressColors[i].threshold) {
      return ProgressColors[i].color;
    }
  }
  return 'bg-red-600'; // Default color if something goes wrong
};

export const Progress = ({
  value = 0,
  max = 100,
  className = "",
  ...props
}) => {
  const progressPercentage = (value / max) * 100;
  const progressColor = getColorForProgress(progressPercentage);

  return (
    <div className="w-full">
      <div
        className={`
          h-2 
          w-full 
          bg-gray-200 
          rounded-full 
          overflow-hidden
          ${className}
        `}
        {...props}
      >
        <div
          className={`h-full ${progressColor} transition-all duration-300 ease-in-out`}
          style={{ width: `${progressPercentage}%` }}
        />
      </div>
    </div>
  );
};

export default Progress;