// Card.js

import React from 'react';

// Card Component
const Card = ({ children, className = '', ...props }) => (
  <div className={`border rounded shadow-sm ${className}`} {...props}>
    {children}
  </div>
);

// CardHeader Component
const CardHeader = ({ children, className = '', ...props }) => (
  <div className={`${className}`} {...props}>
    {children}
  </div>
);

// CardTitle Component
const CardTitle = ({ children, className = '', ...props }) => (
  <h3 className={`text-lg font-semibold ${className}`} {...props}>
    {children}
  </h3>
);

// CardContent Component
const CardContent = ({ children, className = '', ...props }) => (
  <div className={`mt-2 ${className}`} {...props}>
    {children}
  </div>
);

export { Card, CardHeader, CardTitle, CardContent };