import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { recentGraphTransactions } from "./utils/paymentHistory";
const BillingInformation = () => {
  const [chartData, setChartData] = useState([]);
  const [totalSpend, setTotalSpend] = useState(0);
  useEffect(() => {
    const getRecentGraphTransaction = async () => {
      try {
        const history = await recentGraphTransactions();
        setChartData(history);
        let total = 0;
        total = history.reduce((acc, entry) => {
          return (
            acc +
            parseInt(entry.sms) +
            parseInt(entry.sms) +
            parseInt(entry.subscription)
          );
        }, 0);
        setTotalSpend(total);
      } catch (err) {}
    };
    getRecentGraphTransaction();
  }, []);
  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <h3 className="text-lg font-semibold mb-4">
        Monthly Billing Information
      </h3>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="week" />
          <YAxis tickFormatter={(value) => `£${value}`} />
          <Tooltip formatter={(value) => `£${value}`} />
          <Legend />
          <Bar
            dataKey="subscription"
            stackId="a"
            fill="#7bb0f2"
            name="Subscription"
          />
          <Bar dataKey="sms" stackId="a" fill="#4a90e2" name="SMS" />
          <Bar dataKey="email" stackId="a" fill="#add8e6" name="Email" />
        </BarChart>{" "}
      </ResponsiveContainer>
      <div className="mt-4 text-right">
        <span className="text-lg font-semibold">
          Total Spend: £{totalSpend}
        </span>
      </div>
    </div>
  );
};

export default BillingInformation;
