// TrainingDetail.js
"use client";

import React, { useState } from 'react';
import {
  User,
  GraduationCap,
  Mail,
  Building,
  Calendar,
  CheckCircle2,
  Clock,
  AlertTriangle,
  FileCheck,
  BookOpen,
  UserCheck,
  XCircle,
  CheckCircle,
  PlayCircle,
  PauseCircle,
  Briefcase,
  Book,
  Timer,
  Bell,
  Settings,
} from 'lucide-react';
import { Button } from './components/ui/Button';
import { Card, CardContent, CardHeader, CardTitle } from './components/ui/Card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from './components/ui/Table';
import { Badge } from './components/ui/Badge';
import { Input } from './components/ui/Input';
import { Label } from './components/ui/Label';
import { Checkbox } from './components/ui/Checkbox';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from './components/ui/Dropdown-menu';

const TrainingDetail = ({ worker, onClose, isInline = false }) => {
  const [newTrainingData, setNewTrainingData] = useState({
    course: '',
    startDate: '',
    dueDate: '',
    notifyWorker: false,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isFormExpanded, setIsFormExpanded] = useState(false);  

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };  

  const getStatusColor = (status) => {
    switch (status) {
      case 'Completed':
        return 'bg-green-100 text-green-800';
      case 'In Progress':
        return 'bg-blue-100 text-blue-800';
      case 'Not Started':
        return 'bg-yellow-100 text-yellow-800';
      case 'Problem':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'Completed':
        return <CheckCircle2 className="h-5 w-5 text-green-600" />;
      case 'In Progress':
        return <PlayCircle className="h-5 w-5 text-blue-600" />;
      case 'Not Started':
        return <PauseCircle className="h-5 w-5 text-yellow-600" />;
      case 'Problem':
        return <AlertTriangle className="h-5 w-5 text-red-600" />;
      default:
        return <Clock className="h-5 w-5 text-gray-600" />;
    }
  };

  const containerClasses = isInline 
    ? "py-0 px-0" 
    : "fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50";

  const contentClasses = isInline
    ? "bg-white"
    : "bg-white rounded-lg shadow-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto";

  return (
    <div 
      className={containerClasses}
      onClick={!isInline ? onClose : undefined}
    >
      <div
        className={contentClasses}
        onClick={!isInline ? (e) => e.stopPropagation() : undefined}
      >
        <div className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Worker Information */}
            <Card className="border border-gray-200 shadow-sm">
              <CardHeader className="border-b bg-gray-50 p-4">
                <div className="flex items-center">
                  <User className="h-5 w-5 text-blue-600 mr-2" />
                  <CardTitle className="text-lg font-semibold">Worker Information</CardTitle>
                </div>
              </CardHeader>
              <CardContent className="p-4 space-y-4">
                <div className="flex items-start space-x-3">
                  <UserCheck className="h-5 w-5 text-blue-600 mt-1" />
                  <div>
                    <p className="text-sm text-gray-500">Full Name</p>
                    <p className="font-medium text-gray-900 text-xl">
                      {worker.firstName} {worker.surname}
                    </p>
                  </div>
                </div>
                <div className="flex items-start space-x-3">
                  <Briefcase className="h-5 w-5 text-blue-600 mt-1" />
                  <div>
                    <p className="text-sm text-gray-500">Job Title</p>
                    <p className="font-medium text-gray-900">{worker.jobTitle}</p>
                  </div>
                </div>
                <div className="flex items-start space-x-3">
                  <Mail className="h-5 w-5 text-blue-600 mt-1" />
                  <div>
                    <p className="text-sm text-gray-500">Email</p>
                    <p className="font-medium text-gray-900">{worker.email}</p>
                  </div>
                </div>
                <div className="flex items-start space-x-3">
                  <Building className="h-5 w-5 text-blue-600 mt-1" />
                  <div>
                    <p className="text-sm text-gray-500">Department</p>
                    <p className="font-medium text-gray-900">{worker.department}</p>
                  </div>
                </div>
              </CardContent>
            </Card>

            {/* Training Overview */}
            <Card className="border border-gray-200 shadow-sm">
              <CardHeader className="border-b bg-gray-50 p-4">
                <div className="flex items-center">
                  <GraduationCap className="h-5 w-5 text-green-600 mr-2" />
                  <CardTitle className="text-lg font-semibold">Training Overview</CardTitle>
                </div>
              </CardHeader>
              <CardContent className="p-4">
                <div className="space-y-4">
                  <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                    <div className="flex items-center space-x-3">
                      <CheckCircle className="h-5 w-5 text-green-600" />
                      <div>
                        <p className="text-sm text-gray-500">Overall Status</p>
                        <Badge className={getStatusColor(worker.trainingStatus)}>
                          {worker.trainingStatus}
                        </Badge>
                      </div>
                    </div>
                    <div className="text-2xl font-bold text-green-600">
                      {worker.completedTrainings}
                      <span className="text-sm text-gray-500 ml-1">completed</span>
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                      <Calendar className="h-5 w-5 text-blue-600" />
                      <div>
                        <p className="text-sm text-gray-500">Last Training</p>
                        <p className="font-medium">{worker.lastTrainingDate}</p>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <Timer className="h-5 w-5 text-orange-600" />
                      <div>
                        <p className="text-sm text-gray-500">Next Training</p>
                        <p className="font-medium">{worker.nextScheduledTraining || 'N/A'}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>

          {/* Training Courses */}
          <Card className="border border-gray-200 shadow-sm">
            <CardHeader className="bg-gray-50 p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <BookOpen className="h-5 w-5 text-purple-600 mr-2" />
                  <CardTitle className="text-lg font-semibold">Training Courses</CardTitle>
                </div>
              </div>
            </CardHeader>
            <CardContent className="p-0">
              <Table>
                <TableHeader>
                  <TableRow className="bg-gray-50">
                    <TableHead>Course Title</TableHead>
                    <TableHead>Description</TableHead>
                    <TableHead>Status</TableHead>
                    <TableHead>Completion Date</TableHead>
                    <TableHead>Initiated By</TableHead>
                    <TableHead>Actions</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {worker.trainingCourses.map((course) => (
                    <TableRow key={course.id} className="hover:bg-gray-50">
                      <TableCell className="font-medium">{course.title}</TableCell>
                      <TableCell>{course.description}</TableCell>
                      <TableCell>
                        <div className="flex items-center space-x-2">
                          {getStatusIcon(course.status)}
                          <Badge className={getStatusColor(course.status)}>
                            {course.status}
                          </Badge>
                        </div>
                      </TableCell>
                      <TableCell>{course.completionDate || 'N/A'}</TableCell>
                      <TableCell>{course.initiatedBy}</TableCell>
                      <TableCell>
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button variant="outline" size="sm" onClick={toggleDropdown}>
                              <Settings className="h-4 w-4 mr-2" />
                              Actions
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent isOpen={isOpen}>
                            <DropdownMenuItem onClick={() => console.log(`Mark as completed`)}>
                              <CheckCircle2 className="h-4 w-4 mr-2 text-green-600" />
                              Mark as Completed
                            </DropdownMenuItem>
                            <DropdownMenuItem onClick={() => console.log(`Mark as not completed`)}>
                              <XCircle className="h-4 w-4 mr-2 text-red-600" />
                              Mark as Not Completed
                            </DropdownMenuItem>
                            <DropdownMenuItem onClick={() => console.log(`Add note`)}>
                              <FileCheck className="h-4 w-4 mr-2 text-blue-600" />
                              Add Note
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>

          {/* Initiate New Training */}
          <Card className="border border-gray-200 shadow-sm">
            <CardHeader className="border-b bg-gray-50 p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <Book className="h-5 w-5 text-blue-600 mr-2" />
                  <CardTitle className="text-lg font-semibold">Initiate New Training</CardTitle>
                </div>
                <Button 
                  variant="outline" 
                  size="sm" 
                  onClick={() => setIsFormExpanded(!isFormExpanded)}
                  className="ml-4"
                >
                  {isFormExpanded ? (
                    <>
                      <XCircle className="h-4 w-4 mr-2" />
                      Cancel
                    </>
                  ) : (
                    <>
                      <FileCheck className="h-4 w-4 mr-2" />
                      Add New Training
                    </>
                  )}
                </Button>
              </div>
            </CardHeader>
            {isFormExpanded && (
              <CardContent className="p-6">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    console.log('Initiate new training:', newTrainingData);
                    setIsFormExpanded(false); // Close form after submission
                    setNewTrainingData({ // Reset form
                      course: '',
                      startDate: '',
                      dueDate: '',
                      notifyWorker: false,
                    });
                  }}
                  className="space-y-4"
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="space-y-2">
                      <Label htmlFor="training-course" className="w-12 mr-4">Training Course</Label>
                      <select
                        id="training-course"
                        value={newTrainingData.course}
                        onChange={(e) =>
                          setNewTrainingData({ ...newTrainingData, course: e.target.value })
                        }
                        className="w-1/2 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      >
                        <option value="">Select a course</option>
                        <option value="course1">Safety Refresher</option>
                        <option value="course2">Advanced Electrical Systems</option>
                        <option value="course3">Project Management Basics</option>
                      </select>
                    </div>

                    <div className="space-y-2">
                      <Label htmlFor="start-date" className="w-12 mr-4">Start Date</Label>
                      <Input
                        id="start-date"
                        type="date"
                        value={newTrainingData.startDate}
                        onChange={(e) =>
                          setNewTrainingData({ ...newTrainingData, startDate: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div className="space-y-2">
                    <Label htmlFor="due-date" className="w-12 mr-4">Due Date</Label>
                    <Input
                      id="due-date"
                      type="date"
                      value={newTrainingData.dueDate}
                      onChange={(e) =>
                        setNewTrainingData({ ...newTrainingData, dueDate: e.target.value })
                      }
                    />
                  </div>

                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="notify-worker"
                      checked={newTrainingData.notifyWorker}
                      onCheckedChange={(checked) =>
                        setNewTrainingData({ ...newTrainingData, notifyWorker: checked })
                      }
                    />
                    <div className="flex items-center space-x-2">
                      <Bell className="h-4 w-4 text-blue-600" />
                      <Label htmlFor="notify-worker">Notify worker via email</Label>
                    </div>
                  </div>

                  <div className="flex justify-end">
                    <Button type="submit" className="bg-blue-600 hover:bg-blue-700">
                      <FileCheck className="h-4 w-4 mr-2" />
                      Initiate Training
                    </Button>
                  </div>
                </form>
              </CardContent>
            )}
          </Card>

        </div>
      </div>
    </div>
  );
};

export default TrainingDetail;