import React from "react";
import { Loader2 } from "lucide-react";

const BackgroundLoadingBanner = ({ isLoading, progress }) => {
  if (!isLoading) return null;
  return (
    <div className="mb-4 p-4 bg-blue-50 border border-blue-200 rounded-md">
      <div className="flex items-center space-x-3">
        <Loader2 className="h-4 w-4 animate-spin text-blue-500" />
        <p className="text-blue-700 text-sm">
          Generating base questionnaire in the background... You can continue
          adding or editing questions.
          {progress && (
            <span className="ml-2 text-blue-600 font-medium">{progress}</span>
          )}
        </p>
      </div>
    </div>
  );
};

export default BackgroundLoadingBanner;
