// WorkerManagement.js

import React, { useState } from 'react';
import Worker from './Worker';
import Payroll from './Payroll';
import RightsToWork from './RightsToWork';
import WorkerChecklist from './WorkerChecklist';
import Onboarding from './Onboarding';
import Training from './Training';
import AuditList from './AuditList';

import {
  User,
  Briefcase,
  ClipboardList,
  Shield,
  CreditCard,
  MessageSquare,
  GraduationCap,
} from 'lucide-react';

const WorkerManagement = () => {
  const [activeTab, setActiveTab] = useState('payroll');

  const navigationItems = [
    { id: 'workers', label: 'Workers', icon: User },
    { id: 'checklist', label: 'Checklist', icon: ClipboardList },
    { id: 'onboarding', label: 'Onboarding', icon: Briefcase },
    { id: 'training', label: 'Training', icon: GraduationCap },
    { id: 'rights', label: 'Right-to-Work', icon: Shield },    
    { id: 'audit', label: 'Audit', icon: MessageSquare },
    { id: 'payroll', label: 'Payroll', icon: CreditCard },
  ];

const renderNavigation = () => (
  <div className="flex space-x-2 bg-slate-100 p-2 rounded-lg">
    {navigationItems.map((item) => {
      const Icon = item.icon;
      const isActive = activeTab === item.id;

      return (
        <button
          key={item.id}
          onClick={() => setActiveTab(item.id)}
          className={`flex items-center px-4 py-3 rounded-lg transition-all ${
            isActive
              ? 'bg-blue-600 text-white shadow-md'
              : 'bg-white text-slate-600 hover:bg-slate-200'
          }`}
        >
          <Icon
            className={`w-5 h-5 mr-2 ${
              isActive ? 'text-white' : 'text-slate-500'
            }`}
          />
          {item.label}
        </button>
      );
    })}
  </div>
);


  const renderContent = () => {
    switch (activeTab) {
      case 'workers':
        return <Worker />;    
      case 'payroll':
        return <Payroll />;
      case 'rights':
        return <RightsToWork />;
      case 'checklist':
        return <WorkerChecklist />;
      case 'onboarding':
        return <Onboarding />;
      case 'training':
        return <Training />;
      case 'audit':
        return <AuditList />;        
      default:
        return (
          <div className="bg-white rounded-lg shadow-sm border border-slate-200 p-8">
            <h2 className="text-lg font-medium text-slate-900 mb-2">
              {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}
            </h2>
            <p className="text-slate-500">This section is under development.</p>
          </div>
        );
    }
  };

  return (
    <div className="min-h-screen bg-slate-50">
      <div className="max-w-[1600px] mx-auto p-6">
        {/* Header */}
        <div className="mb-8">
          <h1 className="text-2xl font-semibold text-slate-900">Worker Management</h1>
          <p className="mt-1 text-sm text-slate-500">
            Manage your workforce information, documentation, and payroll
          </p>
        </div>

        {/* Navigation */}
        {renderNavigation()}

        {/* Content */}
        <div className="mt-3">{renderContent()}</div>
      </div>
    </div>
  );
};

export default WorkerManagement;