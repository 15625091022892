import { api } from "./api";

export const currentSubscription = async () => {
  const token = localStorage.getItem("token");
  const response = await api.get(`/api/companies/subscription`, { 
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const recentTransactions = async () => {
  const token = localStorage.getItem("token");
  const response = await api.get(`/api/companies/payment/history`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const recentGraphTransactions = async () => {
  const token = localStorage.getItem("token");
  const response = await api.get(`/api/companies/payment/graph/history`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};
