// components/ui/Button.js
import React from 'react';

export const Button = ({ 
  children, 
  className = "", 
  variant = "default", 
  ...props 
}) => {
  const baseStyles = "inline-flex items-center px-3 py-2 text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500";
  
  const variants = {
    default: "bg-blue-600 text-white hover:bg-blue-700",
    outline: "border border-gray-300 text-gray-700 bg-white hover:bg-gray-50",
  };

  return (
    <button
      className={`
        ${baseStyles}
        ${variants[variant] || variants.default}
        ${className}
      `}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;