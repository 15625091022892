// WorkerChecklist.js

import React, { useState } from 'react';
import {
  AlertCircle,
  CheckCircle,
  XCircle,
  Clock,
  AlertTriangle,
  Search,
  Briefcase,
  ClipboardList,
  FileText,
  ChevronDown,
  ChevronUp,
  User,
} from 'lucide-react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from './components/ui/Card'; // Adjust import paths according to your project structure
import { Input } from './components/ui/Input';
import { Badge } from './components/ui/Badge';
import { Button } from './components/ui/Button';
import { Progress } from './components/ui/Progress'; // Import the Progress component
import WorkerChecklistDetail from './WorkerChecklistDetail';
import { Label } from './components/ui/Label';

// Dummy data for workers
const dummyWorkers = [
  {
    id: '1',
    firstName: 'John',
    surname: 'Doe',
    jobTitle: 'Senior Electrician',
    currentJob: 'Commercial Wiring Project',
    startDate: '2023-01-15',
    status: 'Approved',
    qualifications: [
      { id: 'q1', name: 'Electrical License', obtained: true },
      { id: 'q2', name: 'First Aid Certificate', obtained: true },
      { id: 'q3', name: 'Working at Heights', obtained: true },
      { id: 'q4', name: 'Asbestos Awareness', obtained: false },
      { id: 'q5', name: 'Electric Vehicle Charging', obtained: true },
      { id: 'q6', name: 'Solar Panel Installation', obtained: false },
    ],
  },
  {
    id: '2',
    firstName: 'Jane',
    surname: 'Smith',
    jobTitle: 'Apprentice Electrician',
    currentJob: 'Residential Rewiring',
    startDate: '2023-03-01',
    status: 'Pending',
    qualifications: [
      { id: 'q1', name: 'Electrical License', obtained: false },
      { id: 'q2', name: 'First Aid Certificate', obtained: true },
      { id: 'q3', name: 'Working at Heights', obtained: true },
      { id: 'q4', name: 'Asbestos Awareness', obtained: true },
      { id: 'q5', name: 'Electric Vehicle Charging', obtained: false },
      { id: 'q6', name: 'Solar Panel Installation', obtained: false },
    ],
  },
];

const WorkerChecklist = () => {
  const [workers, setWorkers] = useState(dummyWorkers);
  const [searchTerm, setSearchTerm] = useState('');
  const [jobTitleFilter, setJobTitleFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('All');
  const [qualificationFilter, setQualificationFilter] = useState('All');
  const [sortConfig, setSortConfig] = useState(null);
  const [expandedWorkerId, setExpandedWorkerId] = useState(null);

  // Collect all qualifications for table header
  const allQualifications = [];
  workers.forEach((worker) => {
    worker.qualifications.forEach((qual) => {
      allQualifications.push({ id: qual.id, name: qual.name });
    });
  });
  // Remove duplicates
  const uniqueQualifications = Array.from(
    new Map(allQualifications.map((qual) => [qual.id, qual])).values()
  );

  // Filter the workers based on search and filters
  const filteredWorkers = workers
    .filter((worker) => {
      const nameMatch = `${worker.firstName} ${worker.surname}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const jobTitleMatch =
        !jobTitleFilter ||
        worker.jobTitle.toLowerCase().includes(jobTitleFilter.toLowerCase());
      const statusMatch =
        statusFilter === 'All' || worker.status === statusFilter;
      const qualificationMatch =
        qualificationFilter === 'All' ||
        (qualificationFilter === 'Complete'
          ? worker.qualifications.every((q) => q.obtained)
          : worker.qualifications.some((q) => !q.obtained));

      return nameMatch && jobTitleMatch && statusMatch && qualificationMatch;
    })
    .sort((a, b) => {
      if (!sortConfig) return 0;
      const { key, direction } = sortConfig;
      let aValue = a[key];
      let bValue = b[key];

      if (typeof aValue === 'string') {
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }

      if (aValue < bValue) return direction === 'ascending' ? -1 : 1;
      if (aValue > bValue) return direction === 'ascending' ? 1 : -1;
      return 0;
    });

  const handleSort = (key) => {
    if (sortConfig && sortConfig.key === key) {
      setSortConfig(
        sortConfig.direction === 'ascending'
          ? { key, direction: 'descending' }
          : null
      );
    } else {
      setSortConfig({ key, direction: 'ascending' });
    }
  };

  const renderSortIcon = (key) => {
    if (!sortConfig || sortConfig.key !== key) {
      return <ChevronDown className="ml-1 h-4 w-4 text-gray-400" />;
    }
    return sortConfig.direction === 'ascending' ? (
      <ChevronUp className="ml-1 h-4 w-4 text-gray-400" />
    ) : (
      <ChevronDown className="ml-1 h-4 w-4 text-gray-400" />
    );
  };

  const getStatusIcon = (obtained) => {
    if (obtained === true) {
      return <CheckCircle className="h-5 w-5 text-green-600" />;
    } else if (obtained === false) {
      return <Clock className="h-5 w-5 text-yellow-600" />;
    } else if (obtained === 'Rejected') {
      return <AlertTriangle className="h-5 w-5 text-red-600" />;
    }
    return <XCircle className="h-5 w-5 text-gray-400" />;
  };

  const toggleWorkerDetail = (workerId) => {
    setExpandedWorkerId(expandedWorkerId === workerId ? null : workerId);
  };

  const handleColumnEnter = (index) => {
    highlightColumn(index);
    document
      .querySelector(`[data-header="${index}"]`)
      ?.classList.add('header-highlight');
  };

  const handleColumnLeave = (index) => {
    removeColumnHighlight(index);
    document
      .querySelector(`[data-header="${index}"]`)
      ?.classList.remove('header-highlight');
  };

  return (
    <div className="space-y-6">
      {/* Header and Filters */}
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <div className="flex items-center mb-4">
          <div className="p-2 bg-blue-50 rounded-lg">
            <ClipboardList className="h-6 w-6 text-blue-600" />
          </div>
          <h2 className="text-xl font-bold text-gray-800 ml-2">
            Worker Checklist
          </h2>
        </div>
        <div className="flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 md:space-x-4 mb-6">
          <div className="flex-1 relative">
            <Input
              type="text"
              placeholder="Search workers..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10"
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
          <div className="flex-1 relative">
            <Input
              type="text"
              placeholder="Search job titles..."
              value={jobTitleFilter}
              onChange={(e) => setJobTitleFilter(e.target.value)}
              className="w-full pl-10"
            />
            <Briefcase className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
          {/* Add more filters as needed */}
        </div>
      </div>

      {/* Workers Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr className="bg-gray-50">
              <th
                className="sticky left-0 z-10 py-3 px-4 text-left text-sm font-bold text-gray-500 uppercase tracking-wider bg-gray-50 min-w-[200px]"
                onClick={() => handleSort('firstName')}
              >
                Worker {renderSortIcon('firstName')}
              </th>
              {uniqueQualifications.map((qual, index) => (
                <th
                  key={qual.id}
                  className="w-[40px] h-[140px] relative group pl-7"
                  onMouseEnter={() => handleColumnEnter(index)}
                  onMouseLeave={() => handleColumnLeave(index)}
                  data-header={index}
                >
                  <div
                    className="task-name absolute bottom-0 left-0 origin-bottom-left transform -rotate-45 whitespace-nowrap text-xs text-gray-500 ml-5 mb-2 font-bold"
                    title={qual.name}
                  >
                    {qual.name}
                  </div>
                </th>
              ))}
              <th className="py-3 px-4 text-center text-sm font-bold text-gray-500 uppercase tracking-wider w-[100px]">
                Progress
              </th>
              <th className="sticky right-0 z-10 py-3 px-4 text-left text-sm font-bold text-gray-500 uppercase tracking-wider bg-gray-50 w-[120px]">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="text-gray-700 text-sm">
            {filteredWorkers.map((worker) => (
              <React.Fragment key={worker.id}>
                <tr
                  className={`border-b border-gray-200 hover:bg-gray-50 ${
                    expandedWorkerId === worker.id ? 'border-b-0' : ''
                  }`}
                >
                  <td
                    className="sticky left-0 z-10 py-3 px-4 text-left whitespace-nowrap font-medium bg-white cursor-pointer"
                    onClick={() => toggleWorkerDetail(worker.id)}
                  >
                    {worker.firstName} {worker.surname}
                    <div className="text-sm text-gray-500">{worker.jobTitle}</div>
                  </td>
                  {uniqueQualifications.map((qual, colIndex) => {
                    let obtainedStatus = 'not_obtained';
                    const workerQual = worker.qualifications.find(
                      (q) => q.id === qual.id
                    );
                    if (workerQual) {
                      obtainedStatus = workerQual.obtained;
                    }
                    return (
                      <td
                        key={qual.id}
                        className={`w-[40px] py-3 px-0 text-center align-middle column-${colIndex}`}
                        onMouseEnter={() => handleColumnEnter(colIndex)}
                        onMouseLeave={() => handleColumnLeave(colIndex)}
                      >
                        <div className="flex justify-center items-center status-icon">
                          {getStatusIcon(obtainedStatus)}
                        </div>
                      </td>
                    );
                  })}
                  <td className="py-3 px-4 text-center">
                    <Progress
                      value={
                        (worker.qualifications.filter((q) => q.obtained === true)
                          .length /
                          uniqueQualifications.length) *
                        100
                      }
                      className="w-24 mx-auto"
                    />
                  </td>
                  <td className="sticky right-0 z-10 py-3 px-4 text-left whitespace-nowrap bg-white">
                    <Button
                      onClick={() => toggleWorkerDetail(worker.id)}
                      variant="outline"
                      size="sm"
                      className="inline-flex items-center"
                    >
                      {expandedWorkerId === worker.id ? (
                        <>
                          <XCircle className="h-4 w-4 mr-2" />
                          Close Details
                        </>
                      ) : (
                        <>
                          <FileText className="h-4 w-4 mr-2" />
                          View Details
                        </>
                      )}
                    </Button>
                  </td>
                </tr>
                {expandedWorkerId === worker.id && (
                  <tr className="border-b border-gray-200">
                    <td
                      colSpan={uniqueQualifications.length + 3}
                      className="p-0 bg-gray-50"
                    >
                      <WorkerChecklistDetail
                        worker={worker}
                        onClose={() => setExpandedWorkerId(null)}
                      />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
        <style jsx>{`
          /* Header text animation */
          .header-highlight .task-name,
          .group:hover .task-name {
            font-size: calc(0.75rem * 1.5);
            transition: all 0.2s ease;
            cursor: pointer;
          }
          .task-name {
            transition: all 0.2s ease;
          }

          /* Status icon animation */
          .highlight .status-icon {
            transform: scale(1.25);
            transition: all 0.2s ease;
          }
          .status-icon {
            transition: all 0.2s ease;
          }

          .group:hover .group-hover\\:visible {
            visibility: visible;
          }
          .invisible {
            visibility: hidden;
          }
          th {
            height: 160px;
            vertical-align: bottom;
          }
          .highlight {
            background-color: rgba(100, 149, 237, 0.2);
          }
        `}</style>
      </div>
    </div>
  );
};

// JavaScript functions to add column highlighting
const highlightColumn = (colIndex) => {
  document.querySelectorAll(`.column-${colIndex}`).forEach((cell) => {
    cell.classList.add('highlight');
  });
};

const removeColumnHighlight = (colIndex) => {
  document.querySelectorAll(`.column-${colIndex}`).forEach((cell) => {
    cell.classList.remove('highlight');
  });
};

export default WorkerChecklist;