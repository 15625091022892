// components/QuestionInput.jsx
import React from "react";
import { Mic, Upload } from "lucide-react";
import { AnswerTypes } from "../constants";

export const QuestionInput = ({
  question,
  value,
  onChange,
  onFileUpload,
  textAreaRef,
  onMicClick,
  isRecording,
  chatAttachment,
}) => {
  const handleTextAreaChange = (e) => {
    onChange(e.target.value);
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
  };

  const handleQuickActionClick = (answer) => {
    onChange(answer);
  };

  const commonButtonClasses =
    "h-14 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500";

  const renderInputByType = () => {
    switch (question.answer_type.toLowerCase()) {
      case AnswerTypes.PHONE.toLowerCase():
      case AnswerTypes.EMAIL.toLowerCase():
      case AnswerTypes.TIME.toLowerCase():
      case AnswerTypes.OPEN_ENDED.toLowerCase():
        return (
          <div className="flex relative w-full">
            <div className="flex-auto w-10/12">
              <textarea
                ref={textAreaRef}
                value={value}
                onChange={handleTextAreaChange}
                className="w-full p-3 pr-12 border rounded-md min-h-[56px] resize-none overflow-hidden"
                placeholder="Type your answer here..."
              />
            </div>
            <div className="text-center flex-auto w-1/6">
              <div className="grid grid-cols-2">
                <div className="inline-block">
                  <button
                    onClick={onMicClick}
                    className={`w-1/2 relative right-2 top-1/2 p-2 rounded-full ${isRecording
                        ? "text-red-500 animate-pulse"
                        : "text-gray-500"
                      }`}
                  >
                    <Mic size={24} />
                  </button>
                </div>
                <div>
                  {chatAttachment && (
                    <button className="relative right-2 top-1/2 p-2 rounded-full text-gray-500">
                      <Upload size={24} />
                      <input
                        type="file"
                        name="chat_attachment"
                        className="absolute inset-0 opacity-0 cursor-pointer"
                        onChange={onFileUpload}
                        multiple
                      />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        );

      case AnswerTypes.MULTIPLE_CHOICE.toLowerCase():
      case AnswerTypes.MULTIPLE_CHOICE_MULTI_ANSWER.toLowerCase():
      case AnswerTypes.MULTIPLE_CHOICE_OTHER.toLowerCase():
        return (
          <div className="flex flex-wrap gap-2 w-full">
            {question.question.options.map((option, index) => (
              <button
                key={index}
                onClick={() => handleQuickActionClick(option)}
                className={`${commonButtonClasses} ${value === option
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700"
                  }`}
              >
                {option}
              </button>
            ))}
          </div>
        );

      case AnswerTypes.YES_NO.toLowerCase():
        return (
          <div className="flex gap-2 w-full">
            <button
              onClick={() => handleQuickActionClick("Yes")}
              className={`${commonButtonClasses} flex-1 ${value === "Yes"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-700"
                }`}
            >
              Yes
            </button>
            <button
              onClick={() => handleQuickActionClick("No")}
              className={`${commonButtonClasses} flex-1 ${value === "No"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-700"
                }`}
            >
              No
            </button>
          </div>
        );

      case AnswerTypes.RATING.toLowerCase():
        return (
          <div className="flex gap-2 w-full">
            {[1, 2, 3, 4, 5].map((rating) => (
              <button
                key={rating}
                onClick={() => handleQuickActionClick(rating.toString())}
                className={`${commonButtonClasses} flex-1 ${value === rating.toString()
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700"
                  }`}
              >
                {rating}
              </button>
            ))}
          </div>
        );

      case AnswerTypes.FILE.toLowerCase():
        return (
          <input
            type="file"
            onChange={onFileUpload}
            className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-14"
            accept={question.allowedFileTypes}
          />
        );

      default:
        return null;
    }
  };

  return renderInputByType();
};
