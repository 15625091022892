import { useEffect } from "react";
import defaultQuestions from "./defaultQuestions.json";

const QuestionGenerator = ({ onQuestionsGenerated }) => {
  // Load default questions on component mount
  useEffect(() => {
    onQuestionsGenerated(defaultQuestions); // Notify parent component with default questions
  }, [onQuestionsGenerated]); // Include onQuestionsGenerated in the dependency array

  return null; // Do not render any UI elements
};

export default QuestionGenerator;
