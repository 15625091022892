// components/ui/Badge.js
import React from 'react';

export const Badge = ({ 
  children, 
  variant = "default", 
  className = "", 
  ...props 
}) => {
  const variants = {
    default: "bg-gray-100 text-gray-800",
    primary: "bg-blue-100 text-blue-800",
    secondary: "bg-purple-100 text-purple-800",
    success: "bg-green-100 text-green-800",
    warning: "bg-yellow-100 text-yellow-800",
    danger: "bg-red-100 text-red-800",
  };

  return (
    <span
      className={`
        inline-flex 
        items-center 
        px-2.5 
        py-0.5 
        rounded-full 
        text-xs 
        font-medium
        ${variants[variant] || variants.default}
        ${className}
      `}
      {...props}
    >
      {children}
    </span>
  );
};

export default Badge;