import axios from "axios";

const getApiUrl = () => {
  return process.env.REACT_APP_ENV === "production"
    ? process.env.REACT_APP_API_URL_DEPLOYED
    : process.env.REACT_APP_API_URL_LOCAL;
};

export const API_BASE_URL = getApiUrl();
export const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
    Accept: "application/json",
  },
});
