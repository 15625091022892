import React from "react";
import {
  HelpCircle,
  Tag,
  BarChart2,
  Award,
  CheckCircle,
  AlertTriangle,
  Brain,
} from "lucide-react";

const QuestionnaireResponses = ({ jobseeker }) => {
  // Calculate overall score
  let questionnaire_responses = [];
  if (jobseeker.detailedInfo && jobseeker.detailedInfo.questionnaireResponses) {
    questionnaire_responses = jobseeker.detailedInfo.questionnaireResponses;
  }
  let overallScore =
    questionnaire_responses.reduce((sum, response) => sum + response.score, 0) /
    questionnaire_responses.length;

  overallScore =
    typeof overallScore === "number" && !isNaN(overallScore)
      ? overallScore
      : 0.0;
  console.log(overallScore);

  // Function to determine color based on score
  const getScoreColor = (score) => {
    if (score >= 0.8) return "text-green-600";
    if (score >= 0.6) return "text-yellow-600";
    return "text-red-600";
  };

  const getDifficultyColor = (difficulty) => {
    switch (difficulty.toLowerCase()) {
      case "easy":
        return "text-green-500";
      case "medium":
        return "text-yellow-500";
      case "hard":
        return "text-red-500";
      default:
        return "text-gray-500";
    }
  };

  const getImportanceColor = (importance) => {
    switch (importance.toLowerCase()) {
      case "critical":
        return "text-red-500";
      case "high":
        return "text-orange-500";
      case "medium":
        return "text-yellow-500";
      case "low":
        return "text-green-500";
      default:
        return "text-gray-500";
    }
  };

  return (
    <div className="p-4 space-y-8 bg-gradient-to-br from-blue-50 to-indigo-50 rounded-lg">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-2xl font-bold text-indigo-800 flex items-center">
          <HelpCircle className="mr-2" /> Questionnaire Responses
        </h3>
        <div className="bg-white p-4 rounded-lg shadow-md">
          <h4 className="text-sm font-semibold mb-1 text-gray-600">
            Overall Score
          </h4>
          <div className={`text-3xl font-bold ${getScoreColor(overallScore)}`}>
            {(overallScore * 100).toFixed(2)}%
          </div>
        </div>
      </div>

      {questionnaire_responses.map((response) => (
        <div key={response.id} className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex justify-between items-start mb-4">
            <div className="flex-grow">
              <h4 className="text-xl font-semibold mb-2 text-indigo-800">
                {response.question}
              </h4>
              <div className="flex flex-wrap gap-2 mb-2">
                <span className="flex items-center text-sm text-gray-600">
                  <Tag className="mr-1" size={16} />
                  {response.category}
                </span>
                <span
                  className={`flex items-center text-sm ${getDifficultyColor(response.difficulty)}`}
                >
                  <BarChart2 className="mr-1" size={16} />
                  {response.difficulty}
                </span>
                <span
                  className={`flex items-center text-sm ${getImportanceColor(response.importance)}`}
                >
                  <Award className="mr-1" size={16} />
                  {response.importance}
                </span>
              </div>
            </div>
            <div className="text-right ml-4">
              <div
                className={`text-2xl font-bold ${getScoreColor(response.score)}`}
              >
                {(response.score * 100).toFixed(2)}%
              </div>
              <div className="text-sm text-gray-600">
                Score: {response.score} / {response.maxScore}
              </div>
            </div>
          </div>

          {response.evaluationCriteria.length > 0 ? (
            <div className="mb-4">
              <h5 className="font-semibold mb-2 flex items-center">
                <CheckCircle className="mr-2 text-green-500" size={18} />{" "}
                Evaluation Criteria
              </h5>
              <div className="bg-green-50 p-3 rounded">
                <ul className="list-disc pl-5">
                  {response.evaluationCriteria.map((criterion, index) => (
                    <li key={index} className="text-sm text-gray-700">
                      {criterion}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="mb-4">
            <h5 className="font-semibold mb-2 flex items-center">
              <AlertTriangle className="mr-2 text-yellow-500" size={18} />{" "}
              Candidate's Answer
            </h5>
            <p className="text-gray-700 bg-yellow-50 p-3 rounded">
              {response.answer}
            </p>
          </div>

          <div>
            <h5 className="font-semibold mb-2 flex items-center">
              <Brain className="mr-2 text-blue-500" size={18} /> Assessment
            </h5>
            <p className="text-sm text-gray-600 bg-blue-50 p-3 rounded">
              {response.aiAssessment === 'NONE'? " - ": response.aiAssessment}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default QuestionnaireResponses;
