// Training.js
"use client";

import React, { useState } from 'react';
import {
  GraduationCap,
  Search,
  ChevronDown,
  ChevronUp,
  FileText,
  XCircle,
  Book
} from 'lucide-react';
import { Button } from './components/ui/Button';
import { Card, CardContent, CardHeader, CardTitle } from './components/ui/Card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from './components/ui/Table';
import { Badge } from './components/ui/Badge';
import { Input } from './components/ui/Input';
import TrainingDetail from './TrainingDetail';

const dummyWorkers = [
  {
    id: '1',
    firstName: 'John',
    surname: 'Doe',
    jobTitle: 'Senior Electrician',
    email: 'john.doe@example.com',
    department: 'Electrical',
    trainingStatus: 'Completed',
    lastTrainingDate: '2023-05-15',
    nextScheduledTraining: '2023-11-15',
    completedTrainings: 5,
    trainingCourses: [
      {
        id: 'course1',
        title: 'Safety Training',
        description: 'Annual safety procedures and protocols',
        status: 'Completed',
        completionDate: '2023-05-15',
        initiatedBy: 'HR Department',
      },
      {
        id: 'course2',
        title: 'Electrical Code Refresher',
        description: 'Updates to electrical codes and standards',
        status: 'In Progress',
        initiatedBy: 'Technical Department',
      },
    ],
  },
  {
    id: '2',
    firstName: 'Jane',
    surname: 'Smith',
    jobTitle: 'Apprentice Electrician',
    email: 'jane.smith@example.com',
    department: 'Electrical',
    trainingStatus: 'In Progress',
    lastTrainingDate: '2023-03-01',
    nextScheduledTraining: '2023-09-01',
    completedTrainings: 2,
    trainingCourses: [
      {
        id: 'course1',
        title: 'Safety Training',
        description: 'Annual safety procedures and protocols',
        status: 'Completed',
        completionDate: '2023-03-01',
        initiatedBy: 'HR Department',
      },
      {
        id: 'course3',
        title: 'Apprenticeship Program - Module 1',
        description: 'Fundamentals of electrical systems',
        status: 'In Progress',
        initiatedBy: 'Training Department',
      },
    ],
  },
];

const Training = ({ workers = dummyWorkers }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('All');
  const [sortConfig, setSortConfig] = useState(null);
  const [expandedWorkerId, setExpandedWorkerId] = useState(null);

  const getStatusColor = (status) => {
    switch (status) {
      case 'Completed':
        return 'bg-green-100 text-green-800';
      case 'In Progress':
        return 'bg-blue-100 text-blue-800';
      case 'Not Started':
        return 'bg-yellow-100 text-yellow-800';
      case 'Problem':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const handleSort = (key) => {
    if (sortConfig && sortConfig.key === key) {
      setSortConfig(
        sortConfig.direction === 'ascending'
          ? { key, direction: 'descending' }
          : null
      );
    } else {
      setSortConfig({ key, direction: 'ascending' });
    }
  };

  const renderSortIcon = (key) => {
    if (!sortConfig || sortConfig.key !== key) {
      return <ChevronDown className="ml-2 h-4 w-4 text-gray-400" />;
    }
    return sortConfig.direction === 'ascending' ? (
      <ChevronUp className="ml-2 h-4 w-4 text-gray-400" />
    ) : (
      <ChevronDown className="ml-2 h-4 w-4 text-gray-400" />
    );
  };

  const filteredWorkers = workers
    .filter((worker) => {
      const nameMatch = `${worker.firstName} ${worker.surname}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const statusMatch =
        filterStatus === 'All' || worker.trainingStatus === filterStatus;
      return nameMatch && statusMatch;
    })
    .sort((a, b) => {
      if (!sortConfig) return 0;
      const { key, direction } = sortConfig;
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });

  const toggleWorkerDetail = (workerId) => {
    setExpandedWorkerId(expandedWorkerId === workerId ? null : workerId);
  };

  return (
    <div className="space-y-6">
      <Card className="bg-white shadow-lg p-4">
        <CardHeader className="bg-white">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <div className="p-2 rounded-lg">
                <GraduationCap className="h-6 w-6 text-blue-600" />
              </div>
              <CardTitle className="text-xl font-bold text-gray-800">
                Training Management
              </CardTitle>
            </div>
          </div>
        </CardHeader>
        <CardContent className="pt-0">
          <div className="flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 md:space-x-4 mb-6">
            <div className="flex-1 relative">
              <Input
                type="text"
                placeholder="Search workers..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10"
              />
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
            <select
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              className="w-[180px] border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="All">All Statuses</option>
              <option value="Completed">Completed</option>
              <option value="In Progress">In Progress</option>
              <option value="Not Started">Not Started</option>
              <option value="Problem">Problem</option>
            </select>
          </div>

          <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow className="bg-gray-50">
                  <TableHead
                    className="py-3 px-4 text-left cursor-pointer font-bold text-gray-600 text-sm"
                    onClick={() => handleSort('firstName')}
                  >
                    <div className="flex items-center">
                      <Book className="h-4 w-4 text-blue-600 mr-2" />
                      Worker Name {renderSortIcon('firstName')}
                    </div>
                  </TableHead>
                  <TableHead
                    className="cursor-pointer font-bold font-bold text-gray-600 text-sm"
                    onClick={() => handleSort('jobTitle')}
                  >
                    <div className="flex items-center font-bold">
                      <GraduationCap className="h-4 w-4 text-blue-600 mr-2" />
                      Job Title/Role {renderSortIcon('jobTitle')}
                    </div>
                  </TableHead>
                  <TableHead
                    className="cursor-pointer font-bold font-bold text-gray-600 text-sm"
                    onClick={() => handleSort('trainingStatus')}
                  >
                    <div className="flex items-center">
                      <FileText className="h-4 w-4 text-blue-600 mr-2" />
                      Training Status {renderSortIcon('trainingStatus')}
                    </div>
                  </TableHead>
                  <TableHead
                    className="cursor-pointer font-bold font-bold text-gray-600 text-sm"
                    onClick={() => handleSort('lastTrainingDate')}
                  >
                    <div className="flex items-center">
                      Last Training {renderSortIcon('lastTrainingDate')}
                    </div>
                  </TableHead>
                  <TableHead
                    className="cursor-pointer font-bold font-bold text-gray-600 text-sm"
                    onClick={() => handleSort('nextScheduledTraining')}
                  >
                    <div className="flex items-center">
                      Next Training {renderSortIcon('nextScheduledTraining')}
                    </div>
                  </TableHead>
                  <TableHead
                    className="cursor-pointer font-bold font-bold text-gray-600 text-sm"
                    onClick={() => handleSort('completedTrainings')}
                  >
                    <div className="flex items-center">
                      Completed {renderSortIcon('completedTrainings')}
                    </div>
                  </TableHead>
                  <TableHead className="font-bold font-bold text-gray-600 text-sm">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredWorkers.map((worker, index) => (
                  <React.Fragment key={worker.id}>
                    <TableRow
                      className={`border-b border-gray-200 hover:bg-gray-50 ${
                        index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                      } ${expandedWorkerId === worker.id ? 'border-b-0' : ''}`}
                    >
                      <TableCell className="font-medium">
                        {worker.firstName} {worker.surname}
                      </TableCell>
                      <TableCell>{worker.jobTitle}</TableCell>
                      <TableCell>
                        <Badge className={getStatusColor(worker.trainingStatus)}>
                          {worker.trainingStatus}
                        </Badge>
                      </TableCell>
                      <TableCell>{worker.lastTrainingDate}</TableCell>
                      <TableCell>{worker.nextScheduledTraining || 'N/A'}</TableCell>
                      <TableCell>
                        <Badge variant="outline" className="bg-blue-50">
                          {worker.completedTrainings} complete
                        </Badge>
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() => toggleWorkerDetail(worker.id)}
                          variant="outline"
                          size="sm"
                          className="inline-flex items-center"
                        >
                          {expandedWorkerId === worker.id ? (
                            <>
                              <XCircle className="h-4 w-4 mr-2" />
                              Close Details
                            </>
                          ) : (
                            <>
                              <FileText className="h-4 w-4 mr-2" />
                              View Details
                            </>
                          )}
                        </Button>
                      </TableCell>
                    </TableRow>
                    {expandedWorkerId === worker.id && (
                      <TableRow className="border-b border-gray-200">
                        <TableCell colSpan={7} className="p-0">
                          <div className="">
                            <TrainingDetail
                              worker={worker}
                              onClose={() => setExpandedWorkerId(null)}
                              isInline={true}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Training;