import React from "react";
import { SkipForward } from "lucide-react";

export const Message = ({ message, onSkip }) => {
  if (message.required === false) {
    return (
      <div className="mb-4 text-left">
        <div className="inline-block p-3 rounded-lg bg-green-100 text-gray-700 border border-green-300 max-w-[85%]">
          {message.text}
        </div>
        <div className="mt-2 text-right">
          <button
            onClick={onSkip}
            className="inline-flex items-center px-3 py-2 border border-blue-300 text-sm leading-4 font-medium rounded-md text-blue-700 bg-white hover:text-blue-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-blue-800 active:bg-blue-50 transition ease-in-out duration-150"
          >
            Skip for now
            <SkipForward size={16} className="ml-2" />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`inline-block p-3 rounded-lg ${message.sender === "user"
          ? "bg-blue-500 text-white"
          : "bg-white text-gray-700 border border-gray-300"
        } max-w-[85%]`}
    >
      {message.text}
      {message.files?.length > 0 && (
        <div className="mt-2 flex flex-wrap">
          {message.files.map((file, i) => (
            <div key={i} className="m-1">
              {file.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
