// RightsToWork.js
"use client";

import React, { useState } from 'react';
import {
  Shield,
  Search,
  ChevronDown,
  ChevronUp,
  FileText,
} from 'lucide-react';
import { Button } from './components/ui/Button';
import { Badge } from './components/ui/Badge';
import RightsToWorkDetail from './RightsToWorkDetail';

// Keeping the dummy data in the main component
const dummyWorkers = [
  {
    id: '1',
    firstName: 'John',
    surname: 'Doe',
    jobTitle: 'Senior Electrician',
    dateOfBirth: '1985-05-15',
    nationality: 'British',
    passportNumber: '123456789',
    documentType: 'Passport',
    documentExpiry: '2030-05-14',
    issuingCountry: 'United Kingdom',
    rightToWorkStatus: 'British Citizen',
    dateOfCheck: '2023-01-10',
    checkMethod: 'Manual',
    checkOutcome: 'Pass',
    checkedBy: 'Jane Smith, HR Manager',
    timeline: [
      {
        date: '2023-01-05',
        title: 'Application Received',
        description: 'Initial application and documents submitted',
        status: 'completed',
        source: 'worker',
      },
      {
        date: '2023-01-07',
        title: 'Document Verification',
        description: 'Passport and supporting documents verified',
        status: 'completed',
        source: 'company',
      },
      {
        date: '2023-01-10',
        title: 'Right to Work Check',
        description: 'Manual check performed by HR',
        status: 'completed',
        source: 'company',        
      },
      {
        date: '2023-01-12',
        title: 'Approval',
        description: 'Right to work approved',
        status: 'completed',
        source: 'company',        
      },
      {
        date: '2024-01-10',
        title: 'Annual Review',
        description: 'Scheduled annual right to work review',
        status: 'pending',
        source: 'company',
      },
    ],
  },
  {
    id: '2',
    firstName: 'Maria',
    surname: 'Garcia',
    jobTitle: 'Apprentice Electrician',
    dateOfBirth: '1998-09-23',
    nationality: 'Spanish',
    passportNumber: '987654321',
    documentType: 'National ID Card',
    documentExpiry: '2028-09-22',
    issuingCountry: 'Spain',
    rightToWorkStatus: 'EU Settlement Scheme - Settled Status',
    shareCode: 'ABC123XYZ',
    shareCodeExpiry: '2023-07-01',
    dateOfCheck: '2023-03-01',
    checkMethod: 'Digital',
    checkOutcome: 'Pass',
    checkedBy: 'John Brown, Recruitment Officer',
    timeline: [
      {
        date: '2023-02-20',
        title: 'Application Received',
        description: 'Initial application and EU Settlement Scheme details submitted',
        status: 'completed',
        source: 'worker',        
      },
      {
        date: '2023-02-25',
        title: 'Share Code Provided',
        description: 'Candidate provided share code for digital check',
        status: 'completed',
        source: 'worker',        
      },
      {
        date: '2023-03-01',
        title: 'Digital Right to Work Check',
        description: 'Online check performed using share code',
        status: 'completed',
        source: 'company',        
      },
      {
        date: '2023-03-03',
        title: 'Approval',
        description: 'Right to work approved under EU Settlement Scheme',
        status: 'completed',
        source: 'company',        
      },
      {
        date: '2023-06-15',
        title: 'Share Code Expiry Reminder',
        description: 'Notification sent for upcoming share code expiry',
        status: 'pending',
        source: 'company',        
      },
      {
        date: '2024-03-01',
        title: 'Annual Review',
        description: 'Scheduled annual right to work review',
        status: 'pending',
        source: 'company',        
      },
    ],
  },
];

const RightsToWork = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('All');
  const [sortConfig, setSortConfig] = useState(null);
  const [expandedWorkerId, setExpandedWorkerId] = useState(null);

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'pass':
      case 'completed':
        return 'bg-green-100 text-green-800';
      case 'fail':
      case 'failed':
        return 'bg-red-100 text-red-800';
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const handleSort = (key) => {
    if (sortConfig && sortConfig.key === key) {
      setSortConfig(
        sortConfig.direction === 'ascending'
          ? { key, direction: 'descending' }
          : null
      );
    } else {
      setSortConfig({ key, direction: 'ascending' });
    }
  };

  const renderSortIcon = (key) => {
    if (!sortConfig || sortConfig.key !== key) {
      return <ChevronDown className="ml-1 h-4 w-4 inline-block text-gray-400" />;
    }
    return sortConfig.direction === 'ascending' ? (
      <ChevronUp className="ml-1 h-4 w-4 inline-block text-gray-400" />
    ) : (
      <ChevronDown className="ml-1 h-4 w-4 inline-block text-gray-400" />
    );
  };

  const filteredWorkers = dummyWorkers
    .filter((worker) => {
      const nameMatch = `${worker.firstName} ${worker.surname}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const statusMatch =
        filterStatus === 'All' || worker.checkOutcome === filterStatus;
      return nameMatch && statusMatch;
    })
    .sort((a, b) => {
      if (!sortConfig) return 0;
      const { key, direction } = sortConfig;
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });

  const toggleWorkerDetail = (workerId) => {
    setExpandedWorkerId(expandedWorkerId === workerId ? null : workerId);
  };

  return (
    <div className="space-y-6">
      <div className="bg-white p-6 rounded-lg shadow">
        <div className="flex items-center mb-4">
          <Shield className="mr-2 h-6 w-6 text-blue-600" />
          <h2 className="text-xl font-bold text-gray-800">
            Right-to-Work Management
          </h2>
        </div>

        {/* Filters */}
        <div className="flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 md:space-x-4 mb-4">
          <div className="flex-1 relative">
            <input
              type="text"
              placeholder="Search workers..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full border border-gray-300 rounded-md p-2 pl-10 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
          <select
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
            className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="All">All Outcomes</option>
            <option value="Pass">Pass</option>
            <option value="Fail">Fail</option>
            <option value="Pending">Pending</option>
          </select>
        </div>

        {/* Workers Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr className="bg-gray-50 text-gray-600 uppercase text-sm leading-normal">
                <th
                  className="py-3 px-4 text-left cursor-pointer"
                  onClick={() => handleSort('firstName')}
                >
                  Name {renderSortIcon('firstName')}
                </th>
                <th
                  className="py-3 px-4 text-left cursor-pointer"
                  onClick={() => handleSort('nationality')}
                >
                  Nationality {renderSortIcon('nationality')}
                </th>
                <th className="py-3 px-4 text-left">Right to Work Status</th>
                <th
                  className="py-3 px-4 text-left cursor-pointer"
                  onClick={() => handleSort('documentExpiry')}
                >
                  Document Expiry {renderSortIcon('documentExpiry')}
                </th>
                <th
                  className="py-3 px-4 text-left cursor-pointer"
                  onClick={() => handleSort('checkOutcome')}
                >
                  Check Outcome {renderSortIcon('checkOutcome')}
                </th>
                <th className="py-3 px-4 text-left">Actions</th>
              </tr>
            </thead>
            <tbody className="text-gray-700 text-sm">
              {filteredWorkers.map((worker, index) => (
                <React.Fragment key={worker.id}>
                  <tr
                    className={`border-b border-gray-200 hover:bg-gray-50 ${
                      index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                    } ${expandedWorkerId === worker.id ? 'border-b-0' : ''}`}
                  >
                    <td className="py-3 px-4 text-left whitespace-nowrap">
                      {worker.firstName} {worker.surname}
                    </td>
                    <td className="py-3 px-4 text-left">{worker.nationality}</td>
                    <td className="py-3 px-4 text-left">
                      {worker.rightToWorkStatus}
                    </td>
                    <td className="py-3 px-4 text-left">
                      <Badge
                        variant={
                          new Date(worker.documentExpiry) < new Date()
                            ? 'destructive'
                            : 'default'
                        }
                      >
                        {worker.documentExpiry}
                      </Badge>
                    </td>
                    <td className="py-3 px-4 text-left">
                      <span
                        className={`px-2 py-1 rounded-full text-xs font-medium ${getStatusColor(
                          worker.checkOutcome
                        )}`}
                      >
                        {worker.checkOutcome}
                      </span>
                    </td>
                    <td className="py-3 px-4 text-left">
                      <Button
                        onClick={() => toggleWorkerDetail(worker.id)}
                        variant="outline"
                        size="sm"
                        className="inline-flex items-center"
                      >
                        <FileText className="h-4 w-4 mr-2" />
                        View Details
                      </Button>
                    </td>
                  </tr>
                  {expandedWorkerId === worker.id && (
                    <tr className="border-b border-gray-200">
                      <td colSpan="6" className="p-0">
                        <div className="border-t border-gray-200">
                          <RightsToWorkDetail
                            worker={worker}
                            onClose={() => setExpandedWorkerId(null)}
                            isInline={true}
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RightsToWork;