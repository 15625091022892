import { api } from "./api";

export const uploadCompanyLogo = async (file) => {
  const token = localStorage.getItem("token");
  if (!file) {
    alert("Please select a file to upload.");
    return;
  }

  const formData = new FormData();
  formData.append("logo", file);

  try {
    const response = await api.post("/api/upload/logo", formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;

  } catch (error) {
    console.error("Error uploading file:", error);
    return;
  }
};
